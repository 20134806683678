.RewardHomePage {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding-bottom: 40px;
}

.RewardHome_Banner {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 80px 40px 130px;
    position: relative;
}

.RewardHome_Banner::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000005b;
    z-index: 1;
}

.RewardHome_Banner .contant_wrap {
   width: 100%;
   max-width: 50%;
   height: auto;
   position: relative;
   z-index: 2;
}

.RewardHome_Banner .heading { 
    font-size: 34px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
    color: #fff;
    padding: 0;
    margin-bottom: 30px;
}

.RewardHome_Banner .text {
   font-size: 22px;
   text-align: left;
   font-weight: 500;
   line-height: normal;
   color: #fff;
   padding: 0;
   margin-bottom: 30px;
}

.benifits_btn {
    width: 100%;
    max-width: 120px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(90deg, rgba(94,16,223,1) 0%, rgba(51,9,121,1) 100%);
    border: none;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.WelcomeToworld_sec {
    width: 100%;
    height: auto;
}

.WelcomeToworld_sec .content_wrap {
    width: 100%;
    height: auto;
    padding: 50px 40px 50px;
}

.WelcomeToworld_sec .heading {
    font-size: 24px;
    text-align: left;
    font-weight: 500;
    line-height: normal;
    color: #000000;
    margin-bottom: 25px;
}

.WelcomeToworld_sec .text {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin-bottom: 30px;
}


.RewardsDigitalcards_sec {
    width: 100%;
    height: auto;
    background-image: linear-gradient(#9411E4, #46076D);
}

.RewardsDigitalcards_sec .mainwrap {
    width: 100%;
    height: auto;
    padding: 40px 40px;
}

.RewardsDigitalcards_sec .img_wrap {
   width: 100%;
   max-width: 70%;
   height: auto;
   overflow: hidden;
   margin: 0 auto;
}

.RewardsDigitalcards_sec .img_wrap img {
    width: 100%;
    height: 100%;
}

.RewardsDigitalcards_sec .rightcontent {
    width: 100%;
    height: auto;
}

.RewardsDigitalcards_sec .heading {
    max-width: 400px;
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    padding: 0;
    margin-bottom: 20px;
}

.RewardsDigitalcards_sec .text {
    max-width: 400px;
    font-size: 20px;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    padding: 0;
    margin-bottom: 40px;
}

.RewardsDigitalcards_sec .cardbox_wrap {
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 15px;
}

.RewardsDigitalcards_sec .cardbox_wrap .cardbox {
    width: 30%;
    height: auto;
}

.RewardsDigitalcards_sec .cardbox_wrap .colorbox {
   width: 50px;
   height: 50px;
   border-radius: 50%;
   overflow: hidden;
   margin-bottom: 10px;
   margin: 0 auto 8px;
}

.RewardsDigitalcards_sec .cardbox_wrap .name {
   font-size: 15px;
   text-align: center;
   font-weight: 500;
   color: #fff;
   padding: 0;
   margin-bottom: 0px;
}

.RewardsDigitalcards_sec .cardbox_wrap .points {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    color: #fff;
    padding: 0;
    margin: 0;
}

.WhatNeooSearchbusiness_sec {
    width: 100%;
    height: auto;
}

.WhatNeooSearchbusiness_sec .mainwrap {
    width: 100%;
    height: auto;
    padding: 40px 40px;
}

.WhatNeooSearchbusiness_sec .whatis {
   font-size: 24px;
   text-align: left;
   font-weight: 500;
   color: #000000;
   line-height: normal;
   padding: 0;
   margin-bottom: 15px;
}

.WhatNeooSearchbusiness_sec .whatis_list {
   width: 100%;
   height: auto;
   padding: 0;
   margin-left: 30px;
}

.WhatNeooSearchbusiness_sec .whatis_list li {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    color: #000000;
    line-height: normal;
    padding: 0;
    margin-bottom: 10px;
}




@media (max-width: 768px) {
    .RewardHome_Banner .contant_wrap {
        width: 100%;
        max-width: 60%;
    }
    .RewardHome_Banner {
        padding: 50px 40px;
    }
    .RewardHome_Banner .heading { 
        font-size: 24px;
        margin-bottom: 30px;
    }
    .RewardHome_Banner .text {
        font-size: 16px;
        margin-bottom: 30px;
     }
     .WelcomeToworld_sec .content_wrap {
        padding: 50px 40px;
    }
    .RewardsDigitalcards_sec .row {
        flex-direction: column-reverse;
    }
    /* .RewardsDigitalcards_sec .rightcontent {
      margin-bottom: 30px;
    } */
    .RewardsDigitalcards_sec .img_wrap {
        width: 100%;
        max-width: 100%;
        rotate: 45deg
    }
   
}

@media (max-width: 576px) {
    .RewardHome_Banner .contant_wrap {
        width: 100%;
        max-width: 80%;
    }

    .benifits_btn {
        width: 100%;
        max-width: 90px;
        height: 32px;
    }
    .RewardsDigitalcards_sec .heading {
        font-size: 24px;
    }
    .RewardsDigitalcards_sec .text {
        font-size: 16px;
    }

    .WhatNeooSearchbusiness_sec .whatis {
        font-size: 20px;
     }
     
     .WhatNeooSearchbusiness_sec .whatis_list li {
         font-size: 14px;
         margin-bottom: 6px;
     }
    
}

@media (max-width: 480px) {
    .RewardHome_Banner {
        padding: 50px 20px;
    }
    .RewardHome_Banner .contant_wrap {
        width: 100%;
        max-width: 100%;
    }
    .RewardHome_Banner .heading { 
        font-size: 20px;
        margin-bottom: 20px;
    }
    .RewardHome_Banner .text {
        font-size: 14px;
     }
     .WelcomeToworld_sec .content_wrap {
        padding: 30px 20px;
    }
    .RewardsDigitalcards_sec .mainwrap {
        padding: 40px 20px 0px;
    }
    .RewardsDigitalcards_sec .cardbox_wrap .name {
        font-size: 12px;
        font-weight: 500;
    }
    .RewardsDigitalcards_sec .cardbox_wrap .points {
        font-size: 12px;
        font-weight: 500;
    }
    .WhatNeooSearchbusiness_sec .mainwrap {
        padding: 40px 20px;
    }
    .WhatNeooSearchbusiness_sec .whatis {
        font-size: 18px;
     }
     
     .WhatNeooSearchbusiness_sec .whatis_list li {
         font-size: 14px;
     }
}