.neoobg {
    width: 100%;
    height: 100%;
}

.bg-wht {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    height: 100%;
    /* padding-top: 10px; */
    padding-bottom: 20px;
}

.mainbgsavecontact {
    padding: 20px 0px;
    position: relative;
}

.mainsavecontact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
}

.emojuwavetxt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
    padding-bottom: 5px;
}

.jsmrtxt {
    color: #010203;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 22px;
    /* word-Wrap: 'break-word' */
}

.savecontactbtn {
    color: #fff;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    word-Wrap: 'break-word';
    background-color: rgba(0, 158, 96, 1);
    border: none;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 30px;
    white-space: nowrap;
    transition: all 0.5s ease-in-out;
}

.savecontactbtn:hover {
    color: #fff;
    background: rgb(139 158 209);
}

.cntntimg {
    width: 20px;
    height: 27px;
    margin-left: 10px;
}

.txtdear {
    color: #010203;
    font-Size: 15px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    letter-Spacing: 0.24px;
    word-wrap: break-word;
    width: 100%;
    max-width: 55%;
    padding-top: 5px;
}

.bggradient {
    /* background-image: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1)); */
    /* padding: 150px 0px; */
    width: 100%;
    height: 400px;
}

.bggradient img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.persondetailsdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}

.img_profileflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -20px;
    gap: 1%;
    width: 100%;
    margin-left: 25px;
}

.imgbrdr {
    width: 100%;
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin-right: 5px;
    overflow: hidden;
}

.imgbrdr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.imgmain img {
    width: 100%;
    height: 100%;
}


.Messagebtn_ {
    position: relative;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    background-color: #662D91;
    color: #F5EEEE;
    padding: 9px 24px;
    margin: 0;
    border-radius: 6px;
    font-size: 16px;
    line-height: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 145px;
    height: auto;
}

.Messagebtn_ img {
    width: 20px;
    height: auto;
    margin-right: 10px;
}


.likespiner {
    border: 1px solid #4c58fc45;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 15px;
    border-radius: 6px;
    color: #908B8B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 104px;
    height: 38px;
}

.likespiner:hover {
    color: #908B8B;
}

.likespinerdivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3%;
}

.likespiner img {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.whatsppimg,
.callimg {
    width: 60px;
    height: 40px;
}

/* .callimg{
    display: none;
} */

.whatsppimg img,
.callimg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.dtlsprflnmmph {
    display: flex;
    width: 100%;
}

.dtlsprflnmmph .left {
    margin-right: 10px;
}

.profiledtlstxt {
    color: #010101;
    font-Size: 24px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 30px;
    word-Wrap: break-word;
}

.dtlsprflnmmph .Verified {
    width: 25px;
    height: 25px;
}

.dtlsprflnmmph .Verified img {
    width: 100%;
    height: 100%;
}


.prodtlmarkettxt {
    color: #010101;
    font-Size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 18px;
    word-Wrap: break-word
}

.likeCount_all {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.likeCount_all .text {
    font-size: 14px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    color: #212529;
    margin-right: 15px;
}

.likeCount_all .text strong {
    margin-right: 5px;
    font-size: 13px;
}


.infolabbrdr {
    border: 1px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    width: 95%;
}

.infoflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
}

.mainrattingarea {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 10px;
}

.mainrattingarea .star-ratings {
    display: flex !important;
}

.invitebtn {
    background-color: #009E60;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid #009E60;
    font-size: 14px;
    border-radius: 10px;
    transition: all 0.5s ease;
}

.invitebtn:hover {
    border: 1px solid #009E60;
    color: #009E60;
    background-color: #fff;
}

.ocentxt {
    color: #585454;
    font-Size: 24px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-Height: 22px;
    word-Wrap: break-word;
    padding-bottom: 5px;
}

.pcndgtxt {
    color: #585454;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
    word-Wrap: break-word
}

.dbytxtp {
    color: #585454;
    font-Size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 18px;
}

.rctdivimg {
    width: 90px;
    min-width: 90px;
    height: 90px;
    cursor: pointer;
}

.rctdivimg img {
    width: 100%;
    height: 100%;
}

.groupdiv {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start !important;
}

.groupdiv .rating_box {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 20px;
}

.groupdiv .rating_box i {
    font-size: 16px;
    color: #a19c9c;
}

.groupdiv .rating_box i.active {
    color: rgb(252, 106, 2);
}

.giveratting_btn {
    padding: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.giveratting_btn .btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: 1px solid #AB4AD9;
    width: auto;
    height: auto;
    padding: 6px 15px;
    /* background-color: #4C58FC; */
    /* color: #fff; */
    background-color: transparent;
    /* color: #009E60; */
    color: #3E3E3E;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.giveratting_btn .btn i {
    font-size: 18px;
    margin-right: 5px;
    color: #E9B601;
}

.giveratting_btn .btn:hover {
    box-shadow: 2px 2px 2px 0px #bebebe;
}

.giveratting_btn .btn img {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.socialdivflx {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0px;
}

.bgsocial {
    background-color: #010101;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.5s ease;
}

.bgsocial:hover {
    background-color: #7f86e9;
    color: #fff;
}

.cntntbrdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.flxcntnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.getdrctnBtnDiv {
    padding: 12px 0px;
    display: flex;
    justify-content: end;
}

.getdirectionbtn {
    background-color: #009E60;
    color: #fff;
    border-radius: 15px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 500;
}

.getdirectionbtn i {
    display: none;
}

.getdirectionbtn:hover {
    color: #fff;
}

/* .cntncttxt {
    color: #010203;
    font-Size: 28px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
    word-Wrap: break-word
} */

.cntncttxt {
    color: #010203;
    font-Size: 20px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
}

.ArrwiconDiv {
    background-color: #4C58FC;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ArrwiconDiv span {
    color: #fff !important;
    font-size: 12px !important;
}

.divcnctflx {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}


.brdrdivdtls {
    width: 100%;
    max-width: 32%;
    border: 2px solid #DBDEFE;
    padding: 8px 15px;
    border-radius: 10px;
}

.divcnctflx.non_member .brdrdivdtls {
    border: none;
    max-width: 100%;
}

.dibmailflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.envelopdiv {
    width: 100%;
    max-width: 48%;
    border: 2px solid #DBDEFE;
    padding: 15px;
    border-radius: 10px;
}

.divloctin {
    width: 100%;
    max-width: 100%;
    border: 2px solid #DBDEFE;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.txtdtlmn {
    color: #585454;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;

}

.txtdtlmn i {
    margin-right: 10px;
    font-weight: 600;
}

.mapwidth {
    width: 100%;
}

.mapwidth iframe {
    width: 100%;
    border-radius: 10px;
}

.abtbrsdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.txtabtmain {
    color: #585454;
    font-Size: 12px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-height: 16px;
    margin: 10px 0px 0px 0px;
}

.servcbrdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.srvcboxbrdr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 20px;
    row-gap: 10px;
}

.brdrmaindesign {
    width: 100%;
    max-width: 22%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 14px;
    font-Family: 'Roboto';
    font-Weight: 500;
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    cursor: pointer;
}

.brdrmaindesign:hover,
.brdrseo:hover,
.brdrseosms:hover,
.brdrprnt:hover,
.brdrbrand:hover {
    background: #7f86e9;
    color: #fff;
}

.brdrseo {
    width: 100%;
    max-width: 11%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.brdrseosms {
    width: 100%;
    max-width: 17%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}

.brdrprnt {
    width: 100%;
    max-width: 20%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.brdrbrand {
    width: 100%;
    max-width: 20%;
    background-color: #DBDEFE;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: #585454;
    font-Size: 18px;
    font-Family: 'Roboto';
    font-Weight: '400';
    letter-Spacing: 1px;
    line-height: 22px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.prdrtbrdr {
    border: 2px solid #DBDEFE;
    margin: 15px 20px;
    border-radius: 10px;
    padding: 10px 15px;
}

.prdrtflxdivmain {
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-start;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-wrap: nowrap;
    overflow-y: auto;
    gap: 1%;
    margin-top: 20px;
}

.brdrprdctdiv {
    border: 1px solid #DBDEFE;
    padding: 10px;
    width: 100%;
    /* max-width: 24%; */
    max-width: 32%;
    min-width: 32%;
    overflow: hidden;
    margin-bottom: 15px;
}

.brdrprdctdiv:hover .prdcgimg img {
    transform: scale(1.15);
}

.txtdiv {
    color: #000000;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    line-Height: 22px;
    padding: 10px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.txtpara {
    color: #585454;
    font-Size: 10px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    letter-Spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.prdcgimg {
    width: 100%;
    height: 150px;
    cursor: pointer;
    overflow: hidden;
}

.prdcgimg img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: 0.5s all ease;
    /* overflow: hidden; */
}

.pdrmainflx {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 15px;
}

.pdfflxDiv {
    width: 30%;
}

.pdfimg {
    width: 120px;
    height: 126px;
    position: relative;
    margin: auto;
}

.pdfimg img {
    width: 100%;
    height: 100%;
}

.inpuduv {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

}

.inpuduv input {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.prfltxtprdct {
    color: #000;
    font-Size: 14px;
    font-Family: 'Roboto';
    font-Weight: 600;
    letter-Spacing: 0.42px;
    text-align: center;
}

.prfldtlsprdct {
    color: #585454;
    font-Size: 14px;
    font-Family: 'Roboto';
    font-Weight: 300;
    letter-Spacing: 0.24px;
    line-height: 18px;
    width: 100%;
    max-width: 60%;
    margin-top: 10px;
    text-align: center;
}

.prflmainflx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px;
}

/* Responsive */

@media(max-width:1199px) {
    .cntncttxt {
        font-size: 20px;
    }

    .infolabbrdr {
        width: 90%;
    }

    .prfldtlsprdct {
        max-width: 100%;
    }

    .pdfimg {
        width: 55px;
        height: 60px;
    }

    .brdrseosms {
        width: 100%;
        max-width: 22%;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 25%;
    }

    .ocentxt {
        font-Size: 20px;
        line-Height: 25px;
    }
}

@media(max-width:991px) {

    .bggradient {
        height: 250px;
    }

    .savecontactbtn {
        font-Size: 14px;
        line-height: 18px;
        padding: 10px 20px;
        white-space: nowrap;
    }

    .txtdtlmn {
        color: #585454;
        font-Size: 14px;
        line-Height: 18px;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 32%;
    }

    .brdrseo {
        width: 100%;
        max-width: 20%;
    }

    .brdrseosms {
        width: 100%;
        max-width: 30%;
    }

    .brdrprnt {
        width: 100%;
        max-width: 24%;
    }
}

@media(max-width:767px) {

    .prdrtflxdivmain {
        row-gap: 10px;
    }

    .brdrprdctdiv {
        max-width: 49%;
        min-width: 48%;
    }

    .persondetailsdiv {
        flex-wrap: wrap;
        gap: 15px;
    }

    .img_profileflx {
        margin-left: 0;
    }

    .txtdiv {
        font-Size: 15px;
        padding: 7px 0px;
    }

    .txtdear {
        color: #010203;
        font-Size: 12px;
        max-width: 70%;
        padding-top: 5px;
    }

    .pdfimg {
        width: 40px;
        height: 40px;
    }

    /* .pdfflxDiv {
        width: 47%;
    } */

    .prfldtlsprdct {
        font-Size: 12px;
    }

    .savecontactbtn {
        padding: 8px 20px;
    }

    /* .imgmain {
        width: 100px;
        height: 100px;
    } */

    .imgbrdr {
        width: 100%;
        min-width: 105px;
        max-width: 105px;
        height: 105px;
    }

    .profiledtlstxt {
        color: #010101;
        font-Size: 20px;
        font-Weight: 500;
        line-Height: 28px;
    }

    .likespiner {
        padding: 5px 10px;
        width: 90px;
        height: 36px;
    }

    .likespiner img {
        width: 20px;
        height: auto;
    }

    .whatsppimg,
    .callimg {
        width: 42px;
        height: 33px;
    }

    .pcndgtxt {
        color: #585454;
        font-Size: 14px;
        line-Height: 21px;
    }

    .txtdtlmn {
        color: #585454;
        font-Size: 14px;
        line-Height: 20px;
    }

    .divcnctflx {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
        column-gap: 10px;
    }

    .brdrdivdtls {
        width: 100%;
        max-width: 48%;
    }

    .brdrdivdtls:nth-child(3) {
        width: 100%;
        max-width: 70%;
    }

    .dibmailflx {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 15px;
    }

    .envelopdiv {
        width: 100%;
        max-width: 70%;
        text-align: center;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 49%;
    }

    .brdrmaindesign:nth-child(3) {
        width: 100%;
        max-width: 40%;
    }

    .brdrseosms {
        width: 100%;
        max-width: 36%;
    }

    .brdrprnt {
        width: 100%;
        max-width: 40%;
    }

    .brdrbrand {
        width: 100%;
        max-width: 40%;
    }

    /* .infoflx {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    } */

    .groupdiv {
        padding-left: 8px;
    }
}

@media(max-width:575px) {

    .prdrtflxdivmain {
        flex-wrap: nowrap;
        overflow-y: auto;
    }

    .getdirectionbtn {
        background-color: #DEDEDE;
        color: #000;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .getdirectionbtn i {
        display: block;
        color: #000;
        margin-left: 5px;
        font-size: 16px;
    }

    .mainsavecontact {
        flex-wrap: wrap;
    }

    .brdrprdctdiv {
        min-width: 60%;
        max-width: 60%;
    }

    .txtdear {
        color: #010203;
        font-Size: 12px;
        max-width: 100%;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .neoobg .likespinerdivflx,
    .membrinvit .likespinerdivflx {
        justify-content: space-between;
    }

    .likespinerdivflx {
        justify-content: flex-start;
        width: 100%;
        padding: 10px 0px;
    }

    /* .imgmain {
        width: 82px;
        height: 85px;
    } */

    .imgbrdr {
        width: 100%;
        min-width: 90px;
        max-width: 90px;
        height: 90px;
    }

    .bggradient {
        /* background-image: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1));
        padding: 90px 0px; */
        height: 200px;
    }

    .dtlsprflnmmph {
        margin-top: 24px;
    }

    .whatsppimg,
    .callimg {
        width: 35px;
        height: 33px;
    }

    .callimg {
        order: 5;
        /* display: block; */
    }

    .likespiner {
        padding: 5px;
        width: 78px;
        height: 36px;
    }

    .img_profileflx {
        margin-left: 0px;
    }

    .infoflx {
        padding: 10px 10px;
    }

    .rctdivimg {
        width: 80px;
        min-width: 80px;
        height: 80px;
    }

    .bgsocial {
        width: 31px;
        height: 32px;
        font-size: 16px;
    }

    .socialdivflx {
        padding: 10px 0px;
    }

    .infolabbrdr {
        margin: 10px 20px;
    }

    .envelopdiv {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .brdrdivdtls:nth-child(3) {
        width: 100%;
        max-width: 100%;
    }

    .brdrdivdtls {
        width: 100%;
        max-width: 100%;
        text-align: left;
        padding: 0;
        border: none;
        margin-bottom: 6px;
    }

    .envelopdiv {
        text-align: left;
        padding: 0;
        border: none;
        margin-bottom: 6px;
    }

    .dibmailflx {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .divloctin {
        border: none;
        padding: 0;
    }

    .txtdtlmn i {
        font-size: 17px;
    }

    .cntncttxt {
        color: #010203;
        font-Size: 18px;
        font-family: 'Inter', sans-serif;
        font-Weight: 400;
        line-Height: 22px;
    }

    .brdrmaindesign {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrmaindesign:nth-child(3) {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrseo {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrseosms {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrprnt {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    .brdrbrand {
        width: 100%;
        max-width: 100%;
        font-Size: 15px;
    }

    /* .infoflx {
        flex-wrap: wrap;
    } */

    .giveratting_btn {
        width: 100%;
        height: auto;
    }

    /* .infoflx .d-flex {
        flex-wrap: wrap;
    } */

    .ocentxt {
        font-Size: 15px;
    }

}

@media(max-width: 510px) {
    .groupdiv .rating_box {
        padding-left: 0;
        margin-top: 10px;
        width: 100%;
    }

    .groupdiv .rating_box i {
        font-size: 12px;
    }
}

@media(max-width:480px) {

    .pdfflxDiv {
        width: 100%;
    }

    .cntncttxt {
        font-size: 16px;
    }

    .savecontactbtn {
        padding: 5px 15px;
        font-Size: 12px;
    }

    /* .imgmain {
        width: 75px;
        height: 77px;
    } */

    .imgbrdr {
        width: 100%;
        min-width: 85px;
        max-width: 85px;
        height: 85px;
    }

    .profiledtlstxt {
        font-Size: 17px;
        line-Height: 23px;
    }

    .prodtlmarkettxt {
        font-Size: 12px;
        line-Height: 16px;
    }

    .ocentxt {
        font-Size: 20px;
        line-Height: 23px;
        padding-bottom: 0px;
    }

    .rctdivimg {
        width: 75px;
        min-width: 75px;
        height: 72px;
    }

    .dbytxtp {
        font-Size: 12px;
        line-Height: 15px;
    }

    .brdrdivdtls {
        text-align: left;
    }

    .bggradient {
        height: auto;
    }

    .brdrmaindesign {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrseo {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrseosms {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrprnt {
        font-Size: 14px;
        padding: 5px;
    }

    .brdrbrand {
        font-Size: 14px;
        padding: 5px;
    }

    .star-container .widget-svg {
        width: 15px !important;
        height: 15px !important;
    }

    .groupdiv .rating_box {
        margin-top: 5px;
    }

    .groupdiv .rating_box {
        /* justify-content: end; */
        justify-content: start;
    }

    .pcndgtxt {
        font-Size: 12px;
        line-Height: normal;
    }

    .mainrattingarea {
        padding: 0px 10px 10px;
    }

    .giveratting_btn .btn {
        padding: 5px 10px;
        font-size: 12px;
    }

    .giveratting_btn .btn img {
        width: 18px;
    }

    .likeCount_all .text {
        font-size: 10px;
        margin-right: 15px;
    }

    .likeCount_all .text strong {
        margin-right: 3px;
        font-size: 10px;
    }
    .brdrprdctdiv {
        min-width: 75%;
        max-width: 75%;
    }
}

@media(max-width:420px) {
    .rctdivimg {
        width: 100%;
        max-width: 70px;
        min-width: 70px;
        height: 70px;
    }

    .ocentxt {
        font-Size: 14px;
        line-Height: normal;
        padding-bottom: 2px;
    }

    .infoflx {
        padding: 8px 8px;
    }

    .dbytxtp {
        font-Size: 10px;
        line-Height: 15px;
    }

    .txtdtlmn {
        font-Size: 12px;
        line-Height: 12px;
    }

    /* .envelopdiv {
        text-align: left;
        padding: 5px;
    } */

    /* .brdrmaindesign {
        width: 100%;
        max-width: 100%;
    } */

}