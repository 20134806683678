.WithdrawTop_Heading {
    width: 100%;
    height: auto;
    padding: 15px 30px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
}

.WithdrawTop_Heading .back_btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 30px;
    font-size: 24px;
    color: #000;
}

.WithdrawTop_Heading .title {
    font-size: 24px;
    line-height: normal;
    text-align: left;
    font-weight: 600;
    color: #000;
    padding: 0;
    margin: 0;
}

.EarnedBalanceTransfer_sec {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1%;
    background-color: #fff;
    padding: 15px 20px 30px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.EarnedBalanceTransfer_box {
    width: 100%;
    max-width: 32%;
    min-width: 32%;
    height: auto;
    padding: 20px;
    border-radius: 12px;
}

.EarnedBalanceTransfer_box .cardheding {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    color: #fff;
    padding: 0;
    margin-bottom: 10px;
}

.EarnedBalanceTransfer_box .cardamount {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
    color: #fff;
    padding: 0;
    margin-bottom: 15px;
}

.EarnedBalanceTransfer_box .downarrow_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: end;
}

.EarnedBalanceTransfer_box .downarrow_btn .btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: 1px solid #fff;
    background-color: transparent;
    border-radius: 50%;
    padding: 6px;
    width: 100%;
    max-width: 28px;
    min-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
}


.Transactionhistory_sec {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1%;
}

.Bankaccount_wrap {
    width: 100%;
    max-width: 49%;
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
}

.Bankaccount_wrap .head {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #000;
    padding: 0;
    margin-bottom: 10px;
}

.Bankaccount_wrap .box {
    width: 100%;
    height: auto;
    padding: 20px;
    border: 1px solid #C6C6C6;
    border-radius: 12px;
}

.Bankaccount_wrap .box .text {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    color: #000000;
    padding: 0;
    margin-bottom: 5px;
}

.Bankaccount_wrap .botton_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px 20px;
}

.Bankaccount_wrap .botton_wrap .btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.Transactionhistory {
    width: 100%;
    max-width: 49%;
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
}

.Transactionhistory .head {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #000;
    padding: 0;
    margin-bottom: 10px;
}

.Transactionhistory .history_list {
    width: 100%;
    height: auto;
    list-style: none;
    padding: 0;
    margin: 0;
}

.Transactionhistory .history_list li {
    width: 100%;
    height: auto;
    padding: 15px;
    border: 1px solid #E7B4F9;
    background-color: transparent;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;
}

.Transactionhistory .date {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0;
}

.Transactionhistory .amount {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0;
}

.Transactionhistory .status {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.Transactionhistory .status span {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0;
}

.Transactionhistory .status img {
    width: 16px;
    height: auto;
}

.EarnBalanceTransfer_Details {
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 15px;
}

.EarnBalanceTransfer_Details table {
    width: 100%;
    height: auto;
}

.EarnBalanceTransfer_Details table tr {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #dee2e6;
}

.EarnBalanceTransfer_Details table tr:last-child {
    border-bottom: none;
}

.EarnBalanceTransfer_Details table tr td {
    border: none;
}

.EarnBalanceTransfer_Details table .profile_pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    margin-right: 10px;
}

.EarnBalanceTransfer_Details table .name {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin-bottom: 2px;
    white-space: nowrap;
}

.EarnBalanceTransfer_Details table .position {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.EarnBalanceTransfer_Details table .desc {
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    color: #363636;
    padding: 0;
    margin: 0;

}

.EarnBalanceTransfer_Details table .datetime {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.EarnBalanceTransfer_Details table .amount {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.EarnBalanceTransfer_Details table .status {
    width: 18px;
    height: auto;
}

.EarnBalanceTransfer_Details table .status img {
    width: 100%;
    height: 100%;
}


.AvailableTransfer_Balance {
    width: 100%;
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px 30px;
    margin-bottom: 15px;
}

.AvailableTransfer_Balance table {
    width: 100%;
    height: auto;
}

.AvailableTransfer_Balance table tr {
    width: 100%;
    height: auto;
}

.AvailableTransfer_Balance table tr td {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #4d4b4b;
    padding: 6px;
    margin: 0;
}

.AvailableTransfer_Balance table tr td.left {
    text-align: left;
}

.AvailableTransfer_Balance table tr td.right {
    text-align: right;
}


.TransferNow_btn {
    width: 100%;
    height: auto;
    margin: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.TransferNow_btn .btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    outline: 0;
    background-color: #28A608;
    color: #fff;
    width: 100%;
    max-width: 130px;
    height: 40px;
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}


/****Withdrawstatus_wrap***/

.Withdrawstatus_wrap {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 30px 30px 100px;
}


.Withdrawstatus_body {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    border: 1px solid #928E8E;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    padding: 30px 40px;
}

.Withdrawstatus_body .icon {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    ;
    margin: 0 auto 10px;
}

.Withdrawstatus_body .icon img {
    width: 100%;
    height: 100%;
}

.Withdrawstatus_body .heading {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    padding: 0;
    margin-bottom: 20px;
}

.Withdrawstatus_body .subheading {
    font-size: 16px;
    line-height: normal;
    text-align: center;
    font-weight: 600;
    color: #000000;
    padding: 0;
    margin-bottom: 10px;
}

.Withdrawstatus_body .desc {
    font-size: 14px;
    line-height: normal;
    text-align: center;
    font-weight: 400;
    color: #000000;
    padding: 0;
    margin-bottom: 30px;
}

.Withdrawstatus_body .activebtn_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Withdrawstatus_body .activebtn_btn .btn {
    width: 100%;
    max-width: 130px;
    height: 45px;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 12px;
    padding: 5px 15px;
    margin: 0;
}

.Withdrawstatus_body .activebtn_btn .btn.succ {
    background-color: #27AE60;
}

.Withdrawstatus_body .activebtn_btn .btn.erro {
    background-color: #F21E1E;
}


/***bankaccountUpdate_page***/
.bankaccountUpdate_page {
    width: 100%;
    height: auto;
}

.bankaccountadd_form {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    padding: 30px 50px;
}

.bankaccountadd_form .formheading {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin-bottom: 15px;
    margin-top: 15px;
}

.bankaccountadd_form form {
    width: 100%;
    max-width: 50%;
    height: auto;
}

.bankaccountadd_form .form-group {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.bankaccountadd_form .form-group .form-control {
   width: 100%;
   height: 40px;
   padding: 5px 10px;
   font-size: 15px;
   text-align: left;
   font-weight: 400;
   color: #363636;
   border-radius: 8px;
}

.bankaccountadd_form .form-group .form-text {
    font-size: 12px;
    text-align: left;
    font-weight: 400;
    color: #272727;
    margin-left: 10px;
    margin-top: 0;
}

.bankaccountadd_form .nextButton {
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: end;
   margin-top: 40px;
   margin-bottom: 20px;
}

.bankaccountadd_form .nextButton .btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    background-color: #27AE60;
    color: #fff;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    max-width: 100px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bankaccountadd_form .form-group .form-check {
    position: relative;
    display: block;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px 10px;
    padding-left: 30px;
    margin-bottom: 10px;
}

.bankaccountadd_form .form-group .form-check label {
    font-size: 15px;
   text-align: left;
   font-weight: 500;
   color: #363636;
}

.uploadfile_check {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 6px 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploadfile_check label {
    font-size: 15px;
    text-align: left;
    font-weight: 500;
    color: #363636;
    margin-bottom: 0;
}

.uploadfile_check span {
    font-size: 11px;
}

.uploadfile_check .attach_icon {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 20px;
    position: relative;
}

.uploadfile_check input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}


/*****Responsive********/

@media (max-width: 768px) {
    .EarnedBalanceTransfer_box .cardheding {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .EarnedBalanceTransfer_box .cardamount {
        font-size: 20px;
    }

    .EarnedBalanceTransfer_box .downarrow_btn .btn {
        max-width: 20px;
        min-width: 20px;
        height: 20px;
        font-size: 10px;
    }

    .Bankaccount_wrap {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .Transactionhistory {
        max-width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 576px) {
    .WithdrawTop_Heading .title {
        font-size: 20px;
    }

    .WithdrawTop_Heading .back_btn {
        margin-right: 20px;
        font-size: 20px;
    }

    .WithdrawTop_Heading {
        padding: 15px 10px;
    }

    .EarnedBalanceTransfer_sec {
        padding: 15px 10px;
    }

    .EarnedBalanceTransfer_box {
        padding: 10px 12px;
    }

    .EarnedBalanceTransfer_box .cardheding {
        font-size: 12px;
        margin-bottom: 2px;
    }

    .EarnedBalanceTransfer_box .cardamount {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .Bankaccount_wrap {
        padding: 10px;
    }

    .Transactionhistory {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .WithdrawTop_Heading .title {
        font-size: 18px;
    }

    .EarnedBalanceTransfer_box {
        padding: 8px 5px;
        border-radius: 6px;
    }

    .EarnedBalanceTransfer_box .downarrow_btn .btn {
        max-width: 16px;
        min-width: 16px;
        height: 16px;
        font-size: 8px;
    }

    .EarnedBalanceTransfer_box .cardheding {
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 1px;
    }

    .EarnedBalanceTransfer_box .cardamount {
        font-size: 12px;
        margin-bottom: 4px;
    }

    .Bankaccount_wrap .head {
        font-size: 16px;
    }

    .Bankaccount_wrap .box {
        padding: 10px;
    }

    .Bankaccount_wrap .box .text {
        font-size: 14px;
    }

    .Transactionhistory .head {
        font-size: 16px;
    }

    .Transactionhistory .history_list li {
        padding: 10px;
    }

    .Transactionhistory .date {
        font-size: 12px;
    }

    .Transactionhistory .amount {
        font-size: 12px;
    }

    .Transactionhistory .status span {
        font-size: 12px;
    }

    .Transactionhistory .status img {
        width: 12px;
        height: auto;
    }

    .Withdrawstatus_wrap {
        padding: 15px 15px 15px;
    }
    .Withdrawstatus_body {
        padding: 15px 15px;
    }
    .Withdrawstatus_body .icon {
        width: 40px;
        height: 40px;
    }
    .Withdrawstatus_body .heading {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .Withdrawstatus_body .subheading {
        font-size: 14px;
    }
    .Withdrawstatus_body .desc {
        font-size: 12px;
    }
}