.shredivflx {
  /* background-color: #009e60;
  padding: 10px 17px;
  display: flex;
  justify-content: flex-start;
  gap: 3%;
  align-items: center;
  color: #fff;
  font-size: 14px;
  border: 1px solid #009e60;
  border-radius: 10px;
  transition: all 0.5s ease; */

  border: 1px solid #009e60;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border: none;
  color: #F5EEEE;
  color: #f5eeee;
  padding: 10px 24px;
  margin: 0;
  border-radius: 6px;
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: auto;
  background-color: #009e60;
  width: 144px;
}

.countdown-circle {
  position: absolute; /* Position it absolutely relative to the button */
  top: -8px; /* Adjust as needed */
  right: -5px; /* Adjust as needed */
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: green; /* Circle color */
  color: white; /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust text size as needed */
  font-weight: bold;
}



.shredivflx i {
  color: #fff;
  margin-left: 7px;
}

.green-dot-unread-message {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
}

.shredivflx:hover {
  border: 1px solid #009e60;
  color: #009e60;
  background-color: #fff;
}

.shredivflx:hover i {
  color: #009e60;
}

.quckcntntdiv {
  border: 2px solid #dbdefe;
  margin: 15px 20px 0px;
  border-radius: 10px;
}

.quckcntntdiv .top {
  width: 100%;
  height: auto;
  padding: 10px 15px 10px;
  background-color: #f9e8ff;
}

.quckcntntdiv .wfwfe3g3wtg3 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quckcntntdiv .wfwfe3g3wtg3 span {
  font-size: 14px;
  line-height: normal;
  text-align: left;
  font-weight: 400;
  color: rgba(102, 102, 102, 0.8);
  margin-right: 10px;
}

.quckcntntdiv .wfwfe3g3wtg3 a {
  width: auto;
  height: auto;
  text-decoration: none;
  outline: 0;
  border: none;
  box-shadow: none;
  padding: 4px 15px;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 6px;
  color: rgba(102, 102, 102, 0.8);
}

.quckcntntdiv .bottom {
  width: 100%;
  height: auto;
  padding: 10px 15px;
}

.cntghjflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.cntghjflx span {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  font-weight: 400;
  color: rgba(102, 102, 102, 0.8);
}

.crtdivtct {
  color: #010203;
  font-size: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 32px;
}

.updgttxt {
  color: #000;
  border: none;
  background: transparent;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 20px;
}

.updgttxt:hover {
  color: #000;
}

.inptfgfrmdiv {
  width: 100%;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap !important;
  overflow: hidden;
}

.inptfgfrmdiv input {
  width: 100%;
  padding: 6px 10px;
  border-radius: 5px;
  margin: 10px 0px;
  border: 1px solid #dbdefe;
}

.inptfgfrmdiv .countrycodebox {
  width: 100%;
  min-width: 60px !important;
  max-width: 130px !important;
}

.form-control {
  padding: 0.375rem 0.25rem;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  outline: 0;
}

.inptfgfrmdiv input:focus {
  outline: 0;
}

.svaecntcydiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 2%;
  margin: 15px 0px;
}

.txtplt {
  color: #010203;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 20px;
  background: transparent;
  border: none;
  outline: 0;
}

.txtplt:hover {
  color: #010203;
}

.btnsvsnd {
  color: #fff;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 22px;
  background-color: #009e60;
  border: none;
  outline: 0;
  padding: 10px 15px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.btnsvsnd:hover {
  color: #fff;
  background-color: #7f86e9;
}

.vdoighh {
  width: 100%;
  padding-top: 25px;
  height: 415px;
}

.vdoighh .ReactPlayerdiv {
  width: 100% !important;
  height: 100% !important;
}

.vdoighh iframe {
  width: 100%;
}

@media (max-width: 767px) {
  .cntghjflx {
    flex-wrap: wrap;
  }

  .crtdivtct {
    font-size: 20px;
    line-height: 24px;
  }

  .txtplt {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }

  .btnsvsnd {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    background-color: #009e60;
    padding: 7px 10px;
    border-radius: 10px;
  }

  .svaecntcydiv {
    justify-content: space-between;
  }
}

@media (max-width: 575px) {
  .shredivflx {
    /* order: 3;
    width: 80px;
    height: 30px;
    font-size: 13px; */

    order: 3;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    color: #F5EEEE;
    padding: 9px 24px;
    margin: 0;
    border-radius: 6px;
    font-size: 16px;
    line-height: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 145px;
    height: auto;
  }

  .shredivflx i {
    font-size: 13px;
  }

  .whatsppimg {
    order: 4;
  }

  .cntghjflx {
    /* flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */
    column-gap: 15px;
    row-gap: 5px;
  }

  .crtdivtct {
    font-size: 14px;
  }

  .updgttxt {
    font-size: 12px;
  }

  .inptfgfrmdiv input {
    width: 100%;
    padding: 5px 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .svaecntcydiv {
    flex-wrap: wrap;
  }

  .quickContctBtn {
    width: 100%;
    margin: 10px 0px;
  }

  .btnsvsnd {
    margin: 10px 0px 0px 0px;
  }

  .quckcntntdiv .wfwfe3g3wtg3 {
    display: block;
  }

  .inptfgfrmdiv .countrycodebox {
    width: 100%;
    min-width: 60px !important;
    max-width: 90px !important;
    font-size: 10px;
    font-weight: 600;
  }

  .Messagebtn_ {
    width: 122px;
  }

  .shredivflx {
    width: 122px;
  }
}

@media (max-width: 420px) {
  .crtdivtct {
    font-size: 15px;
    line-height: 21px;
  }
}