.ExploreBanner_sec {
    width: 100%;
    height: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}


.slick-dots li button:before {
    color: #6A08B7;
    font-size: 14px;
}

.slick-dots li.slick-active button:before {
    color: #6A08B7;
}

.ExploreBanner_sec .content {
    width: 100%;
    max-width: 55%;
    height: auto;
    padding: 50px 0;
}

.ExploreBanner_sec .heading {
    font-size: 42px;
    text-align: left;
    font-weight: 700;
    color: #04F9EA;
    padding: 0;
    margin-bottom: 30px;
}

.ExploreBanner_sec .price_tag {
    width: 100%;
    height: auto;
    padding: 10px 20px;
    border-radius: 18px;
    background: linear-gradient(90deg, rgba(94, 16, 223, 1) 0%, rgba(51, 9, 121, 1) 100%);
    margin-bottom: 30px;
    position: relative;
}

.ExploreBanner_sec .price_tag .headline {
    font-size: 26px;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    padding: 0;
    margin: 0;
}

.ExploreBanner_sec .price_tag .ctrbox {
    position: absolute;
    top: -27px;
    right: -25px;
    z-index: 1;
    width: 110px;
    height: 110px;
    border-radius: 50%;
}

.ExploreBanner_sec .launch_wrap {
    width: 100%;
    height: auto;
}

/* .ExploreBanner_sec .launch_wrap div {
    margin-bottom: 10px;
} */


.ExploreBanner_sec .launch_icon {
    width: 100%;
    max-width: 40px;
    min-width: 25px;
    height: auto;
    margin-right: 10px;
}

.ExploreBanner_sec .launch_icon img {
    width: 100%;
    height: 100%;
}

.ExploreBanner_sec .launch_wrap .head {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    color: #fff;
    padding: 0;
    margin-bottom: 15px;
}

.ExploreBanner_sec .launch_wrap .list {
    width: 100%;
    height: auto;
    list-style: none;
    padding-left: 20px;
    margin: 0;
}

.ExploreBanner_sec .launch_wrap .list li {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.ExploreBanner_sec .launch_wrap .list li span {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ExploreBanner_sec .launch_wrap .list li span img {
    width: 100%;
    height: 100%;
}

.ExploreBanner_sec .banner_bottom {
    width: 100%;
    height: auto;
    background-color: #d8d7d7;
    padding: 20px 15px;
}

.ExploreBanner_sec .banner_bottom .para {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #080808;
    max-width: 200px;
    padding: 0;
    margin-bottom: 15px;
}

.ExploreBanner_sec .banner_bottom .contact_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.ExploreBanner_sec .banner_bottom .getnow_btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border: none;
    width: 100%;
    max-width: 120px;
    height: 38px;
    border-radius: 20px;
    padding: 5px 20px;
    background: linear-gradient(90deg, rgba(94, 16, 223, 1) 0%, rgba(114, 41, 233, 1) 17%, rgba(51, 9, 121, 1) 100%);
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

.ExploreBanner_sec .banner_bottom .getnow_btn span {
    padding: 2px;
    border-radius: 50%;
    border: 1px solid #fcfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.ExploreBanner_sec .banner_bottom .getnow_btn span i {
    font-size: 10px;
}

.ExploreBanner_sec .banner_bottom .number {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    color: #080808;
}

.ExploreBanner_sec .banner_bottom .number span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.ExploreBanner_sec .banner_bottom .number span img {
    width: 100%;
    height: 100%;
}

.ExploreBanner_sec .manimg_wrap {
    position: absolute;
    right: 12%;
    bottom: 0;
    z-index: 2;
    width: 100%;
    max-width: 380px;
    height: auto;

}

.ExploreBanner_sec .manimg_wrap img {
    width: 100%;
    height: 100%;
}

.innovativetechnology_sec {
    width: 100%;
    height: auto;
    padding: 80px 0 50px;
}

.innovativetechnology_sec .heding {
    font-size: 24px;
    text-align: left;
    font-weight: 700;
    line-height: normal;
    color: #080808;
    padding: 0;
    margin-bottom: 30px;
    max-width: 450px;
}

.innovativetechnology_sec .text {
    font-size: 16px;
    text-align: left;
    font-weight: 500;
    line-height: normal;
    color: #080808;
    padding: 0;
    margin-bottom: 40px;
    max-width: 450px;
}

.innovativetechnology_sec .technology_card {
    width: 100%;
    height: auto;
    padding: 20px 15px;
}

.innovativetechnology_sec .icon_wrap {
    width: 100%;
    max-width: 120px;
    height: 120px;
    border: 1px solid #000000;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 10px;
}

.innovativetechnology_sec .icon_wrap img {
    width: 100%;
    height: 100%;
}

.innovativetechnology_sec .name {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    color: #000000;
}

.NFCbusinessCard_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.NFCbusinessCard_sec .nfcimg_wrap {
    margin-left: -186px;
    width: auto;
    height: auto;
}

.NFCbusinessCard_sec .nfcimg_wrap img {
    width: 100%;
    height: 100%;
}

.NFCbusinessCard_sec .content_ {
    width: 100%;
    height: auto;
}

.NFCbusinessCard_sec .content_ .desc {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #5D5B5B;
    padding: 0;
    margin-bottom: 30px;
}

.NFCbusinessCard_sec .content_ ol {
    width: 100%;
    height: auto;
    padding-left: 40px;
    margin-bottom: 30px;
}

.NFCbusinessCard_sec .content_ ol li {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #5D5B5B;
    text-transform: capitalize;
    padding: 0;
    margin-bottom: 4px;
}

.NFCbusinessCard_sec .content_ .desc2 {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #5D5B5B;
    padding: 0;
    margin: 0;
}

.WhatWeOffer_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.WhatWeOffer_sec .heading {
    font-size: 42px;
    text-align: left;
    line-height: normal;
    font-weight: 700;
    color: #000000;
    max-width: 550px;
    padding: 0;
    margin-bottom: 30px;
}

.WhatWeOffer_sec .smart_card {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 5px 0px #424141;
    border: 1px solid #6A08B7;
    border-radius: 0px 25px 25px 25px;
    overflow: hidden;
    margin-bottom: 30px;
}

.WhatWeOffer_sec .smart_card .top {
    width: 100%;
    height: auto;
    background-color: #050505;
    padding: 15px 30px;
}

.WhatWeOffer_sec .smart_card .top .head {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #FBFBFB;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    max-width: 130px;
    text-transform: uppercase;
}

.WhatWeOffer_sec .smart_card .bottom {
    width: 100%;
    height: 150px;
    padding: 15px 30px;
}

.WhatWeOffer_sec .smart_card .bottom .desc {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #000000;
    text-align: center;
}

.OrderNow_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OrderNow_btn .btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border: none;
    width: auto;
    height: 40px;
    border-radius: 20px;
    padding: 5px 20px;
    background: linear-gradient(90deg, rgba(94, 16, 223, 1) 0%, rgba(114, 41, 233, 1) 17%, rgba(51, 9, 121, 1) 100%);
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.OrderNow_btn .btn span {
    padding: 2px;
    border-radius: 50%;
    border: 1px solid #fcfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.OrderNow_btn .btn span i {
    font-size: 10px;
}

.MarketingGrowth_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.MarketingGrowth_card {
    width: 100%;
    height: auto;
    border-radius: 25px;
    display: flex;
    background: linear-gradient(90deg, rgba(65, 215, 248, 1) 0%, rgba(30, 147, 255, 1) 100%);
}

.MarketingGrowth_card .left_wrap {
    width: 100%;
    max-width: 250px;
    height: auto;
    padding: 20px 30px 0;
}

.MarketingGrowth_card .left_wrap img {
    width: 100%;
    height: 100%;
}

.MarketingGrowth_card .right_wrap {
    width: 100%;
    height: auto;
    padding: 30px 40px 30px 0px;
}

.MarketingGrowth_card .right_wrap .head {
    font-size: 36px;
    line-height: normal;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    text-align: left;
    padding: 0;
    margin-bottom: 20px;
}

.MarketingGrowth_card .right_wrap .text {
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    text-align: left;
    color: #000000;
    max-width: 550px;
    padding: 0;
    margin-bottom: 10px;
}

.ExploreBusiness_Rewards {
    width: 100%;
    height: auto;
    position: relative;
    padding: 100px 0;
}

.ExploreBusiness_Rewards .bgimg_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.ExploreBusiness_Rewards .bgimg_wrap img {
    width: 100%;
    height: 100%;
}

.ExploreBusiness_Rewards .content {
    position: relative;
    width: 100%;
    max-width: 50%;
    height: auto;
    z-index: 2;
}

.ExploreBusiness_Rewards .head {
    font-size: 56px;
    line-height: normal;
    text-align: left;
    font-weight: 700;
    color: #fff;
    padding: 0;
    margin-bottom: 30px;
}

.ExploreBusiness_Rewards .text {
    font-size: 18px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #fff;
    padding: 0;
    margin-bottom: 90px;
}

.PromoteMembers_Businesses {
    width: 100%;
    height: auto;
    padding: 80px 0;
}

.PromoteMembers_Businesses .heading {
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin-bottom: 20px;
}

.PromoteMembers_Businesses .Businesses_card {
    width: 100%;
    height: auto;
    max-width: 250px;
    margin: 0 auto;
}

.PromoteMembers_Businesses .Businesses_card img {
    width: 100%;
    height: 100%;
}

.PromoteMembers_Businesses .content {
    width: 100%;
    height: auto;
}

.PromoteMembers_Businesses .subhead {
    font-size: 26px;
    text-align: left;
    line-height: normal;
    color: #000000;
    font-weight: 600;
    padding: 0;
    margin-bottom: 20px;
}

.PromoteMembers_Businesses .text {
    font-size: 18px;
    text-align: left;
    line-height: normal;
    color: #000000;
    font-weight: 500;
    padding: 0;
    margin-bottom: 20px;
}

.PromoteMembers_Businesses .desc {
    font-size: 14px;
    text-align: left;
    line-height: normal;
    color: #000000;
    font-weight: 400;
    padding: 0;
    margin-bottom: 40px;
}

.ReferEarnProgram_sec {
    width: 100%;
    height: auto;
    padding: 50px 0;
    background: linear-gradient(90deg, rgba(240,216,255,1) 0%, rgba(230,189,255,1) 100%);
    position: relative;
}

.ReferEarnProgram_sec .Program_img {
   position: absolute;
   right: 0;
   bottom: 0;
   width: 100%;
   max-width: 400px;
   height: auto;
}

.ReferEarnProgram_sec .Program_img img {
    width: 100%;
    height: 100%;
}

.ReferEarnProgram_sec .content {
    width: 100%;
    max-width: 60%;
    height: auto;
}

.ReferEarnProgram_sec .head {
   font-size: 48px;
   text-align: left;
   font-weight: 700;
   color: #8D05E1;
   line-height: normal;
   padding: 0;
   margin-bottom: 20px;
}

.ReferEarnProgram_sec .head span {
    color: #000000;
}

.ReferEarnProgram_sec .text {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    color: #000000;
    line-height: normal;
    padding: 0;
    margin-bottom: 30px;
}


/***Responsive****/

@media (max-width: 991px) {
    .ExploreBanner_sec .manimg_wrap {
        right: 5%;
        bottom: 0;
    }

    .NFCbusinessCard_sec .nfcimg_wrap {
        margin-left: 0;
        width: 100%;
        max-width: 80%;
        height: auto;
        margin: 0 auto 20px;
    }
    .ReferEarnProgram_sec .head {
        font-size: 30px;
    }
    .ReferEarnProgram_sec .text {
        font-size: 16px;
    }
    .ReferEarnProgram_sec .content {
        max-width: 50%;
    }
}

@media (max-width: 768px) {

    .ExploreBanner_sec .content {
        max-width: 65%;
    }

    .ExploreBanner_sec .manimg_wrap {
        right: 0;
        bottom: 0;
        max-width: 250px;
    }

    .ExploreBanner_sec .heading {
        font-size: 30px;
    }

    .ExploreBanner_sec .price_tag .headline {
        font-size: 16px;
    }

    .ExploreBanner_sec .price_tag .ctrbox {
        top: -20px;
        right: 0px;
        width: 80px;
        height: 80px;
    }

    .ExploreBanner_sec .launch_wrap .head {
        font-size: 24px;
    }

    .ExploreBanner_sec .launch_wrap .list li {
        font-size: 14px;
    }

    .innovativetechnology_sec .heding {
        text-align: center;
        margin: 0 auto 20px;
    }

    .innovativetechnology_sec .text {
        text-align: center;
        margin: 0 auto 30px;
    }

    .NFCbusinessCard_sec .nfcimg_wrap {
        max-width: 100%;
    }

    .WhatWeOffer_sec .heading {
        font-size: 28px;
        text-align: center;
        margin: 0 auto 30px;
    }

    .MarketingGrowth_card .left_wrap {
        max-width: 170px;
    }

    .MarketingGrowth_card .right_wrap .head {
        font-size: 26px;
    }

    .MarketingGrowth_card .right_wrap .text {
        font-size: 14px;
    }

    .MarketingGrowth_card .right_wrap {
        padding: 20px 30px 20px 0px;
    }

    .ExploreBusiness_Rewards .content {
        max-width: 60%;
    }

    .ExploreBusiness_Rewards {
        padding: 40px 0;
    }

    .ExploreBusiness_Rewards .head {
        font-size: 36px;
    }

    .ExploreBusiness_Rewards .text {
        margin-bottom: 40px;
    }

    .PromoteMembers_Businesses .heading { 
        font-size: 30px;
        text-align: center;
    }

    .ReferEarnProgram_sec .Program_img {
        max-width: 260px;
    }
    .ReferEarnProgram_sec .text {
        font-size: 12px;
    }
    .ReferEarnProgram_sec .head {
        font-size: 26px;
    }
    .ReferEarnProgram_sec .content {
        max-width: 60%;
    }
}

@media (max-width: 567px) {
    .ExploreBanner_sec .content {
        max-width: 100%;
    }

    .ExploreBanner_sec .manimg_wrap {
        display: none;
    }

    .NFCbusinessCard_sec {
        padding: 20px 0;
    }

    .MarketingGrowth_card .right_wrap .head {
        font-size: 20px;
    }

    .ExploreBusiness_Rewards .head {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .ExploreBusiness_Rewards .text {
        font-size: 12px;
    }
    .PromoteMembers_Businesses {
        padding: 20px 0;
    }
    .PromoteMembers_Businesses .subhead {
        font-size: 20px;
        font-weight: 700;
    }
    .PromoteMembers_Businesses .text {
        font-size: 16px;
        font-weight: 600;
    }
    .PromoteMembers_Businesses .desc {
        font-size: 12px;
        margin-bottom: 30px;
    }
    .ReferEarnProgram_sec .Program_img {
        max-width: 100%;
        position: relative;
        margin-bottom: 20px;
    }
    .ReferEarnProgram_sec .content {
        max-width: 100%;
    }
    .ReferEarnProgram_sec {
        padding: 20px 0;
    }
    .ReferEarnProgram_sec .head {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .ReferEarnProgram_sec .text {
        font-size: 12px;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .ExploreBanner_sec .banner_bottom .getnow_btn {
        width: 100%;
        max-width: 100px;
        height: 35px;
        padding: 5px 10px;
        font-size: 10px;
        margin-right: 20px;
    }

    .ExploreBanner_sec .banner_bottom .getnow_btn span {
        width: 14px;
        height: 14px;
    }

    .ExploreBanner_sec .banner_bottom .getnow_btn span i {
        font-size: 8px;
    }

    .ExploreBanner_sec .banner_bottom .number span {
        width: 20px;
        height: 20px;
    }

    .ExploreBanner_sec .banner_bottom .number {
        font-size: 14px;
    }

    .ExploreBanner_sec .launch_wrap .head {
        font-size: 20px;
    }

    .ExploreBanner_sec .launch_icon {
        max-width: 30px;
    }

    .ExploreBanner_sec .launch_wrap .list li {
        font-size: 12px;
    }

    .ExploreBanner_sec .launch_wrap .list li span {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    .innovativetechnology_sec .heding {
        font-size: 18px;
        margin: 0 auto 15px;
    }

    .innovativetechnology_sec .text {
        font-size: 12px;
        margin: 0 auto 10px;
    }

    .innovativetechnology_sec .icon_wrap {
        width: 100%;
        max-width: 80px;
        height: 80px;
        padding: 20px;
    }

    .innovativetechnology_sec {
        padding: 60px 0 20px;
    }

    .NFCbusinessCard_sec .nfcimg_wrap {
        margin: 0 auto 15px;
    }

    .NFCbusinessCard_sec .content_ .desc {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .NFCbusinessCard_sec .content_ ol {
        margin-bottom: 15px;
    }

    .NFCbusinessCard_sec .content_ ol li {
        font-size: 14px;
        margin-bottom: 3px;
    }

    .NFCbusinessCard_sec .content_ .desc2 {
        font-size: 15px;
    }

    .WhatWeOffer_sec .heading {
        font-size: 24px;
    }

    .MarketingGrowth_card .left_wrap {
        max-width: 120px;
        padding: 15px 15px 0;
    }

    .MarketingGrowth_card .right_wrap .head {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .MarketingGrowth_card .right_wrap .text {
        font-size: 10px;
    }

    .MarketingGrowth_card .OrderNow_btn .btn {
        height: 30px;
        padding: 5px 15px;
        font-size: 10px;
    }

    .MarketingGrowth_card .OrderNow_btn .btn span {
        width: 14px;
        height: 14px;
    }

    .MarketingGrowth_card .OrderNow_btn .btn span i {
        font-size: 8px;
    }

    .MarketingGrowth_card .right_wrap {
        padding: 15px 15px 15px 0px;
    }

    .MarketingGrowth_sec {
        padding: 20px 0;
    }

    .ExploreBusiness_Rewards {
        margin: 20px 0;
        padding: 20px 0;
    }

    .ExploreBusiness_Rewards .head {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .ExploreBusiness_Rewards .text {
        font-size: 10px;
        margin-bottom: 20px;
    }

    .ExploreBusiness_Rewards .content {
        max-width: 70%;
    }
    .PromoteMembers_Businesses .heading {
        font-size: 22px;
    }
    .PromoteMembers_Businesses .subhead {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .PromoteMembers_Businesses .text {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .PromoteMembers_Businesses .desc {
        font-size: 12px;
        margin-bottom: 20px;
        color: #585656;
    }
}

@media (max-width: 420px) {
    .MarketingGrowth_card .right_wrap .head {
        font-size: 14px;
    }
}