.venderdashboardhome_page {
    width: 100%;
    height: auto;
    padding: 30px 0;
    background-color: #D9D9D9;
}


/******DashboardStatus******/
.DashboardStatus {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 10px;
    padding: 20px 30px;
}

.DashboardStatus .allwrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

.DashboardStatus .box {
    width: 100%;
    height: 60px;
    border-right: 2px solid #bbbaba;
    display: grid;
    align-content: center;
}

.DashboardStatus .box:last-child {
    border: none;
}

.DashboardStatus .name {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: start;
}

.DashboardStatus .name img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.DashboardStatus .amount {
    font-size: 16px;
    font-weight: 700;
    color: #F20000;
    margin-bottom: 5px;
    text-align: center;
    padding: 0;
}

.DashboardStatus .text {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #484848;
    padding: 0;
    margin: 0;
}

.DashboardStatus .resp_allwrap {
    width: 100%;
    height: auto;
    display: none;
}

.DashboardStatus .toppart {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #B5B5B5;
    padding-bottom: 8px;
    margin-bottom: 20px;
}

.DashboardStatus .back_btn {
    font-size: 26px;
    color: #AFAFAF;
}

.DashboardStatus .bottompart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #B7B3B3;
    padding: 10px 15px;
    width: 100%;
    height: 100px;
}


@media (max-width: 767px) {
    .venderdashboardhome_page {
        background-color: #fff;
        padding: 5px 0;
    }

    .DashboardStatus .allwrap {
        display: none;
    }

    .DashboardStatus .resp_allwrap {
        display: block;
    }

    .DashboardStatus {
        padding: 20px 0px;
    }
}

/*******MyOfferVender_sec******/

.MyOfferVender_sec {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    padding: 30px 30px;
    margin-bottom: 20px;
}

.MyOfferVender_sec .top_area {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.MyOfferVender_sec .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #090808;
}

.MyOfferVender_sec .heading img {
    width: 25px;
    height: 30px;
    margin-right: 15px;
}

.MyOfferVender_sec .entervoucher_btn {
    width: auto;
    height: auto;
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline: 0;
    padding: 0;
    margin-left: 30px;
    text-align: center;
}

.MyOfferVender_sec .entervoucher_btn img {
    width: 100%;
    max-width: 30px;
    height: auto;
    margin-bottom: 5px;
}

.MyOfferVender_sec .entervoucher_btn span {
    font-size: 12px;
    font-size: 500;
    color: #000;
    text-align: center;
    display: none;
}


.MyOfferVender_sec .scanvoucher_btn {
    width: auto;
    height: auto;
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline: 0;
    padding: 0;
    display: none;
    text-align: center;
}

.MyOfferVender_sec .scanvoucher_btn img {
    width: 100%;
    max-width: 30px;
    height: auto;
    margin-bottom: 5px;
}

.MyOfferVender_sec .scanvoucher_btn span {
    font-size: 12px;
    font-size: 500;
    color: #000;
    text-align: center;
    display: none;
}

.MyOfferVender_sec .myofferimg_wrap {
    width: 100%;
    max-width: 80%;
    height: 350px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MyOfferVender_sec .myoffer_img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
}

.MyOfferVender_sec .myoffer_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



@media (max-width: 767px) {
    .MyOfferVender_sec .entervoucher_btn span {
        display: block;
    }

    .MyOfferVender_sec .scanvoucher_btn {
        display: block;
    }

    .MyOfferVender_sec .scanvoucher_btn span {
        display: block;
    }

    .MyOfferVender_sec {
        padding: 5px 0px;
    }

    .MyOfferVender_sec .myofferimg_wrap {
        width: 100%;
        max-width: 100%;
        height: auto;
        background-color: transparent;
        border: 1px solid #D9D9D9;
        padding: 40px;
    }

    .MyOfferVender_sec .myoffer_img {
        background-color: #D9D9D9;
        border-radius: 12px;
        overflow: hidden;
    }

}

@media (max-width: 480px) {
    .MyOfferVender_sec .heading img {
        width: 20px;
        height: 25px;
        margin-right: 10px;
    }

    .MyOfferVender_sec .heading {
        font-size: 16px;
    }

    .MyOfferVender_sec .scanvoucher_btn img {
        width: 100%;
        max-width: 20px;
        height: auto;
        margin-bottom: 0px;
    }

    .MyOfferVender_sec .entervoucher_btn img {
        width: 100%;
        max-width: 20px;
        height: auto;
        margin-bottom: 0px;
    }

    .MyOfferVender_sec .myofferimg_wrap {
        padding: 20px;
    }

}


/***VenderDetails_sec***/
.VenderDetails_sec {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 12px;
    padding: 30px 30px;
    margin-bottom: 20px;
}

.VenderDetails_sec .list {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

.VenderDetails_sec .list li {
    width: 100%;
    height: 100%;
    border-right: 2px solid #ccc;
    display: grid;
    align-items: center;
    justify-content: center;
}

.VenderDetails_sec .list li:last-child {
    border: none;
}

.VenderDetails_sec .list li p {
    font-size: 16px;
    color: #474747;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.VenderDetails_sec .list li p strong {
    margin-left: 20px;
}

@media (max-width: 767px) {
    .VenderDetails_sec {
        padding: 0px 0px;
    }

    .VenderDetails_sec .list {
        display: block;
    }

    .VenderDetails_sec .list li {
        width: 100%;
        height: auto;
        border: 2px solid #ccc;
        border-radius: 12px;
        padding: 20px 30px 10px;
        margin-bottom: 20px;
        justify-content: left;
    }

    .VenderDetails_sec .list li:last-child {
        border: 2px solid #ccc;
    }
}

@media (max-width: 480px) {
    .VenderDetails_sec .list li p {
        font-size: 12px;
    }

    .VenderDetails_sec .list li p strong {
        margin-left: 10px;
    }
}


/****OfferstatsVender_sec****/
.OfferstatsVender_sec {
    width: 100%;
    height: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
}

.OfferstatsVender_sec .backbutton_btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #4C4452;
    font-size: 24px;
    padding: 0;
    margin: 0;
    display: block;
}

.OfferstatsVender_sec .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #090808;
}

.OfferstatsVender_sec .heading img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.OfferstatsVender_sec .list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.OfferstatsVender_sec .list li {
    width: 100%;
    max-width: 30%;
    height: auto;
    gap: 1%;
}

.OfferstatsVender_sec .list .box_wrap {
    width: 100%;
    height: auto;
    background-color: #4C4452;
    border-radius: 6px;
    overflow: hidden;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
}

.OfferstatsVender_sec .list .box_wrap.active {
    background-color: #8731C9;
}

.OfferstatsVender_sec .list .box_wrap .icon {
    width: 100%;
    max-width: 40px;
    height: auto;
    margin-right: 10px;
}

.OfferstatsVender_sec .list .box_wrap .icon img {
    width: 100%;
    height: 100%;
}

.OfferstatsVender_sec .list .head {
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
}

.OfferstatsVender_sec .list .number {
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #fff;
}

.OfferstatsVender_sec .list .number i {
    margin-right: 10px;
}

@media (max-width: 1199px) {
    .OfferstatsVender_sec .list .head {
        font-size: 16px;
    }
}

@media (max-width: 1199px) {
    .OfferstatsVender_sec .list .box_wrap {
        padding: 20px 15px;
    }
}

@media (max-width: 992px) {
    .OfferstatsVender_sec .list li {
        max-width: 32%;
    }

    .OfferstatsVender_sec .list .number {
        font-size: 12px;
    }

    .OfferstatsVender_sec .list .head {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .OfferstatsVender_sec .list .box_wrap {
        width: 100%;
        height: 90px;
    }
}

@media (max-width: 768px) {
    .OfferstatsVender_sec .list {
        display: none;
    }

    .OfferstatsVender_sec.responsiveOfferstats .list {
        display: flex;
    }

    .OfferstatsVender_sec .list .box_wrap .icon {
        max-width: 30px;
        margin-right: 4px;
    }

    .OfferstatsVender_sec .list .head {
        font-size: 14px;
        margin-bottom: 6px;
    }

    .OfferstatsVender_sec .list .number {
        font-size: 10px;
    }

    .OfferstatsVender_sec .list .number i {
        margin-right: 5px;
    }

    .OfferstatsVender_sec .list .box_wrap {
        width: 100%;
        height: 70px;
        padding: 10px 10px;
    }

    .OfferstatsVender_sec .toparea {
        width: 100%;
        height: auto;
        padding-bottom: 15px;
        margin-bottom: 30px;
        border-bottom: 1px solid #b8b7b7;
    }

    /* .OfferstatsVender_sec .backbutton_btn {
        display: block;
    } */
}

@media (max-width: 576px) {
    .OfferstatsVender_sec {
        padding: 10px 10px;
        margin-bottom: 15px;
    }

}

@media (max-width: 480px) {
    .OfferstatsVender_sec .list .head {
        font-size: 10px;
        margin-bottom: 5px;
        font-weight: 600;
    }

    .OfferstatsVender_sec .list .number {
        font-size: 8px;
    }

    .OfferstatsVender_sec .list .box_wrap {
        width: 100%;
        height: 60px;
        padding: 5px 8px;
    }

    .OfferstatsVender_sec .list .box_wrap .icon {
        max-width: 25px;
        margin-right: 5px;
    }

    .OfferstatsVender_sec .heading img {
        width: 16px;
        height: 14px;
        margin-right: 8px;
    }

    .OfferstatsVender_sec .heading {
        font-size: 16px;
    }

    .OfferstatsVender_sec .backbutton_btn {
        font-size: 18px;
    }
}

/***redeemedStats_sec***/
.redeemedStats_sec {
    width: 100%;
    height: auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 20px;
}

.redeemedStats_sec .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #090808;
}

.redeemedStats_sec .heading img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.redeemedStats_sec .heading.resplink {
    display: none;
}

.redeemedStats_sec .contentwrap {
    width: 100%;
    height: auto;
    margin: 40px auto 20px;
}

.redeemedStats_chathistory {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
}


.redeemedStats_chathistory .boxtec {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #9FA3AA;
}

.redeemedStats_chathistory .boxtec:last-child {
    border: none;
}

.redeemedStats_chathistory .boxtec .left {
    width: 130px;
    height: auto;
    margin-right: 25px;
}

.redeemedStats_chathistory .boxtec:nth-child(1) .CircularProgressbar .CircularProgressbar-path {
    stroke: #DB5AEE !important;
}

.redeemedStats_chathistory .boxtec:nth-child(2) .CircularProgressbar .CircularProgressbar-path {
    stroke: #FFB536 !important;
}

.redeemedStats_chathistory .boxtec .week {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    color: #090A0A;
    padding: 0;
    margin-bottom: 5px;
}

.redeemedStats_chathistory .boxtec .totalwork {
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    color: #100F0F;
    padding: 0;
    margin-bottom: 5px;
}

.redeemedStats_chathistory .boxtec .text {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    color: #000000;
    padding: 0;
    margin-bottom: 5px;
}

.redeemedStats_chathistory .boxtec .days {
    font-size: 20px;
    text-align: left;
    font-weight: 500;
    color: #662D91;
    padding: 0;
    margin: 0;
}

.redeemedStats_chathistory .CircularProgressbar .CircularProgressbar-text {
    fill: #000000;
    font-size: 22px;
    /* dominant-baseline: middle; */
    text-anchor: middle;
    font-weight: 600;
}

@media (max-width: 768px) {
    .redeemedStats_sec .heading {
        display: none;
    }

    .redeemedStats_sec .heading.resplink {
        display: block;
    }

    /* .redeemedStats_chathistory {
        flex-wrap: wrap;
    } */
    .redeemedStats_chathistory .boxtec .left {
        width: 90px;
        height: auto;
        margin-right: 20px;
    }

    .redeemedStats_chathistory .boxtec .week {
        font-size: 12px;
        margin-bottom: 2px;
    }

    .redeemedStats_chathistory .boxtec .totalwork {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 2px;
    }

    .redeemedStats_chathistory .boxtec .days {
        font-size: 14px;
        margin-bottom: 2px;
    }

    .redeemedStats_chathistory .boxtec .text {
        font-size: 12px;
        margin-bottom: 2px;
    }
}

@media (max-width: 480px) {}


/****redeemedStats_redeemed***/
.redeemedStats_redeemed {
    width: 100%;
    height: auto;
    background-color: #ebe9e9;
    overflow: hidden;
    border-radius: 12px;
    padding: 30px 30px;
}

.redeemedStats_redeemed .head {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #090A0A;
    padding: 0;
    margin-bottom: 15px;
}

.redeemedStats_redeemed .head span {
    margin-left: 10px;
}

.redeemedStats_redeemed .list {
    width: 100%;
    height: auto;
    list-style: none;
    padding: 0;
    margin: 0;
}

.redeemedStats_redeemed .boxwrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.redeemedStats_redeemed .bar {
    width: 100%;
    max-width: 90%;
    height: auto;
    background-color: #fff;
    padding: 15px 20px 15px 15px;
    border-radius: 6px;
}

.redeemedStats_redeemed .amount {
    width: 100%;
    max-width: 10%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.redeemedStats_redeemed .name {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #000;
}

.redeemedStats_redeemed .progress {
    height: 10px;
    border-radius: 20px;
}

.redeemedStats_redeemed .progress-bar {
    height: 10px;
    border-radius: 20px;
}

.redeemedStats_redeemed .boxwrap:nth-child(1) .progress-bar {
    background-color: #54D14D;
}

.redeemedStats_redeemed .boxwrap:nth-child(2) .progress-bar {
    background-color: #EF7E5D;
}

.redeemedStats_redeemed .boxwrap:nth-child(3) .progress-bar {
    background-color: #3549FD;
}

.redeemedStats_redeemed .boxwrap:nth-child(4) .progress-bar {
    background-color: #C935FD;
}

.redeemedStats_redeemed .boxwrap:nth-child(5) .progress-bar {
    background-color: #FDD135;
}

.redeemedStats_redeemed .boxwrap:nth-child(6) .progress-bar {
    background-color: #35FDD9;
}

.redeemedStats_redeemed .boxwrap:nth-child(7) .progress-bar {
    background-color: #FD3535;
}

@media (max-width: 768px) {
    .redeemedStats_sec .contentwrap {
        display: none;
    }
}

@media (max-width: 576px) {
    .redeemedStats_sec {
        padding: 10px 10px;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {

    .redeemedStats_sec .heading {
        font-size: 16px;
    }

    .redeemedStats_sec .heading img {
        width: 16px;
        height: 14px;
        margin-right: 8px;
    }

    .redeemedStats_chathistory {
        flex-wrap: wrap;
    }

    .redeemedStats_chathistory .boxtec {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: start;
        border: 1px solid #C1A3FF;
        background-color: #D2D2D2;
        padding: 20px 30px;
        border-radius: 12px;
        margin-bottom: 30px;
    }

    .redeemedStats_chathistory .boxtec:last-child {
        border: 1px solid #C1A3FF;
    }

    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #f5f0f0;
    }

    .redeemedStats_redeemed .head {
        font-size: 16px;
    }

    .redeemedStats_redeemed {
        padding: 15px 15px;
    }

    .redeemedStats_redeemed .name {
        font-size: 14px;
    }

    .redeemedStats_redeemed .parsentage {
        font-size: 14px;
    }

    .redeemedStats_redeemed .amount {
        font-size: 16px;
    }

}



/***offerstatsinnerPage***/

.offerstatsinnerPage {
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.offerVoucherissued_Redeemed {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px 30px;
}

.offerVoucherissued_Redeemed .heading_area {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.offerVoucherissued_Redeemed .head {
    font-size: 22px;
    line-height: normal;
    font-weight: 600;
    text-align: left;
    color: #000000;
    white-space: nowrap;
    margin-right: 15px;
}

.offerVoucherissued_Redeemed .custom-select {
    width: 100%;
    max-width: 180px;
    height: auto;
    background-color: #F5F3F3;
    box-shadow: none;
    text-decoration: none;
    outline: 0;
}

.offerVoucherissued_Redeemed .eyebtn_box {
    text-decoration: none;
    outline: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offerVoucherissued_Redeemed .eyebtn_box i {
    margin-right: 7px;
}


.offerVoucherissued_Redeemed .candidate {
    width: 100%;
    height: auto;
}

.offerVoucherissued_Redeemed table tbody tr:nth-child(even) td,
.offerVoucherissued_Redeemed table tbody tr:nth-child(even) th {
    background: #BFECFF;
}

.offerVoucherissued_Redeemed table tbody tr:nth-child(odd) td,
.offerVoucherissued_Redeemed table tbody tr:nth-child(odd) th {
    background: #E1CFFF;
}

.offerVoucherissued_Redeemed .table td,
.offerVoucherissued_Redeemed .table th {
    border-top: 2px solid #ffffff;
}

table tr td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

table tr th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.offerVoucherissued_Redeemed table tbody th {
    align-content: center;
}

.offerVoucherissued_Redeemed .profile_wrap {
    display: flex;
    align-items: center;
}

.offerVoucherissued_Redeemed .profile_pic {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.offerVoucherissued_Redeemed .profile_pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.offerVoucherissued_Redeemed .profile_details .name {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 3px;
    padding: 0;
}

.offerVoucherissued_Redeemed .profile_details .post {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
}

.offerVoucherissued_Redeemed .address_wrap .cuntry {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 3px;
    padding: 0;
}

.offerVoucherissued_Redeemed .address_wrap .address {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
}

.offerVoucherissued_Redeemed .date_wrap .datetext {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 3px;
    padding: 0;
}

.offerVoucherissued_Redeemed .date_wrap .date {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
}

.offerVoucherissued_Redeemed .ViewProfile_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.offerVoucherissued_Redeemed .ViewProfile_wrap .Viewtext {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    margin-bottom: 3px;
    padding: 0;
}

.offerVoucherissued_Redeemed .ViewProfile_wrap .Profile {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    margin: 0;
    padding: 0;
}

.offerVoucherissued_Redeemed .ViewProfile_wrap .morebtn {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #555555;
    outline: 0;
    text-decoration: none;
    box-shadow: none;
    background-color: transparent;
    border-radius: 50%;
    overflow: hidden;
    font-size: 12px;
    color: #555555;
    cursor: pointer;
}

@media (max-width: 576px) {
    .offerVoucherissued_Redeemed {
        padding: 10px 10px;
    }

    .offerVoucherissued_Redeemed table tbody tr th {
        font-size: 14px;
    }

}

@media (max-width: 480px) {
    .offerVoucherissued_Redeemed .head {
        font-size: 14px;
        margin-right: 10px;
    }

    .offerVoucherissued_Redeemed .custom-select {
        width: 100%;
        max-width: 120px;
    }

    .offerVoucherissued_Redeemed .profile_pic {
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .offerVoucherissued_Redeemed .profile_details .name {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 3px;
        white-space: nowrap;
    }

    .offerVoucherissued_Redeemed .profile_details .post {
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
    }

    .offerVoucherissued_Redeemed .address_wrap .cuntry {
        font-size: 12px;
        font-weight: 700;
    }

    .offerVoucherissued_Redeemed .address_wrap .address {
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
    }

    .offerVoucherissued_Redeemed .date_wrap .datetext {
        font-size: 12px;
        font-weight: 700;
    }

    .offerVoucherissued_Redeemed .date_wrap .date {
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
    }

    .offerVoucherissued_Redeemed .ViewProfile_wrap .Viewtext {
        font-size: 12px;
        font-weight: 700;
    }

    .offerVoucherissued_Redeemed .ViewProfile_wrap .Profile {
        font-size: 9px;
        font-weight: 500;
        white-space: nowrap;
    }

    .offerVoucherissued_Redeemed .ViewProfile_wrap .morebtn {
        width: 15px;
        height: 15px;
        font-size: 10px;
    }
}

/***redeemedstatsinner_page***/
.redeemedstatsinner_page {
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.redeemedstatsinner_page .toparea {
    width: 100%;
    height: auto;
    padding-bottom: 15px;
    border-bottom: 1px solid #acacac;
    margin-bottom: 30px;
}

.redeemedstatsinner_page .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #090808;
}

.redeemedstatsinner_page .heading img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.redeemedstatsinner_page .backbutton_btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #4C4452;
    font-size: 24px;
    padding: 0;
    margin: 0;
    display: none;
}


@media (max-width: 768px) {
    .redeemedstatsinner_page .backbutton_btn {
        display: block;
    }
}

@media (max-width: 480px) {
    .redeemedstatsinner_page .heading {
        font-size: 16px;
    }

    .redeemedstatsinner_page .heading img {
        width: 16px;
        height: 14px;
        margin-right: 8px;
    }

    .redeemedstatsinner_page .backbutton_btn {
        font-size: 18px;
    }
}

/***uservoucher_code****/

.uservoucher_code {
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.uservoucher_code .toparea {
    width: 100%;
    height: auto;
    padding-bottom: 15px;
    border-bottom: 1px solid #acacac;
    margin-bottom: 30px;
}

.uservoucher_code .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #090808;
}

.uservoucher_code .heading img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.uservoucher_code .backbutton_btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #4C4452;
    font-size: 24px;
    padding: 0;
    margin: 0;
}

.uservoucher_code .sub_head {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #090808;
    margin-bottom: 10px;
}

.uservoucher_code .form-group input {
    width: 100%;
    height: 45px;
    padding: 5px 15px;
    border: 1px solid #c2c2c2;
    outline: 0;
    box-shadow: none;
    border-radius: 8px;
    background-color: transparent;
    font-weight: 600;
}

.uservoucher_code .form-group input:focus {
    box-shadow: none;
    outline: 0;
}

.uservoucher_code .Enter_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: start;
}

.uservoucher_code .Enter_btn .btn {
    width: 100%;
    max-width: 150px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    border: none;
    background-color: #CCCCCC;
    color: #100F0F;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding: 5px 15px;
    border-radius: 12px;
}

@media (max-width: 576px) {
    .uservoucher_code .sub_head {
        text-align: center;
    }

    .uservoucher_code .form-group input {
        text-align: center;
    }

    .uservoucher_code .Enter_btn {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .uservoucher_code .sub_head {
        font-size: 18px;
    }

    .uservoucher_code .backbutton_btn {
        font-size: 18px;
    }

    .uservoucher_code .heading {
        font-size: 18px;
        font-weight: 700;
    }

    .uservoucher_code .heading img {
        width: 20px;
        height: 20px;
        margin-right: 15px;
    }
}