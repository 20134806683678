/****letsgetinTouch_sec*****/

.letsgetinTouch_sec {
    width: 100%;
    height: auto;
}

.letsgetinTouch_sec .cust_container {
    background-color: #fff;
    padding: 60px 30px;
}

.letsgetinTouch_sec .heading {
    font-size: 48px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #000000;
    padding: 0;
    margin-bottom: 20px;
}

.letsgetinTouch_sec .heading span {
    color: #656ED3;
}

.letsgetinTouch_sec .text {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    color: #1A1A1A;
    margin: 0;
    max-width: 340px;
}

.letsgetinTouch_sec .colortext {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    color: #656ED3;
    margin-top: 20px;
}

.letsgetinTouch_sec .subheading {
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    line-height: normal;
    color: #4F59C0;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 15px;
}

.letsgetinTouch_sec ul {
    width: 100%;
    height: auto;
    list-style: none;
    padding: 0;
    margin: 0;
}

.letsgetinTouch_sec ul li {
    padding: 5px 0;
    display: flex;
}

.letsgetinTouch_sec ul li i {
    color: #4F59C0;
    margin-right: 10px;
}

.letsgetinTouch_form {
    width: 100%;
    height: auto;
}

.letsgetinTouch_form .form-group {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.letsgetinTouch_form .form-group label {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.letsgetinTouch_form .form-group input {
    font-size: 16px;
    color: #000000;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #656ED3;
    padding: 2px 15px;
}

.letsgetinTouch_form .form-group input::placeholder {
    color: #7a7a7a;
}

.letsgetinTouch_form .form-group input:focus {
    box-shadow: none;
    outline: 0;
}

.letsgetinTouch_form .form-group textarea {
    font-size: 16px;
    color: #000000;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #656ED3;
    padding: 2px 15px;

}

.letsgetinTouch_form .form-group textarea::placeholder {
    color: #7a7a7a;
}

.letsgetinTouch_form .form-group textarea:focus {
    box-shadow: none;
    outline: 0;
}

.letsgetinTouch_form .Submit_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: end;
}

.letsgetinTouch_form .Submit_btn .btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    width: 100%;
    max-width: 150px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #4F59C0;
    border: none;
    border-radius: 12px;
}

/****aboutneoosearch_sec***/
.aboutneoosearch_sec {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 20px 0;
}

.aboutneoosearch_sec .toparea {
    width: 100%;
    height: auto;
    padding: 30px 50px;
    border-bottom: 1px solid #ccc;
}

.aboutneoosearch_sec .headline {
    font-size: 24px;
    line-height: normal;
    text-align: left;
    font-weight: 600;
    color: #000000;
    padding: 0;
    margin-bottom: 10px;
}

.aboutneoosearch_sec .textarea {
    width: 100%;
    height: auto;
    padding: 20px 50px;
}

.aboutneoosearch_sec .text {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    color: #535353;
    padding: 0;
    margin-bottom: 20px;
}

.aboutneoosearch_sec .list {
    list-style: decimal;
    padding: 0;
    margin: 0;
    padding-left: 25px;
}

.aboutneoosearch_sec .list li {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    color: #535353;
    padding: 0;
    margin-bottom: 10px;
}


@media (max-width: 768px) {
    .aboutneoosearch_sec .toparea {
        padding: 15px 20px;
    }

    .aboutneoosearch_sec .headline {
        font-size: 20px;
    }

    .aboutneoosearch_sec .textarea {
        padding: 15px 20px;
    }

    .aboutneoosearch_sec .text {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .aboutneoosearch_sec .list {
        padding-left: 20px;
    }

    .aboutneoosearch_sec .list li {
        font-size: 14px;
        margin-bottom: 6px;
    }
}

@media (max-width: 480px) {
    .aboutneoosearch_sec .toparea {
        padding: 10px 15px;
    }

    .aboutneoosearch_sec .headline {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .aboutneoosearch_sec .textarea {
        padding: 10px 15px;
    }

    .aboutneoosearch_sec .text {
        font-size: 12px;
    }

    .aboutneoosearch_sec .list li {
        font-size: 12px;
    }
}


/*****ProductsAndServices_sec******/
.ProductsAndServices_sec {
    width: 100%;
    height: auto;
}

.ProductsAndServices_sec .mainwrap {
    width: 100%;
    height: auto;
    padding: 40px 100px;
    background-color: #fff;
}

.ProductsAndServices_sec .pageheading {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
}

.ProductsAndServices_sec .vdo_main {
    width: 100%;
    height: 350px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}

.ProductsAndServices_sec .vdo_main video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProductsAndServices_sec .vdo_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: none;
}

.ProductsAndServices_sec .vdo_btn .btn_play {
    width: 70px;
    height: 45px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #3f3e3e;
    text-decoration: none;
    box-shadow: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProductsAndServices_sec .vdo_btn .btn_play i {
    font-size: 24px;
}

.ProductsAndServices_sec .vdo_main:hover .vdo_btn {
    display: inline-block;
}

.ProductsAndServices_sec .textpara {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    color: #272626;
    padding: 0;
    margin: 30px 0;
}

.ProductsAndServices_sec .pagesub_heading {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    color: #000000;
    padding-top: 30px;
    margin-bottom: 30px;
}

.Keyproducts_card {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.Keyproducts_card .imagewrap {
    width: 100%;
    height: 165px;
     overflow: hidden;
     border-radius: 6px;
     margin-bottom: 5px;
}

.Keyproducts_card .imagewrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Keyproducts_card .image_name {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    color: #000000;
}

.Keyproducts_card .Keyproducts_list {
    width: 100%;
    height: auto;
    padding: 0;
    margin-left: 20px;
    margin-bottom: 15px;
}

.Keyproducts_card .Keyproducts_list li {
    font-size: 14px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #1A1A1A;
    padding: 0;
    margin-bottom: 5px;
}

.Companyprofile_link {
    font-size: 12px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #8006FA;
}



@media (max-width: 768px) {
    .ProductsAndServices_sec .pageheading {
        font-size: 26px;
    }

    .ProductsAndServices_sec .mainwrap {
        padding: 30px 30px;
    }

    .ProductsAndServices_sec .vdo_main {
        width: 100%;
        height: 250px;
    }

    .ProductsAndServices_sec .vdo_btn .btn_play {
        width: 50px;
        height: 35px;
    }

    .ProductsAndServices_sec .vdo_btn .btn_play i {
        font-size: 20px;
    }

    .ProductsAndServices_sec .textpara {
        font-size: 16px;
        margin: 20px 0;
    }
    .ProductsAndServices_sec .pagesub_heading {
        font-size: 20px;
        padding-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .ProductsAndServices_sec .pageheading {
        font-size: 22px;
    }

    .ProductsAndServices_sec .mainwrap {
        padding: 30px 20px;
    }

    .ProductsAndServices_sec .vdo_main {
        width: 100%;
        height: 200px;
    }

    .ProductsAndServices_sec .vdo_btn .btn_play {
        width: 40px;
        height: 30px;
    }

    .ProductsAndServices_sec .vdo_btn .btn_play i {
        font-size: 18px;
    }

    .ProductsAndServices_sec .textpara {
        font-size: 14px;
        margin: 15px 0;
    }
    .ProductsAndServices_sec .pagesub_heading {
        font-size: 18px;
        padding-top: 15px;
        margin-bottom: 15px;
    }

    .Keyproducts_card .leftside {
       width: 100%;
       height: auto;
       margin-bottom: 15px;
    }

    .Keyproducts_card .imagewrap {
        width: 100%;
        height: auto;
        margin-bottom: 2px;
    }
    .Keyproducts_card .Keyproducts_list {
        margin-left: 20px;
        margin-bottom: 0px;
    }
    .Keyproducts_card .Keyproducts_list li {
        font-size: 12px;
        margin-bottom: 4px;
    }
    .Keyproducts_card .image_name {
        font-size: 10px;
    }
    .Companyprofile_link {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .ProductsAndServices_sec .pageheading {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .ProductsAndServices_sec .mainwrap {
        padding: 30px 15px;
    }

    .ProductsAndServices_sec .vdo_btn .btn_play {
        width: 35px;
        height: 25px;
    }

    .ProductsAndServices_sec .vdo_btn .btn_play i {
        font-size: 14px;
    }
    .Companyprofile_link {
        font-size: 10px;
    }
}


