.logedsearchmain {
    width: 100%;
    height: 100%;
    padding: 20px 0px;
}

.midownseacrh {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    max-height: max-content;
}

.midownseacrhlogodiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.neoofrontlogoimage {
    width: 100%;
    max-width: 250px;
    min-width: 250px;
    height: 53px;
}

.neoofrontlogoimage img {
    width: 100%;
    height: 100%;
}

.logedserchfrminpt {
    width: 100%;
    max-width: 100%;
    padding-top: 30px;
    position: relative;
}
.searchiconimg{
    position: absolute;
    top: 41px;
    right: 27px;
    width: 100%;
    max-width: 20px;
    min-width: 20px;
    height: 20px;

}
.searchiconimg img{
    width: 100%;
    height: 100%;
}

.logedserchfrminptinpt {
    width: 100%;
    padding: 13px 40px;
    border-radius: 30px;
    box-shadow: 0px 2px 2px 0px #0000001A;
    border: 0.5px solid #00000050;
    font-size: 15px;
    color: #000;
    position: relative;
}

.logedserchfrminpt ::placeholder {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #646262;

}
.logedserchfrminptinpt:focus{
    outline: 0;
    border: 0.5px solid #00000050;
}
.midownseacrhmaindiv{
    width: 100%;
    max-width: 50%;
    min-width: 50%;
}