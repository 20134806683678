.mbrtusrprflflx {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2%;
}

.imgbrdrmbrt {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    border: 2px solid #67308F;
    overflow: hidden;
}

.imgbrdrmbrt img {
    width: 100%;
    height: 100%;
}

.jhjmbmrtdiv {
    width: 70%;
}

.jhjtcfggytxt {
    color: #3F3C3C;
    font-Size: 18px;
    font-family: 'Inter', sans-serif;
    font-Weight: 600;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 2px solid #6E6969;
}

.ajmtxt {
    font-size: 15px;
}

.mghtxtpp {
    color: #3F3C3C;
    font-Size: 14px;
    font-family: 'Inter', sans-serif;
    font-Weight: '400';
    line-Height: 22px;
}

.jhjmbmrtdiv .companyname {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #1D4AEB;
    padding: 0;
    margin-bottom: 5px;
}

.jhjmbmrtdiv .digitalmarketing {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #662D91;
    padding: 0;
    margin-bottom: 5px;
}

.jhjmbmrtdiv .tel_no {
    font-size: 12px;
    line-height: normal;
    text-align: left;
    font-weight: 600;
    font-size: #655C5C;
    padding-bottom: 10px;
}

.jhjmbmrtdiv .review_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.jhjmbmrtdiv .review_wrap .like {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #000;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    margin-bottom: 5px;
}

.jhjmbmrtdiv .review_wrap .follower {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #000;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    margin-bottom: 5px;
}

.jhjmbmrtdiv .review_wrap .star {
    display: flex;
    align-items: center;
}

.jhjmbmrtdiv .review_wrap .star i {
    font-size: 12px;
    color: #989898;
    margin-right: 5px;
}

.jhjmbmrtdiv .review_wrap .star i.active {
    color: #FBB03B;
}

.cntntgdtls {
    border: 2px solid #DBDEFE;
    margin: 15px 0px;
    border-radius: 10px;
    padding: 10px 15px;
}

.egltxtdiv {
    color: #585454;
    font-Size: 26px;
    font-family: 'Inter', sans-serif;
    font-Weight: 500;
    line-Height: 22px;
    padding-bottom: 12px;
}

.mdlfrntr {
    color: #585454;
    font-Size: 16px;
    font-family: 'Inter', sans-serif;
    font-Weight: 400;
    line-Height: 22px;
}

.drctryDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drctryDivLeft,
.drctryDivRght {
    display: flex;
    gap: 20px;
}

.drctryDivFig {
    width: 25px;
    height: 25px;
    margin: 0;
}

.drctryDivFig img {
    width: 100%;
    height: 100%;
}

.drctrCntctIcon {
    width: 100%;
    max-width: 38px;
    min-width: 38px;
    height: 38px;
    margin: 0;
}

.drctrCntctIcon img {
    width: 100%;
    height: 100%;
}

.responsiveshow {
    display: none;
}

.drctryDivLeft p {
    margin-left: 10px;
    color: #000;
    font-size: 20px;
}

@media(max-width:1399px) {
    .egltxtdiv {
        font-size: 20px;
    }

    .cntncttxt {
        font-size: 22px;
    }
}

@media(max-width:767px) {
    .mbrtusrprflflx {
        width: 100%;
        flex-wrap: wrap;
    }

    .responsiveshow {
        display: block;
    }
}

@media(max-width:575px) {
    .drctryDivFig {
        width: 25px;
        height: 25px;
    }

    .drctryDivLeft p {
        font-size: 17px;
    }

    .jhjmbmrtdiv .d-flex {
        flex-wrap: wrap;
    }

    .jhjtcfggytxt {
        font-size: 16px;
        display: block;
        border: none;
        width: 100%;
    }

    .mghtxtpp {
        font-size: 13px;
    }

    .cntncttxt {
        font-size: 17px;
    }

    .egltxtdiv {
        font-size: 17px;
    }

    .mdlfrntr {
        font-size: 14px;
    }

    .ajmtxt {
        font-size: 13px;
    }

    .imgbrdrmbrt {
        width: 70px;
        height: 70px;
        margin-bottom: 15px;
    }
}