
.PeopleAccelerator_Program {
    width: 100%;
    height: auto;
}

.PeopleAcceleratorBanner {
    width: 100%;
    height: 480px;
    padding: 40px 0;
    background-image: linear-gradient(to right, #41D7F8 , #1E93FF);
}


.PeopleAcceleratorProgram_sec {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

.PeopleAcceleratorProgram_sec .heading {
   font-size: 32px;
   line-height: normal;
   text-align: left;
   font-weight: 700;
   margin-bottom: 25px;
   color: #000;
   padding: 0;
}

.PeopleAcceleratorProgram_sec .text {
   font-size: 16px;
   line-height: normal;
   font-weight: 400;
   color: #000;
   padding: 0;
   margin-bottom: 20px;
}




@media (max-width: 576px) {
    .PeopleAcceleratorBanner {
        height: 300px;
    }
    .PeopleAcceleratorProgram_sec {
        padding: 30px 0;
    }
    .PeopleAcceleratorProgram_sec .heading {
        font-size: 24px;
     }
}


@media (max-width: 480px) {
    .PeopleAcceleratorBanner {
        height: 230px;
    }
    .PeopleAcceleratorProgram_sec .heading {
        font-size: 20px;
        margin-bottom: 15px;
     }
     .PeopleAcceleratorProgram_sec .text {
        font-size: 14px;
        margin-bottom: 15px;
        text-align: justify;
     }
}