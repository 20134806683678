.reward_wrapflex {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.rewards_sec {
    width: 100%;
    height: auto;
    padding: 20px 15px 15px;
}

.rewards_sec .bg_color {
    background-color: #fff;
    padding: 15px 15px 30px;
    border-radius: 6px;
    margin-bottom: 15px;
}

.neooPoint_wrap {
    width: 100%;
    /* max-width: 420px; */
    max-width: 530px;
    height: auto;
    margin: 0 auto 20px;
    background-color: #EDEEFF;
    padding: 10px 15px;
    border-radius: 4px;
}

.neooPoint_wrap h4 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #000;
    padding: 0;
    margin-bottom: 5px;
}

.neooPoint_wrap h6 {
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    color: #000;
    padding: 0;
    margin-bottom: 0;
}

.neooPoint_wrap .img_wrap {
    width: 100%;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: none;
}

.neooPoint_wrap .img_wrap img {
    width: 100%;
    height: 100%;
}

.neooPoint_wrap .point_box {
    width: auto;
    height: auto;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: #c7a0e2;
    margin-left: 10px;
}

.neooPoint_wrap .point_box p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    white-space: nowrap;
    text-align: left;
}

.neooPoint_wrap .point_box a {
    border: none;
    background-color: transparent;
    outline: 0;
    box-shadow: none;
    text-decoration: none;
    color: #262020;
}

.neooPoint_wrap .point_box a:hover {
    box-shadow: none;
    text-decoration: none;
    color: #262020;
}

.rewards_card {
    width: 100%;
    height: auto;
    max-width: 420px;
    padding: 20px 20px 30px;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 auto;
}

.rewards_card.silver {
    background-color: rgb(171, 171, 171);
}

.rewards_card.Gold {
    background-color: rgb(195, 146, 46);
}

.rewards_card.Plat {
    background-color: rgb(27, 72, 188);
}

.rewards_card .logoarea {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.rewards_card .logoarea img {
    width: 100px;
    height: auto;
}

.rewards_card .logoarea span {
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
}

.rewards_card.silver .logoarea span {
    color: #67308F;
}

.rewards_card.Gold .logoarea span {
    color: #fff;
}

.rewards_card.Plat .logoarea span {
    color: #fff;
}

.rewards_card .profile {
    margin-right: 15px;
}

.rewards_card .profile .pic {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 10px;
}

.rewards_card .profile .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rewards_card .profile h4 {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    padding: 0;
    margin-bottom: 5px;
}

.rewards_card.silver .profile h4 {
    color: #000;
}

.rewards_card.Gold .profile h4 {
    color: #fff;
}

.rewards_card.Plat .profile h4 {
    color: #fff;
}

.rewards_card .profile p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.rewards_card.silver .profile p {
    color: #706e6e;
}

.rewards_card.Gold .profile p {
    color: #d8d6d6;
}

.rewards_card.Plat .profile p {
    color: #d8d6d6;
}

.rewards_card .Qr_code {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.rewards_card .Qr_code img {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .rewards_card {
        margin: 0 auto 0px;
    }
}

@media (max-width: 480px) {
    .rewards_card .logoarea {
        margin-bottom: 10px;
    }

    .rewards_card {
        padding: 10px 10px 10px;
    }

    .rewards_card .Qr_code {
        width: 70px;
        height: auto;
    }
}

/**moreReward_slider**/

.moreReward_slider {
    width: 100%;
    position: relative;
}

.moreReward_slider .slick-arrow.slick-next::before {
    display: none;
}

.moreReward_slider .slick-arrow.slick-prev::before {
    display: none;
}

.moreReward_slider .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.moreReward_slider .slick-dots.slick-thumb li {
    width: 10px;
    height: 6px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: #b6b3b3;
}

.moreReward_slider .slick-dots.slick-thumb li.slick-active {
    background-color: #662D91;
    width: 24px;
}

.moreReward_card {
    width: 100%;
    height: 230px;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
}

.moreReward_card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.moreReward_card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.moreReward_card .head {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
}

.moreReward_card .text {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding: 0;
    margin-bottom: 15px;
}

.viewmore_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: end;
}

.viewmore_btn .btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    background-color: transparent;
    border: 0;
    width: 100%;
    max-width: 150px;
    height: auto;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewmore_btn .btn i {
    font-size: 16px;
}

.viewmore_btn .btn span {
    margin-left: 10px;
    border: 1px solid #000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .moreReward_card .head {
        font-size: 22px;
    }
}

@media (max-width: 767px) {
    .moreReward_card .head {
        font-size: 36px;
    }
}

@media (max-width: 576px) {
    .moreReward_card .head {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .moreReward_card .head {
        font-size: 16px;
    }

    .moreReward_card {
        height: 160px;
    }

    .moreReward_card .text {
        margin-bottom: 5px;
    }

    .viewmore_btn .btn {
        max-width: 120px;
        font-size: 12px;
        padding: 0;
    }

    .viewmore_btn .btn span {
        width: 16px;
        height: 16px;
    }

    .viewmore_btn .btn span i {
        font-size: 10px;
    }
}

@media (max-width: 420px) {
    .moreReward_card .head {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 6px;
    }

    .moreReward_card .text {
        font-size: 10px;
    }

    .moreReward_card {
        height: 145px;
    }
}

/*****Offersbox_sec****/

.Offersbox_sec {
    width: 100%;
    height: auto;
    padding: 15px 15px;
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 6px;
}

.offers_card {
    width: 100%;
    height: auto;
    padding: 20px 15px;
    background: linear-gradient(90deg, rgba(152, 89, 254, 1) 0%, rgba(108, 25, 244, 1) 100%);
    border-radius: 12px;
}

.offers_card .text {
    font-size: 22px;
    line-height: normal;
    font-weight: 600;
    text-align: left;
    color: #fff;
}

.offers_card .icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offers_card .icon img {
    width: 100%;
    height: auto;
}

@media (max-width: 991px) {
    .offers_card .text {
        font-size: 20px;
        margin-right: 10px;
    }

    .offers_card .icon {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 768px) {
    .offers_card .text {
        width: 50%;
        font-size: 14px;
    }

    .offers_card .icon {
        width: 25%;
        height: auto;
    }
}

@media (max-width: 420px) {
    .offers_card {
        height: 60px;
    }
}

/***redeemyourneoo_sec***/
.redeemyourneoo_sec {
    width: 100%;
    height: auto;
    padding: 0px 0px;
}

.redeemyourneoo_card {
    width: 100%;
    height: auto;
    background-color: #662D91;
    border-radius: 15px;
    overflow: hidden;
}

.redeemyourneoo_card .m-auto {
    padding: 30px 30px;
}

.redeemyourneoo_card .text {
    font-size: 32px;
    line-height: normal;
    text-align: left;
    color: #fff;
}

.redeemyourneoo_card .icon {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-left: auto;
}

.redeemyourneoo_card .icon img {
    width: 100%;
    height: 100%;
}

@media (max-width: 991px) {
    .redeemyourneoo_card .text {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .redeemyourneoo_card .text {
        font-size: 24px;
    }

    .redeemyourneoo_card .m-auto {
        padding: 30px 40px;
    }

    .redeemyourneoo_card .icon {
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .redeemyourneoo_card .icon {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .redeemyourneoo_card .text {
        font-size: 18px;
    }

    .redeemyourneoo_card .m-auto {
        padding: 15px 15px;
    }
}

@media (max-width: 420px) {
    .redeemyourneoo_card .text {
        font-size: 16px;
    }
}

/*****rewardlist_sec***/

.rewardlist_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.rewardlist_sec ul {
    width: 100%;
    max-width: 70%;
    height: auto;
    list-style: none;
    margin: 0 auto;
}

.rewardlist_sec ul li {
    border-bottom: 1px solid #B5B5B5;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    justify-content: space-between;
}

.rewardlist_sec ul li span {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.rewardlist_sec ul li span img {
    width: 100%;
}

.rewardlist_sec ul li p {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #000;
}

.rewardlist_sec ul li a {
    font-size: 16px;
    color: #5e5d5d;
    width: 100%;
    max-width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #5e5d5d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

@media (max-width: 991px) {
    .rewardlist_sec ul {
        width: 100%;
        max-width: 80%;
    }
}

@media (max-width: 768px) {
    .rewardlist_sec ul {
        width: 100%;
        max-width: 100%;
    }

    .rewardlist_sec ul li p {
        font-size: 14px;
    }

}

@media (max-width: 576px) {
    .rewardlist_sec ul li a {
        font-size: 10px;
        width: 100%;
        max-width: 18px;
        min-width: 18px;
        height: 18px;
    }
}


/***rewardpointssummary_sec***/

.rewardpointssummary_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.rewardpointssummary_sec .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.rewardpointssummary_sec .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.rewardpointssummary_sec .heading {
    font-size: 40px;
    line-height: normal;
    font-weight: 600;
    color: #302e2e;
    padding: 0;
    margin: 0;
}

.rewardpointssummary_sec .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.rewardpointssummary_sec .icon img {
    width: 100%;
    height: 100%;
}


.rewardpoint_table {
    width: 100%;
    height: auto;
    padding: 10px 20px;
    background-color: #EADDFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
}

.rewardpoint_table ul {
    text-decoration: none;
    list-style: none;
    margin: 0;
}

.rewardpoint_table ul li {
    width: 100%;
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid #b8b6b6;
}

.rewardpoint_table ul li:last-child {
    border-bottom: none;
}

.rewardpoint_table .point {
    font-size: 18px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #646060;
    padding: 0;
    margin: 0 0 2px 0;
}

.rewardpoint_table .text {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    color: #646060;
}

.pointbreak_up {
    width: 100%;
    height: auto;
}

.pointbreak_up .head {
    font-size: 22px;
    line-height: normal;
    text-align: left;
    font-weight: 600;
    color: #000;
    padding: 0;
    margin-bottom: 10px;
}

.pointbreak_up .accordion {
    border: none !important;
}

.pointbreak_up .accordion__button {
    position: relative;
    background-color: transparent !important;
    color: #383737 !important;
    font-weight: 600;
}

.pointbreak_up .accordion__button:before {
    position: absolute;
    top: 50%;
    right: 23px;
    color: #646060;
    transform: rotate(45deg);
    transition: 0.6s;
}

.pointbreak_up .accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(-135deg);
}

.pointbreak_up .accordion__button .icomimg1 {
    font-size: 20px;
    color: #646060;
    margin-right: 18px;
}

.pointbreak_up .accordion__button p {
    color: #4D4949 !important;
    font-weight: 600;
}

.pointbreak_up .accordion__button span {
    font-size: 14px;
    color: #6B6B6B;
}

.pointbreak_up .accordion__panel p {
    font-size: 16px;
    color: #7e7a7a;
}

.pointbreak_up .accordion__panel ul {
    list-style: decimal;
    margin-top: 20px;
    padding-left: 25px;
}

.pointbreak_up .accordion__panel ul li {
    font-size: 14px;
    color: #7e7a7a;
    margin-bottom: 8px;
}


@media (max-width: 480px) {
    .rewardpointssummary_sec .heading {
        font-size: 16px;
        font-weight: 700;
    }

    .rewardpointssummary_sec .icon {
        max-width: 30px;
        min-width: 30px;
    }

    .rewardpointssummary_sec .backbtn {
        font-size: 20px;
    }
}

/****OffersCategories_page****/

.OffersCategories_page {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.OffersCategories_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.OffersCategories_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.OffersCategories_page .heading {
    font-size: 40px;
    line-height: normal;
    font-weight: 600;
    color: #302e2e;
    padding: 0;
    margin: 0;
}

.OffersCategories_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.OffersCategories_page .icon img {
    width: 100%;
    height: 100%;
}

.OffersCategories_page .categor_card {
    width: 100%;
    height: 185px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.OffersCategories_page .categor_card::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000045;
    z-index: 1;
}

.OffersCategories_page .categor_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.OffersCategories_page .overlay_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    text-align: center;
    padding: 0;
    margin: 0;
}

@media (max-width: 480px) {
    .OffersCategories_page .heading {
        font-size: 16px;
        font-weight: 700;
    }

    .OffersCategories_page .icon {
        max-width: 30px;
        min-width: 30px;
    }

    .OffersCategories_page .backbtn {
        font-size: 20px;
    }
}

/***OffersCategDetails_page***/
.OffersCategDetails_page {
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.OffersCategDetails_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.OffersCategDetails_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.OffersCategDetails_page .heading {
    font-size: 40px;
    line-height: normal;
    font-weight: 600;
    color: #302e2e;
    padding: 0;
    margin: 0;
}

.OffersCategDetails_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.OffersCategDetails_page .icon img {
    width: 100%;
    height: 100%;
}



.OffersCategDetails_page .card_wrap {
    width: 100%;
    height: auto;
    border: 1px solid #DEDEDE;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.OffersCategDetails_page .card_wrap .img_wrap {
    width: 100%;
    max-width: 600px;
    height: auto;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 20px;
}

.OffersCategDetails_page .card_wrap .img_wrap img {
    width: 100%;
    height: 100%;
}

.OffersCategDetails_page .description p {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    color: #282626;
    padding: 0;
    margin-bottom: 30px;
}

.OffersCategDetails_page .count_area {
    width: 100%;
    height: auto;
}

.OffersCategDetails_page .textbox {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    color: #282626;
    font-weight: 500;
    padding: 0;
    /* margin-bottom: 10px; */
}

.OffersCategDetails_page .textbox span {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-right: 10px;
}

.Offerdetails_accordion .textbox span {
    color: #F20000;
}

.Offerdetails_accordion .name {
    font-size: 16px;
    font-weight: 700;
    color: #5D6067;
    margin-right: 10px;
}

.Offerdetails_accordion .accordion {
    border: none;
}

.Offerdetails_accordion .accordion__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background-color: transparent;
    border: none;
    padding: 8px 50px 8px 0;
    color: #000000;
    font-weight: 500;
}

.Offerdetails_accordion .nonAccordion__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background-color: transparent;
    border: none;
    padding: 8px 50px 8px 0;
    color: #000000;
    font-weight: 500;
}

.Offerdetails_accordion .accordion__button:hover {
    background-color: transparent;
}

.Offerdetails_accordion .accordion__button::before {
    position: absolute;
    top: 40%;
    right: 10px;
    z-index: 1;
    transform: rotate(45deg);
}

.Offerdetails_accordion .accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(-135deg);
}

.inner_accordion .accordion__button {
    border-top: 1px solid #ccc;
}

/* .inner_accordion .accordion__button:last-child {
    border-bottom: 1px solid #ccc;
} */

.Offerdetails_accordion .accordion__panel {
    padding: 5px 0;
}

.Offerdetails_accordion .accordion__panel p {
    font-size: 15px;
    line-height: normal;
    text-align: left;
    color: #262020;
    margin-bottom: 15px;
}

.Offerdetails_accordion .bottom_part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.Offerdetails_accordion .ViewProfile_btn {
    border: none;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    font-size: 18px;
    line-height: normal;
    font-weight: 700;
    color: #0042EA;
}

.Offerdetails_accordion .RedeemNow_btn {
    padding: 8px 20px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    background-color: #662D91;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-right: 15px;
}
.Offerdetails_accordion .Redeemed_btn {
    padding: 8px 20px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    /* background-color: #50C878	; */
    color: #fff;
    border: none;
    border-radius: 6px;
    margin-right: 15px;
}

.Offerdetails_accordion .thikbtn {
    font-size: 40px;
    color: #43A047;
}


@media (max-width: 480px) {
    .OffersCategDetails_page .heading {
        font-size: 16px;
        font-weight: 700;
    }

    .OffersCategDetails_page .icon {
        max-width: 30px;
        min-width: 30px;
    }

    .OffersCategDetails_page .backbtn {
        font-size: 20px;
    }

    .Offerdetails_accordion .ViewProfile_btn {
        font-size: 14px;
    }

    .Offerdetails_accordion .RedeemNow_btn {
        font-size: 12px;
        margin-right: 10px;
    }

    .Offerdetails_accordion .thikbtn {
        font-size: 28px;
    }
}


/******topredeemedOffers_sec*******/

.topredeemedOffers_sec {
    width: 100%;
    height: auto;
    padding: 15px 20px 15px;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 6px;
}

.topredeemedOffers_sec .heading {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #000;
    padding: 0;
    margin-bottom: 15px;
}

.topredeemedOffers_sec ul {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    list-style: none;
}

.topredeemedOffers_sec ul li {
    width: 100%;
    height: auto;
    padding: 15px 0;
    border-bottom: 1px solid #747373;
}

.topredeemedOffers_sec ul li:last-child {
    border-bottom: none;
}

.topredeemedOffers_sec .img_box {
    width: 100%;
    max-width: 150px;
    /* height: 100%; */
    height: 100px;
    /* padding: 10px 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 12px 12px 12px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 0px #ccc;
}

.topredeemedOffers_sec .img_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.topredeemedOffers_sec .content {
    width: 100%;
    height: auto;
}

.topredeemedOffers_sec .name {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    color: #131212;
    font-weight: 600;
    padding: 0;
    margin-bottom: 1px;
}

.topredeemedOffers_sec .status {
    font-size: 14px;
    line-height: normal;
    text-align: left;
    color: #282626;
    font-weight: 400;
    padding: 0;
    margin-bottom: 1px;
}

.topredeemedOffers_sec .flex_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.topredeemedOffers_sec .text {
    font-size: 11px;
    line-height: normal;
    text-align: left;
    color: #282626;
    font-weight: 400;
    padding: 0;
    margin-bottom: 10px;
}

.topredeemedOffers_sec .point {
    font-size: 10px;
    line-height: normal;
    text-align: center;
    color: #282626;
    font-weight: 500;
    padding: 0;
    margin-bottom: 5px;
}

.topredeemedOffers_sec .point span {
    display: block;
    color: #F20000;
    font-weight: 600;
}

.Offerdetails_btn {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    color: #0042EA;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    border: none;
}

.Offerdetails_btn i {
    font-size: 14px;
    margin-left: 5px;
    color: #282626;
}

.OfferdetailsRedeemed_btn {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    color: #f00b0b;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    border: none;
}

.OfferdetailsRedeemed_btn i {
    font-size: 14px;
    margin-left: 5px;
    color: #282626;
}


@media (max-width: 768px) {
    .reward_wrapflex .right {
        display: none;
    }
}



/*****OurPartners_page*********/
.OurPartners_page {
    width: 100%;
    height: auto;
}

.OurPartners_page .cust_container {
    width: 100%;
    height: auto;
    padding: 40px 30px;
    background-color: #fff;
}

.OurPartners_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.OurPartners_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.OurPartners_page .heading {
    font-size: 40px;
    line-height: normal;
    font-weight: 600;
    color: #302e2e;
    padding: 0;
    margin: 0;
}

.OurPartners_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
}

.OurPartners_page .icon img {
    width: 100%;
    height: 100%;
}

@media (max-width: 576px) {
    .OurPartners_page .heading {
        font-size: 20px;
    }

    .OurPartners_page .backbtn {
        font-size: 20px;
    }

    .OurPartners_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
}


/****redeemyourPoints_page****/

.redeemyourPoints_page {
    width: 100%;
    height: auto;
    padding: 40px 0px;
    background-color: #fff;
}

.redeemyourPoints_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.redeemyourPoints_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.redeemyourPoints_page .heading {
    font-size: 32px;
    line-height: normal;
    font-weight: 600;
    color: #646060;
    padding: 0;
    margin: 0;
}

.redeemyourPoints_page .heading span {
    color: #EAA902;
    margin-right: 10px;
}

.redeemyourPoints_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.redeemyourPoints_page .icon img {
    width: 100%;
    height: 100%;
}

.redeemyourPoints_page .Pointsbalance {
    width: 100%;
    height: auto;
    background-color: #E4A1FC;
    padding: 30px 30px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.redeemyourPoints_page .Pointsbalance .text {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #000000;
    padding: 0;
    margin-right: 15px;
}

.redeemyourPoints_page .Pointsbalance .amount {
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    color: #000000;
    padding: 0;
    margin: 0;
}

.Howitwork_btn {
    width: 100%;
    max-width: 180px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
    text-decoration: none;
    border: none;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: #000000;
    padding: 5px 15px;
    cursor: pointer;
}

.Howitwork_btn i {
    margin-left: 10px;
    font-size: 16px;
}

.activities_card {
    width: 100%;
    height: auto;
    border: 1px solid #662D91;
    background-color: #F9EDFF;
    margin-bottom: 30px;
    border-radius: 12px;
    overflow: hidden;
}

.activities_card .top_atra {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.activities_card .card_name {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin-right: 15px;
}

.activities_card .ViewProfile_btn {
    border: none;
    box-shadow: none;
    text-decoration: none;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    font-size: 12px;
    color: #000000;
    font-weight: 500;
}


.activities_card .image_area {
    width: 100%;
    height: 144px;
    overflow: hidden;
}

.activities_card .image_area img {
    width: 100%;
    height: 100%;
}

.activities_card .entry_ticket {
    width: 100%;
    height: auto;
    padding: 15px 15px;
}

.activities_card .entry_ticket h6 {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #000000;
    padding: 0;
    margin-bottom: 2px;
}

.activities_card .entry_ticket p {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 0px;
}

.activities_card .bottom_area {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
}

.activities_card .bottom_area .points {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #000000;
    padding: 0;
    margin: 0px;
}

.Getreward_btn {
    border: none;
    box-shadow: none;
    text-decoration: none;
    background-color: #662D91;
    outline: 0;
    cursor: pointer;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    padding: 8px 15px;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.Getreward_btn.deactive {
    background-color: #B2AEB5;
}

.Getreward_btn span {
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 2px;
    font-size: 12px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.activities_card .offervalid_text {
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}

.activities_card .offervalid_text .offdate {
    font-size: 15px;
    line-height: normal;
    text-align: center;
    color: #000000;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.activities_card .offervalid_text .fa-check {
    color: #15c51e;
    margin-right: 5px;
    font-size: 16px;
}

.activities_card .offervalid_text .fa-xmark {
    color: red;
    margin-right: 5px;
    font-size: 16px;
}


@media (max-width: 576px) {
    .redeemyourPoints_page .heading {
        font-size: 20px;
    }

    .redeemyourPoints_page .backbtn {
        font-size: 20px;
    }

    .redeemyourPoints_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
}

@media(max-width: 480px) {
    .redeemyourPoints_page .Pointsbalance .text {
        font-size: 16px;
    }

    .redeemyourPoints_page .Pointsbalance .amount {
        font-size: 22px;
    }
}



/***************/
/*****OurPartners_page*********/
.OurPartners_page {
    width: 100%;
    height: auto;
}

.OurPartners_page .cust_container {
    width: 100%;
    height: auto;
    padding: 40px 30px;
    background-color: #fff;
}

.OurPartners_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.OurPartners_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.OurPartners_page .heading {
    font-size: 40px;
    line-height: normal;
    font-weight: 600;
    color: #302e2e;
    padding: 0;
    margin: 0;
}

.OurPartners_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
}

.OurPartners_page .icon img {
    width: 100%;
    height: 100%;
}

@media (max-width: 576px) {
    .OurPartners_page .heading {
        font-size: 20px;
    }

    .OurPartners_page .backbtn {
        font-size: 20px;
    }

    .OurPartners_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
}


/****mywallet_page****/

.mywallet_page {
    width: 100%;
    height: auto;
    padding: 40px 0px;
    background-color: #fff;
}

.mywallet_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.mywallet_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.mywallet_page .heading {
    font-size: 32px;
    line-height: normal;
    font-weight: 600;
    color: #646060;
    padding: 0;
    margin: 0;
}

.mywallet_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.mywallet_page .icon img {
    width: 100%;
    height: 100%;
}

.notification_count {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    background-color: #F60606;
}

/* offer categories */
.OffersCategories_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.OffersCategories_page .icon img {
    width: 100%;
    height: 100%;
}

.notification_count {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    background-color: #F60606;
}

/* our partners */
.OurPartners_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.OurPartners_page .icon img {
    width: 100%;
    height: 100%;
}

.notification_count {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    background-color: #F60606;
}

.walletactivities_card {
    width: 100%;
    height: auto;
    border: 1px solid #6D6E71;
    box-shadow: 0px 0px 0px 0px #6D6E71;
    border-radius: 20px;
    padding: 30px 50px;
    position: relative;
    display: flex;
    cursor: pointer;
}

.walletactivities_card .leftstyle {
    position: absolute;
    top: 50%;
    left: -1px;
    transform: translateY(-50%);
    z-index: 2;
    width: 25px;
    height: 70px;
    border-radius: 0 20px 20px 0;
    /* box-shadow: 0px 0px 5px 0px #6D6E71; */
    border: 1px solid #6D6E71;
    border-left: none;
    background-color: #fff;
}

.walletactivities_card .rightstyle {
    position: absolute;
    top: 50%;
    right: -1px;
    transform: translateY(-50%);
    z-index: 2;
    width: 25px;
    height: 70px;
    border-radius: 20px 0 0 20px;
    /* box-shadow: 0px 0px 5px 0px #6D6E71; */
    border: 1px solid #6D6E71;
    border-right: none;
    background-color: #fff;
}

.walletactivities_card .left_wrap {
    width: 50%;
    height: auto;
    border-right: 1px solid #C3C1C1;
    padding: 0 20px;
}

.walletactivities_card .left_wrap .head {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 15px;
}

.walletactivities_card .img_wrap {
    width: 100%;
    max-width: 150px;
    height: auto;
}

.walletactivities_card .img_wrap img {
    width: 100%;
    height: 100%;
}

.walletactivities_card .right_wrap {
    width: 50%;
    height: auto;
    padding: 0 20px;
}

.walletactivities_card .free {
    font-size: 28px;
    font-weight: 600;
    color: #000000;
    text-align: left;
    padding: 0;
    margin-bottom: 5px;
}

.walletactivities_card .ticket {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-align: left;
    padding: 0;
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 40px;
}

.walletactivities_card .code {
    font-size: 16px;
    font-weight: 600;
    color: #A3A2A2;
    text-align: left;
    padding: 0;
    margin-bottom: 20px;
}

.walletactivities_card .Valid {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #DE1818;
    padding: 0;
    margin: 0;
}

@media (max-width: 1400px) {
    .walletactivities_card .Valid {
        font-size: 12px;
    }

    .walletactivities_card .code {
        font-size: 12px;
    }

    .walletactivities_card .ticket {
        font-size: 14px;
    }

    .walletactivities_card .free {
        font-size: 18px;
    }

    .walletactivities_card .left_wrap .head {
        font-size: 16px;
    }
}



@media (max-width: 576px) {
    .mywallet_page .heading {
        font-size: 20px;
    }

    .mywallet_page .backbtn {
        font-size: 20px;
    }

    .mywallet_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
}

@media (max-width: 480px) {
    .walletactivities_card .left_wrap .head {
        font-size: 12px;
        margin-bottom: 0px;
    }

    .walletactivities_card .img_wrap {
        width: 100%;
        max-width: 100px;
        height: auto;
    }

    .walletactivities_card .free {
        font-size: 16px;
    }

    .walletactivities_card .ticket {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .walletactivities_card .code {
        font-size: 10px;
        margin-bottom: 15px;
    }

    .walletactivities_card .Valid {
        font-size: 10px;
    }

    .walletactivities_card {
        padding: 25px 30px;
    }

    .walletactivities_card .leftstyle {
        width: 18px;
        height: 60px;
        border-radius: 0 12px 12px 0;
    }

    .walletactivities_card .rightstyle {
        width: 18px;
        height: 60px;
        border-radius: 12px 0 0 12px;
    }

    .walletactivities_card .left_wrap {
        width: 45%;
        height: auto;
        padding: 0 10px;
    }

    .walletactivities_card .right_wrap {
        width: 55%;
        height: auto;
        padding: 0 10px;
    }
}


/****rewardsAbout_page*****/

.rewardsAbout_page {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.rewardsAbout_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.rewardsAbout_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.rewardsAbout_page .heading {
    font-size: 32px;
    line-height: normal;
    font-weight: 600;
    color: #646060;
    padding: 0;
    margin: 0;
}

.rewardsAbout_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.rewardsAbout_page .icon img {
    width: 100%;
    height: 100%;
}

.rewardsAbout_page .accordion {
    border: none;
}

.rewardsAbout_page .accordion__button {
    position: relative;
    padding: 15px 40px 15px 15px;
    background-color: transparent;
}

.rewardsAbout_page .accordion__button p {
    font-size: 22px;
    line-height: normal;
    text-align: left;
    font-weight: 500;
    color: #000;
}

.rewardsAbout_page .accordion__button:before {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: rotate(45deg);
}

.rewardsAbout_page .accordion__button[aria-expanded='true']::before,
.rewardsAbout_page .accordion__button[aria-selected='true']::before {
    transform: rotate(-135deg);
}

.rewardsAbout_page .accordion__panel p {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    color: #525151;
    margin-bottom: 15px;
    text-align: justify;
}


@media (max-width: 576px) {
    .rewardsAbout_page .heading {
        font-size: 20px;
    }

    .rewardsAbout_page .backbtn {
        font-size: 20px;
    }

    .rewardsAbout_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
}

@media (max-width: 480px) {
    .rewardsAbout_page .accordion__button p {
        font-size: 16px;
    }

    .rewardsAbout_page .accordion__panel p {
        font-size: 12px;
    }
}


/***howEarningRewards_page****/

.howEarningRewards_page {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.howEarningRewards_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 5px;
}

.howEarningRewards_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.howEarningRewards_page .heading {
    font-size: 32px;
    line-height: normal;
    font-weight: 600;
    color: #646060;
    padding: 0;
    margin: 0;
}

.howEarningRewards_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.howEarningRewards_page .icon img {
    width: 100%;
    height: 100%;
}


.howEarningRewards_page .conetnt {
    width: 100%;
    height: auto;
    /* padding: 30px 0; */
}

.earningRewards_banner {
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-color: #457f93de;
    margin-bottom: 40px;
}

.earningRewards_banner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.earninghowareyou_wrap {
    width: 100%;
    height: auto;
    padding: 0 40px 20px;
}

.earninghowareyou_wrap .head {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
    color: #0F0E0E;
    padding: 0;
    margin-bottom: 20px;
}

.earninghowareyou_wrap .member_box {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 20px;
}

.earninghowareyou_wrap .member_box .icon {
    width: 100%;
    max-width: 35px;
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.earninghowareyou_wrap .member_box .icon img {
    width: 100%;
    height: 100%;
}

.earninghowareyou_wrap .ditails .name {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
    color: #030303;
    padding: 0;
    margin-bottom: 2px;
}

.earninghowareyou_wrap .ditails .text {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    color: #6B6B6B;
    padding: 0;
    margin: 0;
}

.earninghowareyou_wrap .ditails ol {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-left: 20px;
}

.earninghowareyou_wrap .ditails ol li {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    color: #6B6B6B;
}

.Summary_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
}

.Summary_btn .btn {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    background-color: #E2E2E2;
    border: 1px solid #C7C7C7;
    padding: 5px 20px;
    border-radius: 6px;
    color: #646060;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
}

.OfferTerms_Conditions {
    width: 100%;
    height: auto;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #B5B5B5;
    border-bottom: 1px solid #B5B5B5;
}

.OfferTerms_Conditions h4 {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    line-height: normal;
    color: #030303;
    padding: 0;
    margin: 0;
}

.OfferTerms_Conditions span {
    font-size: 16px;
    color: #5e5d5d;
    width: 100%;
    max-width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #5e5d5d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

@media (max-width: 768px) {
    .earningRewards_banner {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .howEarningRewards_page .heading {
        font-size: 20px;
    }

    .howEarningRewards_page .backbtn {
        font-size: 20px;
    }

    .howEarningRewards_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
    .earninghowareyou_wrap {
        padding: 0 0 20px;
    }

    .OfferTerms_Conditions h4 {
        font-size: 18px;
    }
    .OfferTerms_Conditions span {
        font-size: 14px;
        max-width: 20px;
        min-width: 20px;
        height: 20px;
    }
    .howEarningRewards_page {
        padding: 15px 0 20px;
    }
}

@media (max-width: 480px) {
    .howEarningRewards_page .heading {
        font-size: 18px;
    }
}


/*******howworkRewards_page****/

.howworkRewards_page {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.howworkRewards_page .top_area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;
    border-bottom: 1px solid #646060;
    margin-bottom: 20px;
}

.howworkRewards_page .backbtn {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-shadow: none;
    border: none;
    outline: 0;
    background-color: transparent;
    font-size: 34px;
    color: #282626;
}

.howworkRewards_page .heading {
    font-size: 32px;
    line-height: normal;
    font-weight: 600;
    color: #646060;
    padding: 0;
    margin: 0;
}

.howworkRewards_page .icon {
    width: 100%;
    max-width: 50px;
    min-width: 50px;
    height: auto;
    position: relative;
}

.howworkRewards_page .icon img {
    width: 100%;
    height: 100%;
}


.howworkRewards_page .conetnt {
    width: 100%;
    height: auto;
    padding: 30px 0;
}

.howworkRewards_page .conetnt p {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    font-weight: 400;
    color: #525151;
    margin-bottom: 20px;
    text-align: justify;
}





@media (max-width: 576px) {
    .howworkRewards_page .heading {
        font-size: 20px;
    }

    .howworkRewards_page .backbtn {
        font-size: 20px;
    }

    .howworkRewards_page .icon {
        max-width: 30px;
        min-width: 30px;
    }
}

@media (max-width: 480px) {
    .howworkRewards_page .conetnt p {
        font-size: 14px;
        margin-bottom: 15px;
    }
}