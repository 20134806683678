.custom-toast {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Arial, sans-serif;
  }
  
  .toast-icon {
    background-color: #4caf50; /* Green background */
    color: white;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circle icon */
  }
  
  .toast-message {
    display: flex;
    flex-direction: column;
    color: #333; /* Text color */
  }
  
  .toast-message strong {
    font-size: 16px;
    color: #4caf50; /* Green text for the title */
  }
  
  .toast-message p {
    margin: 0;
    font-size: 14px;
  }
  
  .toast-message small {
    font-size: 12px;
    color: #666; /* Gray color for small text */
  }
  