.contactbg {
    width: 100%;
    max-width: 80%;
    background-color: #fff;
    height: 100%;
    margin: 10px 0;
    padding: 20px;
    border-radius: 10px;
}

.contctbrdr {
    border: 1px solid #67308F;
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #67308f14;
}

.cntntflx {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* align-items: center; */
}

.imgbrdrdiv {
    width: 113px;
    height: 86px;
    border-radius: 50%;
    border: 2px solid #67308F;
}

.cntcnmdtlsmai {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.jhntxtlws {
    color: #585454;
    font-size: 24px;
    line-height: 18px;
    font-weight: 600;
    position: relative;
    /* margin-left: -10px; */
    margin: 0;
}

.prdltxtflx {
    display: flex;
    align-items: flex-end;
    gap: 4%;
    margin-bottom: 5px;
    width: 100%;
}

.prdltxtflx.wrapbox {
    display: block;
    align-items: center;
}

.mrttxtt {
    color: #585454;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
}

.dtghhnjkd {
    width: 100%;
    max-width: 100%;
}

.jhntxtlws::after {
    content: "";
    position: absolute;
    top: 2px;
    right: -7px;
    width: 0px;
    border: 1px solid #585454;
    height: 100%;
}

.cmpntyxt {
    color: #4C58FC;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    position: relative;
    margin-bottom: 3px;
}

.cmpntyxt::after {
    content: "";
    position: absolute;
    top: 3px;
    right: -7px;
    width: 0px;
    border: 1px solid #4C58FC;
    /* height: 10px; */
    height: 80%;
}

.prdltxtflx.wrapbox .cmpntyxt::after {
    display: none;
}

.dghttxt {
    color: #4C58FC;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.prdltxtflx.wrapbox .dghttxt {
    font-size: 14px;
    color: #420172;
}

.mbltxtop {
    color: #655C5C;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    position: relative;
}

.mbltxtop span {
    font-weight: bold;
    font-size: 14px;
}

/* .mbltxtop::after {
    content: "";
    position: absolute;
    top: 3px;
    right: -7px;
    width: 0px;
    border: 1px solid #655C5C;
    height: 75%;
} */

.mbbjlisown {
    color: #655C5C;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
}

.lksflwresrt {
    display: flex;
    justify-content: flex-start;
    gap: 18px;
    color: #010203;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 10px;
}

.upbtndiv {
    background-color: #662D91;
    padding: 6px 20px;
    color: #fff;
    border: none;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.upbtndiv:hover {
    background: #009E60;
}

.brdrblckdiv {
    border: 1px solid #EDEEFF;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
}

.brdrblckdiv:hover {
    background-color: #67308f14;
}

/* .prflimgbrdrdiv{
    width: 98px;
    height: 102px;
    border-radius: 50%;
    border: 2px solid #67308F;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.imgvrtdiv {
    height: 80px;
    width: 100%;
    min-width: 80px;
    max-width: 80px;
    border-radius: 50%;
    overflow: hidden;
   
}

.imgvrtdiv img {
    width: 100%;
    height: 100%;

}

.vrdfbrdr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1%;
    border: 1px solid #DBDEFE;
    padding: 5px 10px;
    border-radius: 10px;
    color: #000;
}

.vrdfbrdr img {
    width: 20px;
    height: 20px;
    margin-left: 3px;
}

.buttonwrap_asse {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
}

.upbtndivmainlop {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 7px;
}

.loadmoreBtnDiv {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 15px;
}

.loadmoreBtn {
    background-color: #67308F;
    width: 200px;
    height: 35px;
    border-radius: 9px;
    outline: 0;
    border: none;
    color: #fff;
    font-size: 15px;
}


.iommdot {
    position: relative;
}

.iommdot .dropDelete {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #838383;
    padding: 10px 15px;
    border-radius: 4px;
    overflow: hidden;
    width: auto;
}

.iommdot .dropDelete ul {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
}

.iommdot .dropDelete ul li {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: normal;
    text-align: left;
    color: #3b3a3a;
    font-weight: 600;
    margin-right: 15px;
}

.iommdot .dropDelete ul li:last-child {
    margin-right: 0;
}

.iommdot .dropDelete ul li .whatsapp {
    font-size: 22px;
    color: rgb(6, 109, 3);
    margin-right: 5px;
}

.iommdot .dropDelete ul li .trash {
    font-size: 16px;
    color: rgb(196, 2, 2);
    margin-right: 5px;
}

.iommdot .dropDelete ul li .drctrCntctIconOpenDel {
    width: 100%;
    max-width: 22px;
    min-width: 22px;
    height: 22px;
    border-radius: 50%;
}

.whtsappimg {
    width: 41px;
    height: 40px;
}

.whtsappimg img {
    width: 100%;
    height: 100%;
}

.iommdot i {
    /* margin-right: 12px; */
    color: #000;
}

.imgbgdrk {
    width: 113px;
    height: 86px;
    border-radius: 50%;
    background-color: #C4C4C4;
}

.txtljklppp {
    color: #010203;
    font-Size: 15px;
    font-family: 'Inter', sans-serif;
    line-Height: 22px;
    font-weight: 400;
}

.txtljklppp span {
    font-Weight: 600;
}

.cntcgbgdiv {
    /* border: 1px solid #67308F; */
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #67308f14;
}

.responResFig {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.contResFig {
    /* position: absolute;
    bottom: 10px;
    right: 10px; */
    width: 30px;
    height: 30px;
    display: none;
    margin: 0 0 8px 0;
}

.contResFig img {
    width: 100%;
    height: 100%;
}

/* contactlist modal */

.ContactlistMod {
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #DBD6D6;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 10;
    display: none;
}

.conListModInn {
    display: flex;
    justify-content: space-between;
}

.ConOptnFig {
    width: 30px;
    height: 30px;
    margin: 0;
}

.delfig {
    width: 23px !important;
    height: 25px !important;
}

.ConOptnFig img {
    width: 100%;
    height: 100%;
}

@media (max-width: 1399px) {
    .contactbg {
        width: 80%;
    }
}

@media (max-width:1199px) {

    .lksflwresrt {
        gap: 10px;
    }

    .contctbrdr {
        padding: 15px 10px;
    }

    .jhntxtlws {
        font-size: 22px;
    }

    .cmpntyxt,
    .dghttxt {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .contactbg {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .contactbg {
        width: 100%;
        max-width: 100%;
    }
}

@media(max-width:767px) {
    .cntntflx {
        /* flex-wrap: wrap; */
        /* justify-content: flex-start; */
        align-items: flex-start;
    }

    .cntcnmdtlsmai {
        width: 100%;
        flex-wrap: wrap;
    }

    .contctbrdr {
        position: relative;
    }

    .upbtndivmain {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .upbtndivmainlop {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        row-gap: 10px;
        column-gap: 10px;
        padding: 10px 0px;
    }

    .upbtndiv {
        padding: 5px 10px;
    }

    .imgbgdrk {
        width: 80px;
        height: 80px;
    }

    .imgbrdrdiv {
        width: 90px;
        height: 86px;
        border-radius: 50%;
        border: 2px solid #67308F;
    }

    .dtghhnjkd {
        padding: 10px 0px;
    }

    .imgvrtdiv {
        width: 100%;
        max-width: 90px;
        min-width: 90px;
        height: 90px;
    }

    .jhntxtlws {
        margin-left: 0;
        font-size: 17px;
    }

    .prdltxtflx {
        align-items: center;
    }

    .lksflwresrt,
    .txtljklppp {
        font-size: 13px;
    }
}

@media(max-width:575px) {
    .ContactlistMod {
        display: block;
    }

    .whtsappimg {
        display: none;
    }

    .brdrblckdiv {
        position: relative;
        padding: 10px;
    }

    /* .cntntflx {
        display: block;
    } */

    .iommdot {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .vrdfbrdr {
        display: none;
    }

    .contResFig {
        display: block;
        /* bottom: auto;
        right: 10px;
        top: 42%; */
        width: 25px;
        height: 25px;
    }

    .cntntflx {
        position: relative;
        padding-top: 16px;
    }

    .mbrtusrprflflx {
        padding-top: 20px;
    }

    .upbtndivmain {
        position: absolute;
        top: -6px;
        right: 0px;
    }

    .upbtndiv {
        padding: 5px 9px;
        font-size: 12px;
        border-radius: 4px;
    }
}

@media(max-width:480px) {

    .contactbg {
        padding: 15px;
    }

    .cmpntyxt {
        color: #4C58FC;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        position: relative;
    }

    .dghttxt {
        color: #4C58FC;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
    }

    .cmpntyxt::after {
        top: 4px;
    }

    .lksflwresrt {
        flex-wrap: wrap;
    }

    .jhntxtlws {
        font-size: 15px;
    }

    .contResFig {
        width: 20px;
        height: 20px;
        /* bottom: auto;
        right: 10px;
        top: 42%; */
    }

    .imgvrtdiv {
        width: 100%;
        max-width: 70px;
        min-width: 70px;
        height: 70px;
    }

    .imgbgdrk {
        width: 70px;
        height: 70px;
    }

    .imgbrdrdiv {
        width: 70px;
        height: 70px;
    }
}

@media(max-width:420px) {

    .ConOptnFig {
        width: 22px;
        height: 22px;
    }

    .delfig {
        width: 18px !important;
        height: 18px !important;
    }

    .cmpntyxt {
        color: #4C58FC;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        position: relative;
    }

    .dghttxt {
        color: #4C58FC;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
    }

    .contctbrdr {
        border: 1px solid #67308F;
        padding: 15px 10px;
        border-radius: 10px;
    }

    .jhntxtlws::after {
        right: -5px;
    }

    .cmpntyxt::after {
        right: -5px;
    }

    .mbltxtop::after {
        right: -5px;
    }

    .mbltxtop {
        font-size: 12px;
        line-height: 14px;
    }

    .mbltxtop span {
        font-size: 13px;
    }

    .mbbjlisown {
        font-size: 12px;
        line-height: 14px;
    }


}