/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */

.chatpage_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 89vh;
  background-color: #fff;
}

.hiddenfooter {
  display: none;
}

.inpuyttyimagdedivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  padding: 6px 15px;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: .5s all ease-in-out;
}
.inpuyttyimagdedivflx:hover{
  background-color: #ccccccc8;
}

.inputimagadibvmain {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  position: relative;
}

/* <<<<<<< HEAD
==== */
.dropdowmmdkiv {
  position: relative;
  z-index: 7;
}

.dropdowmmdkiv i {
  color: #000000;
  font-size: 15px;
}

.dropdowmmdkivdivmaoin {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.delkteeooforwtsd {
  border: none;
  color: #000000;
  font: 400 14px/18px Roboto;
  text-align: left;
  margin-bottom: 10px;
  background-color: transparent;
}

.delkteeooforwtsd:last-child {
  margin-bottom: 0px;
}

.delkteeooforwtsd:hover,
.delkteeooforwtsd:focus {
  color: #000000;
  text-decoration: none;
}

.inputimagadibvmain img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.inputimagadibvmaininputfile {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.inputimagedfiletxtpp {
  font: 400 14px/18px Roboto;
  color: #000000;
}

.chatpage_sec .main-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: calc(85vh - 15px);
  background: #fff;
  display: flex;
  box-shadow: 0px 0px 5px 0 rgb(171 168 168);
}

.chatpage_sec .main-container .left-container {
  position: relative;
  width: 30%;
  height: 100%;
  flex: 30%;
  background: #fff;
}

.chatpage_sec .main-container .right-container {
  position: relative;
  width: 70%;
  height: 100%;
  flex: 70%;
  background: #f2e5fd;
}

/* .chatpage_sec .main-container .right-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(https://camo.githubusercontent.com/854a93c27d64274c4f8f5a0b6ec36ee1d053cfcd934eac6c63bed9eaef9764bd/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67);
    opacity: 0.5;
} */

.chatpage_sec .header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background: #f0f2f5;
  box-shadow: 0px 0px 2px 0px #ccc;
  padding: 0 15px;
}

.chatpage_sec .archivebtn_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.chatpage_sec .archivebtn_wrap .btn {
  text-decoration: none;
  border: none;
  background-color: #d9d9d9;
  outline: 0;
  box-shadow: none;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding: 5px 15px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chatpage_sec .user-img {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid rgb(103, 48, 143);
  padding: 2px;
}

.chatpage_sec .dp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.chatpage_sec .nav-icons {
  display: flex;
  justify-content: flex-end;
  /* padding-left: 110px; */
}

.chatpage_sec .nav-icons li {
  /* background-color: pink; */
  list-style: none;
  display: flex;
  cursor: pointer;
  color: #67308f;
  margin-left: 22px;
  font-size: 18px;
  position: relative;
}

.dropsubmenu_box {
  position: absolute;
  top: 95%;
  right: 0;
  left: auto;
  width: 100%;
  width: 130px;
  padding: 8px 0px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #a0a0a0;
  z-index: 9;
}

.dropsubmenu_box .list {
  width: 100%;
  height: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropsubmenu_box .list li {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  margin-left: 0;
  padding: 5px 10px;
  line-height: normal;
}

.dropsubmenu_box .list li .btn {
  width: 100%;
  height: auto;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
  text-align: left;
  color: #333;
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropsubmenu_box .list li:hover {
  /* background-color: #e9cffb; */
  background-color: #eeeeee;
}

.chatpage_sec .notif-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 70px;
  background: #76daff;
  align-items: center;
  font-size: 0.8em;
  text-decoration: none;
}

.chatpage_sec .notif-box i {
  position: relative;
  left: 13px;
  background: #fff;
  padding: 10px;
  width: 42px;
  height: auto;
  font-size: 20px;
  border-radius: 55%;
  cursor: pointer;
  color: #76daff;
}

.chatpage_sec .notif-box .fa-xmark {
  position: absolute;
  left: 260px;
  text-align: center;
  background: #76daff;
  color: #fff;
}

.chatpage_sec .notif-text {
  margin: 25px;
}

.chatpage_sec .notif-text a {
  text-decoration: none;
  color: #333;
  font-size: 0.9em;
}

.chatpage_sec .search-container {
  position: relative;
  width: 93%;
  height: auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.chatpage_sec .search-container input {
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  background: #f0f2f5;
  padding: 5px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #6b7c85;
  padding-left: 40px;
}

.chatpage_sec .search-container i {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7c85;
  font-size: 0.9em;
}

.chatpage_sec .chat-list {
  position: relative;
  height: calc(100% - 130px);
  overflow-y: auto;
}

.chatpage_sec .chat-list .chat-box {
  position: relative;
  width: 100%;
  display: flex;
  /*   justify-content: center; */
  align-items: center;
  cursor: pointer;
  padding: 8px 15px;
  border-bottom: 1px solid #eee;
}

.chatpage_sec .chat-list .chat-box .img-box {
  position: relative;
  max-width: 45px;
  min-width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  padding: 2px;
}

.chatpage_sec .chat-list .chat-box .chat-details {
  width: 100%;
  margin-left: 10px;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-head h4 {
  font-size: 16px;
  font-weight: 600;
  color: #6b7c85;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-head .time {
  font-size: 0.8em;
  color: #6b7c85;
  font-weight: 500;
}

.chatpage_sec .chat-list .chat-box:hover .chat-details .text-head .time {
  display: none;
}

.chatpage_sec .chat-list .chat-box .onhover_btn {
  display: none;
  cursor: pointer;
  position: relative;
}

.chatpage_sec .chat-list .chat-box .onhover_btn i {
  font-size: 18px;
  color: #6b7c85;
}

.chatpage_sec .chat-list .chat-box .onhover_btn .dropsubmenu_box {
  display: none;
}

.chatpage_sec .chat-list .chat-box:hover .onhover_btn {
  display: inline-block;
}

.chatpage_sec .chat-list .chat-box .onhover_btn:hover .dropsubmenu_box {
  display: inline-block;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-message p {
  color: #6b7c85;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.chatpage_sec img {
  width: 100%;
  object-fit: cover;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-message b {
  background: #06e744;
  color: #fff;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  /* text-align: center; */
  font-size: 11px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatpage_sec .chat-list .active {
  /* background: #e9cffb; */
  background: #ededed;
}

.chatpage_sec .chat-list .chat-box:hover {
  /* background: #e9cffb; */
  background: #ededed;
}

.chatpage_sec .chat-list .chat-box .chat-details .text-head .unread {
  color: #06e744;
}

.chat-black-text {
  color: rgb(105, 101, 101);
  font-size: 15px;
  font-weight: normal;
}

.chat-purple-text {
  color: rgb(103, 48, 143);
  font-size: 15px;
  font-weight: bold;
}

.chat-truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.chat-unseen-count {
  background-color: rgb(103, 48, 143);
  color: white;
  border-radius: 50%;
  padding: 5px 5px;
  font-size: 12px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  margin-left: 10px;
}

.chat-message-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.chat-message-image img {
  height: 15px;
  width: 15px;
}

.chat-unseen-count img {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
}
.imagecontentychatspcl{
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  height: auto;
}
.imagecontentychatspcl img{
  width: 100%;
  height: 100%;
}

.chat-message-sidebar-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
  /* border-bottom: 1px solid #ddd; */
}

.blocked-chat {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 20px;
  border-radius: 8px;
  font-style: italic;
  cursor: not-allowed;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.blocked-chat h2 {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.emoji-picker {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: 1000;
}

/* right-container */

.chatpage_sec .right-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatback_btn {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  padding: 0;
  text-decoration: none;
  background: transparent;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  border: none;
  font-size: 20px;
  color: #67308f;
  align-items: center;
  justify-content: center;
  display: none;
}

.chatpage_sec .right-container .header .img-text .user-img .dp {
  /* position: relative;
    top: -2px;
    left: 0px;
    width: 40px;
    height: auto;
    overflow: hidden; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chatpage_sec .right-container .header .img-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chatpage_sec .right-container .header .img-text h4 {
  font-weight: 500;
  line-height: 1em;
  margin-left: 15px;
  font-size: 18px;
}

.chatpage_sec .right-container .header .img-text h4 span {
  font-size: 12px;
  color: #555;
}

.chatpage_sec .right-container .header .nav-icons {
  position: relative;
  margin-right: 0px;
  /*   padding: 5px; */
}

.chatpage_sec .right-container .header .nav-icons i {
  padding: 10px;
}

.chatpage_sec .chat-container {
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  /*60+60*/
  padding: 30px 40px;
  overflow-y: auto;
}

.chatpage_sec .message-box {
  position: relative;
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.messagedate_box {
  display: flex;
  align-items: center;
  justify-content: baseline;
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.messagedate_box .date {
  width: 100%;
  width: auto;
  height: auto;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  background-color: #747474;
  color: #fff;
  text-align: center;
  font-size: 12px;
  margin: 0 auto;
}

.chatpage_sec .message-box.my-message p {
  position: relative;
  right: 0px;
  text-align: left;
  max-width: fit-content;
  padding: 6px 13px;
  color: #111b21;
  border-radius: 10px;
  font-size: 0.9em;
  width: 100%;
  min-width: fit-content;
  font-size: 14px;
  line-height: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.chatpage_sec .message-box.friend-message p {
  position: relative;
  left: 0px;
  text-align: left;
  max-width: fit-content;
  padding: 6px 13px;
  color: #111b21;
  border-radius: 10px;
  font-size: 0.9em;
  width: 100%;
  min-width: fit-content;
  font-size: 14px;
  line-height: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.messageanddeletforwraddivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 20px;
}

.chatpage_sec .message-box.my-message p {
  /* background: #d8a8f9; */
  background: #d9fdd3;
}

.chatpage_sec .message-box.my-message p::before {
  content: "";
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    135deg,
    #d9fdd3 0%,
    #d9fdd3 50%,
    transparent 50%,
    transparent
  );
  /* background: linear-gradient(135deg, #d8a8f9 0%, #d8a8f9 50%, transparent 50%, transparent); */
}

.chatpage_sec .message-box p span {
  display: block;
  margin-top: 5px;
  font-size: 0.7em;
  font-weight: 500;
  /* opacity: 0.5; */
  color: #111b21;
}

.chatpage_sec .my-message {
  justify-content: flex-end;
}

.chatpage_sec .friend-message p {
  background: #fff;
}

.chatpage_sec .friend-message {
  justify-content: flex-start;
}

.chatpage_sec .chat-container .my-message i {
  color: #003bd2;
}

.chatpage_sec .message-box.friend-message p::before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    225deg,
    #fff 0%,
    #fff 50%,
    transparent 50%,
    transparent
  );
}

.chatpage_sec .chatbox-input {
  position: relative;
  width: 100%;
  height: 60px;
  background: #f0f2f5;
  box-shadow: 0px 0px 2px 0px #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatpage_sec .chatbox-input i {
  cursor: pointer;
  font-size: 1.5em;
  color: #67308f;
}

.chatbox {
  position: relative;
}

.chatpage_sec .chatbox-input i:nth-child(1) {
  margin: 15px;
}

.chatpage_sec .chatbox-input i:last-child {
  margin-right: 25px;
}

.chatpage_sec .chatbox-input input {
  position: relative;
  width: 90%;
  margin: 0 20px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1em;
  border: none;
  outline: none;
  background-color: #fff;
}

@media (max-width: 768px) {
  .chatback_btn {
    display: flex;
  }

  .inputimagadibvmain {
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    position: relative;
  }

  .chatpage_sec .main-container .left-container {
    width: 100%;
    flex: 100%;
  }

  .chatpage_sec .main-container .right-container {
    width: 100%;
    flex: 100%;
  }

  .chatpage_sec {
    min-height: 70vh;
  }

  .chatpage_sec .main-container {
    height: calc(85vh - 57px);
  }

  .chatpage_sec .chat-container {
    height: calc(100% - 118px);
  }
}

@media (max-width: 576px) {
  .chatpage_sec {
    min-height: 90vh;
}

.chatpage_sec .main-container {
  height: calc(90vh - 15px);
}

  .chatpage_sec .chat-container {
    height: calc(100% - 120px);
  }
}

@media (max-width: 480px) {
  .chatpage_sec .right-container .header .img-text h4 {
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
    font-size: 16px;
  }

  .chatpage_sec .right-container .header .img-text h4 span {
    font-size: 10px;
  }

  .chatpage_sec .nav-icons li {
    margin-left: 15px;
    font-size: 16px;
  }

  .chatpage_sec .message-box p {
    /* max-width: 75%; */
    font-size: 12px;
  }

  .chatpage_sec .chatbox-input i {
    font-size: 16px;
  }

  .chatpage_sec .chatbox-input i:nth-child(1) {
    margin: 10px;
  }

  .chatpage_sec .chatbox-input input {
    margin: 0 12px;
    padding: 8px 10px;
    font-size: 12px;
  }

  .chatpage_sec .chatbox-input i:last-child {
    margin-right: 12px;
  }
}
