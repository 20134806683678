.productmodal {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #00000038;
  position: fixed;
  z-index: 999;
}

.productmodalcontent {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  padding: 20px;
  border-radius: 10px;
}

.prdcttctx {
  color: #000;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 10px;
}

.unjktxtt {
  color: #585454;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.divtstxmodal {
  padding: 10px 20px;
}

.prcdgimgj {
  padding: 20px;
  width: 100%;
  height: 300px;
}

.prcdgimgj img {
  width: 100%;
  height: 100%;
}

.icklohjj {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* profilebaneer moodal */
.uploabannermodalbgshadow {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #00000038;
  position: fixed;
  z-index: 999;
}

.uploabannermodalbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  /* padding: 20px; */
  border-radius: 10px;
}

.crossdivicon i {
  position: absolute;
  top: 6px;
  right: 17px;
  z-index: 9;
  font-size: 18px;
  color: #a9acb4;
}

.upldbannertrtxtpp {
  font: 400 25px/30px Roboto;
  color: #000;
  margin-bottom: 5px;
  margin-top: 10px;
}

.selletxtcbabbtxttpp {
  color: #a9acb4;
  font: 400 20px/25px Roboto;
}

.upldbantrtupldbannerdiv {
  border-bottom: 1px solid #b9b3b3;
  padding: 20px 40px;
}

.uploadaboxdeshhbdiv {
  width: 100%;
  max-width: 100%;
  padding: 24px 4px;
  border: 2px dashed #cbd0dc;
  border-radius: 10px;
  position: relative;
  height: 230px;
}

.uploadaboxdeshhbdivbrdrmain {
  padding: 10px 35px;
}

.choosttflttxtpp {
  color: #292d32;
  font: 500 25px/30px Roboto;
  margin: 20px 0px 8px 0px;
  text-align: center;
}

.jjshpsttsrrtxtpp {
  color: #a9acb4;
  font: 500 20px/25px Roboto;
  text-align: center;
}

.bttserfilelytxyp {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbd0dc;
  color: #54575c;
  font: 400 15px/20px Roboto;
  background-color: transparent;
  padding: 6px 10px;
  border-radius: 5px;
}

.bttserfilelytxypbtmmain {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px 0px 0px;
}

.uploadimgdibbavimg {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  margin: auto;
}

.uploadimgdibbavimg img {
  width: 100%;
  height: 100%;
}

.svavtatvvbtnnm {
  background-color: #089f29;
  color: #fff;
  padding: 4px 32px;
  font: 400 15px / 20px Roboto;
  border: none;
  border-radius: 5px;
}

.svavtatvvbtnnmdivmaokin {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px;
}

.filedibbhsjjdiflediv {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 230px;
  opacity: 0;
}

/* UploadLogo modal */
.uploalogomodalbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  min-width: 450px;
  border-radius: 10px;
}

.uploadlogobroderrardt {
  width: 100%;
  max-width: 180px;
  min-width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 2px dashed #cbd0dc;
  margin: 0px auto;
  position: relative;
}

.broowstsffibthnsttdiv {
  background-color: #d9d9d9;
  color: #000;
  font: 500 16px / 20px Roboto;
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 69%;
}

.uploadlogobroderrardtdivbtt {
  padding: 30px 70px;
}

.chooststtsrrtxtppfxtppdiv {
  margin: 30px 0px 25px 0px;
}

/* Editaboutmodal */
.edittamodalboixshadow {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #00000038;
  position: fixed;
  z-index: 999;
}

.edittamodalboixsbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  border-radius: 10px;
}

.edittappduudivnhhb {
  padding: 10px 32px;
}

.abattstdovvstttschgg {
  padding: 10px 32px;
}

.abattstdovvstttschggttstxt {
  width: 100%;
  max-width: 100%;
  height: 200px;
  resize: none;
  border: 1px solid #000000;
  padding: 10px;
}

.addservicetextdivatreediv {
  width: 100%;
  max-width: 100%;
  height: 100px;
  resize: none;
  border: 1px solid #000000;
  padding: 10px;
}

.ttsrrhhshwoolumiuitstxtt {
  text-align: right;
  font: 400 16px/20px Roboto;
  color: #000000;
  margin: 15px 0px;
}

.svavtatvvbtnnmpaggdijbtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px 30px 0px;
}

/* GalleryModal */
.upgalerymodalbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  min-width: 650px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.galrrttxtittxttxtpp {
  font: 400 20px/25px Roboto;
  color: #000;
}

.galrrttxtittxttxtppdib {
  padding-top: 20px;
}

.insttsbtrxrrds {
  width: 100%;
  max-width: 100%;
  border: 1px solid #7a7a7a;
  padding: 8px 15px;
  border-radius: 10px;
  margin-top: 8px;
}

.insttsbtrxrrds:focus {
  outline: 0;
  border: 1px solid #7a7a7a;
}

.insttsbtrxrrdstexrare {
  width: 100%;
  max-width: 100%;
  height: 180px;
  resize: none;
  border: 1px solid #7a7a7a;
  margin-top: 8px;
  border-radius: 10px;
}

.insttsbtrxrrdstexrare:focus {
  outline: 0;
  border: 1px solid #7a7a7a;
}

.uploadaboxafgaeddpadikjnffdiv {
  padding: 15px 60px;
  height: 500px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* width */
.uploadaboxafgaeddpadikjnffdiv::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.uploadaboxafgaeddpadikjnffdiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.uploadaboxafgaeddpadikjnffdiv::-webkit-scrollbar-thumb {
  background: #a9a6a6;
  border-radius: 10px;
}

/* Handle on hover */
.uploadaboxafgaeddpadikjnffdiv::-webkit-scrollbar-thumb:hover {
  background: #a9a6a6;
}

/* CompanyInformation Modal */
.compamyinfoeditbgmain {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.eidutcommhifnnfodiv {
  padding: 20px 40px 0px 40px;
}

.compamydinfodivmain {
  width: 100%;
  max-width: 80%;
  min-width: 80%;
  padding: 20px 0px 0px 0px;
}

.conattaindptxtppnewallasa {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  font-family: Roboto;
  margin-bottom: 10px;
}

.inpustxocntbusuitxtppnewallattyy {
  width: 100%;
  max-width: 100%;
  padding: 10px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.dropwdowndivfrmbusineenewwlas {
  width: 100%;
  max-width: 100%;
  padding: 13px 22px;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  position: relative;
}

.eidutcommhifnnfodivdibvamain {
  height: 490px;
  overflow-y: auto;
}

.eidutcommhifnnfodivdibvamain::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.eidutcommhifnnfodivdibvamain::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.eidutcommhifnnfodivdibvamain::-webkit-scrollbar-thumb {
  background: #a9a6a6;
  border-radius: 10px;
}

/* Handle on hover */
.eidutcommhifnnfodivdibvamain::-webkit-scrollbar-thumb:hover {
  background: #a9a6a6;
}

.compamydinfodivmain:last-child {
  width: 100%;
  max-width: 80%;
  min-width: 80%;
  padding: 0px 0px 0px 0px;
}

.svavtatvvbtnnmddsupscftt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 97px;
}

.compamydinfodivmainbtm:first-child {
  padding-top: 10px;
}

.compamydinfodivmainbtm:last-child {
  padding-bottom: 0px;
}

.vectoraarowdivomgnenwlal {
  position: absolute;
  top: 13px;
  right: 23px;
  width: 100%;
  max-width: 17px;
  min-width: 17px;
  height: 17px;
}

.vectoraarowdivomgnenwlal img {
  width: 100%;
  height: 100%;
}

.compamydinfodivmainbtm {
  padding-bottom: 15px;
}

/* qr modal */

.QRsharemodalSec {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.qrshareInn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 500px;
  border-radius: 9px;
  border: 1px solid #ccc8c8;
  background: #fbfbfb;
  padding: 50px 20px;
}

.ModClosebtn {
  position: absolute;
  top: 5px;
  right: 10px;
}

.modQRimg {
  width: 200px;
  height: 200px;
  margin: 15px auto;
}

.modQRimg img {
  width: 100%;
  height: 100%;
}

.qrDwnld {
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #009e60;
  height: 40px;
  padding: 10px 30px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #009e60;
  outline: 0;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.qrDwnld:hover {
  color: #fff;
  border: 1px solid #009e60;
  color: #009e60;
  background-color: transparent;
}

.modshareBtn {
  display: block;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #adadad;
  height: 40px;
  padding: 10px 30px;
  background: transparent;
  color: #010203;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  transition: all 0.3s ease;
}

.modshareBtn:hover {
  border: 1px solid #adadad;
  background-color: #474646;
  color: #fff;
}

.modshareBtn i {
  margin-left: 5px;
  font-size: 14px;
}

/* profile share modal */

.prflShrModSec {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.prflshrModInn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 600px;
  border-radius: 9px;
  border: 1px solid #ccc8c8;
  background: #fbfbfb;
  padding: 50px 40px;
}

.prflShrIcon {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 25px;
}

.prflshrDiv {
  width: 30%;
}

.prflshrFig {
  width: 50px;
  height: 50px;
  margin: 0px auto;
}

.prflshrFig img {
  width: 100%;
  height: 100%;
}

.prflshrnm {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}

.copyUrlDiv {
  width: 100%;
  border-radius: 15px;
  background: #d9d9d9;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.copyUrlBtn {
  width: 20%;
  border-radius: 10px;
  background: #009e60;
  height: 40px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border: none;
  outline: 0;
  gap: 10px;
}

.copyUrlBtn:hover {
  background: #585454;
  color: #fff;
}

.copyUrlLink {
  width: 80%;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-left: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.copyUrlLink {
  margin-right: 10px;
}

.copyUrlLink:hover {
  color: #000;
}

/**JoinNowModal_wrap**/
.JoinNowModal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000046;
  z-index: 999;
}

.JoinNowModal_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  height: auto;
  background-color: #f1f1f1;
  padding: 20px 30px;
  border-radius: 12px;
  z-index: 2;
}

.JoinNowModal_body .close_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  outline: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 24px;
}

.JoinNowModal_body .logo {
  width: 100%;
  max-width: 150px;
  height: auto;
  overflow: hidden;
  margin: 30px auto 15px;
}

.JoinNowModal_body .logo img {
  width: 100%;
  height: 100%;
}

.JoinNowModal_body .text {
  font-size: 16px;
  text-align: center;
  line-height: normal;
  font-weight: 400;
  color: #000;
  padding: 0;
  max-width: 300px;
  margin: 0 auto 30px;
}

.JoinNowModal_body form {
  width: 100%;
  height: 350px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: #fff;
  border: 1px solid #d9d6d6;
  border-radius: 12px;
}

.JoinNowModal_body form::-webkit-scrollbar {
  display: none;
}

.JoinNowModal_body form .form-group {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.JoinNowModal_body form label {
  font-size: 12px;
  line-height: normal;
  text-align: left;
  font-weight: 400;
  color: #000000;
  margin-bottom: 3px;
}

.JoinNowModal_body form .form-control {
  background-color: #f5f5f5;
  padding: 2px 5px;
  width: 100%;
  height: 35px;
}

.JoinNowModal_body form .form-control.paddingleft {
  padding: 2px 5px 2px 77px;
}

.JoinNowModal_body form .numbercode {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 100%;
  border: none;
  outline: 0;
  background-color: transparent;
  border-right: 1px solid #bbbaba;
}

.Submit_btn {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

.Submit_btn .btn {
  width: 100%;
  max-width: 100px;
  height: 35px;
  border-radius: 7px;
  background: #1f970b;
  color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
}

.JoinNowSuccess_Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000046;
  z-index: 999;
}

.JoinNowSuccessModal_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  height: auto;
  background-color: #f1f1f1;
  padding: 30px 30px;
  border-radius: 12px;
  z-index: 2;
}

.JoinNowSuccessModal_body .icon_wrap {
  width: 100%;
  max-width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  background-color: #1f970b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.JoinNowSuccessModal_body .icon_wrap i {
  color: #fff;
  font-size: 32px;
}

.JoinNowSuccessModal_body .content {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #7f7f7f;
  padding: 30px 20px;
  margin-bottom: 20px;
}

.JoinNowSuccessModal_body .heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #000000;
  padding: 0;
  margin-bottom: 10px;
}

.JoinNowSuccessModal_body .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #000000;
}

.done_btn {
  width: 100%;
  max-width: 80px;
  height: auto;
  border-radius: 10px;
  background: #7f7f7f;
  color: #fff;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  border: none;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
  margin: 0 auto;
}

/******upgradingModal_wrap***/

.upgradingModal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000059;
  z-index: 999;
}

.upgradingModal_wrap .upgradingModal_body {
  width: 100%;
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  z-index: 2;
}

.upgradingModal_body .content {
  width: 100%;
  padding: 20px 30px;
}

.upgradingModal_body .icon {
  width: 100%;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  margin-bottom: 20px;
}

.upgradingModal_body .icon i {
  font-size: 28px;
  color: #000;
}

.upgradingModal_body .text p {
  font-size: 16px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  color: #000;
  padding: 0;
  margin-bottom: 30px;
}

.upgradingModal_body .Leanrmore_btn {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

.upgradingModal_body .Leanrmore_btn .btn {
  width: auto;
  height: auto;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  background-color: transparent;
  padding: 0;
  margin: 20px 0 0;
  font-size: 16px;
  color: rgb(3 98 63);
  font-weight: 600;
  cursor: pointer;
}

.upgradingModal_body .Leanrmore_btn .btn:hover {
  color: rgb(3 98 63);
  box-shadow: none;
  text-decoration: none;
}

.upgradingModal_body .done_btn {
  width: 100%;
  height: auto;
  background-color: darkgreen;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  border: none;
  font-size: 18px;
  font-weight: 600;
}

/********exchangedModal_wrap******/

.exchangedModal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000059;
  z-index: 999;
}

.exchangedModal_wrap .exchangedModal_body {
  width: 100%;
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #494747;
  z-index: 2;
}

.exchangedModal_body .content {
  width: 100%;
  padding: 20px 30px;
}

.exchangedModal_body .icon {
  width: 100%;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #009e60;
  margin-bottom: 20px;
}

.exchangedModal_body .icon i {
  font-size: 28px;
  color: #fff;
}

.exchangedModal_body .head {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 0;
  margin-bottom: 15px;
}

.exchangedModal_body .sub_head {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1919;
  padding: 0;
  margin-bottom: 15px;
}

.exchangedModal_body .text {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292828;
  padding: 0;
  margin: 0;
}

.gotoyourprofile_btn {
  width: 100%;
  height: auto;
  background-color: #a8a7a7;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-decoration: none;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  border: none;
  font-size: 18px;
  font-weight: 600;
}

.gotoyourprofile_btn span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #faf8f8;
  margin-left: 10px;
}

.gotoyourprofile_btn span i {
  color: #000;
}

/****addvoucherWallet_Modal****/
.addvoucherWallet_Modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0808087e;
  z-index: 999;
}

.addvoucherWallet_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 40px 15px 20px;
  background-color: #fff;
  border-radius: 12px;
  z-index: 2;
}

.addvoucherWallet_body .close_btn {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 5;
  width: 30px;
  height: 30px;
}

.addvoucherWallet_body .close_btn .btn {
  box-shadow: none;
  border: none;
  text-decoration: none;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #010203;
}

.addvoucherWallet_body .icon {
  width: 100%;
  max-width: 100px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}

.addvoucherWallet_body .icon img {
  width: 100%;
  height: 100%;
}

.addvoucherWallet_body .text {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #010203;
  padding: 0;
  margin-bottom: 15px;
}

.addvoucherWallet_body .free_box {
  width: 100%;
  height: auto;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 100%
  );
  padding: 20px 15px;
  border-radius: 12px;
}

.addvoucherWallet_body .free_box h6 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-bottom: 2px;
}

.addvoucherWallet_body .free_box p {
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: #a8a7a7;
  padding: 0;
  margin: 0;
}

.addvoucherWallet_body .list {
  width: 100%;
  height: auto;
  margin: 15px 0;
  /* list-style: decimal; */
  padding-left: 24px;
}

.addvoucherWallet_body .list li {
  font-size: 15px;
  color: #080808c9;
  font-weight: 400;
  margin-bottom: 5px;
}

.addvoucherWallet_body .bottom_area {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

.addvoucherWallet_body .bottom_area .cancel_btn {
  background-color: transparent;
  box-shadow: none;
  text-decoration: none;
  outline: 0;
  border: none;
  color: #000;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 700;
}

.addtowallet_btn {
  background-color: #099c17;
  box-shadow: none;
  text-decoration: none;
  outline: 0;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addtowallet_btn img {
  width: 25px;
  height: auto;
  margin-right: 5px;
}

.addvoucherWallet_body .heading {
  font-size: 32px;
  line-height: normal;
  text-align: center;
  color: #662d91;
  font-weight: 700;
  padding: 0;
  margin-bottom: 15px;
}

.addvoucherWallet_body .subheding {
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #000;
  font-weight: 700;
  padding: 0;
  margin-bottom: 20px;
}

.addvoucherWallet_body .vcode {
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  text-align: center;
  color: #a3a2a2;
  padding: 0;
  margin-bottom: 20px;
}

.addvoucherWallet_body .Valid {
  font-size: 16px;
  font-weight: 400;
  color: #de1818;
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
}

/****giftvoucher_modal******/
.giftvoucher_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000075;
  z-index: 999;
}

.giftvouchermodal_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 40px 15px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 12px;
  z-index: 2;
  overflow: hidden;
}

.giftvouchermodal_body .leftstyle {
  position: absolute;
  top: 56%;
  left: -1px;
  transform: translateY(-50%);
  z-index: 2;
  width: 25px;
  height: 60px;
  border-radius: 0 20px 20px 0;
  border: 1px solid #6d6e71;
  border-left: none;
  background-color: #fff;
}

.giftvouchermodal_body .rightstyle {
  position: absolute;
  top: 56%;
  right: -1px;
  transform: translateY(-50%);
  z-index: 2;
  width: 25px;
  height: 60px;
  border-radius: 20px 0 0 20px;
  border: 1px solid #6d6e71;
  border-right: none;
  background-color: #fff;
}

.giftvouchermodal_body .close_btn {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 5;
  width: 30px;
  height: 30px;
}

.giftvouchermodal_body .close_btn .btn {
  box-shadow: none;
  border: none;
  text-decoration: none;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #010203;
}

.giftvouchermodal_body .heding {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}

.giftvouchermodal_body .company_logo {
  width: 100%;
  max-width: 180px;
  height: auto;
  margin: 0 auto 20px;
}

.giftvouchermodal_body .company_logo img {
  width: 100%;
  height: 100%;
}

.giftvouchermodal_body .entryticket {
  font-size: 28px;
  line-height: normal;
  text-align: center;
  font-weight: 500;
  color: #000000;
  padding: 0;
  margin-bottom: 30px;
}

.giftvouchermodal_body .Valid {
  font-size: 16px;
  font-weight: 500;
  color: #de1818;
  text-align: center;
  margin-bottom: 15px;
}

.giftvouchermodal_body .border {
  width: 100%;
  height: auto;
  border: 1px dashed #ccc !important;
}

.giftvouchermodal_body .barcode_wrap {
  width: 100%;
  max-width: 150px;
  height: auto;
  margin: 20px auto 15px;
}

.giftvouchermodal_body .barcode_wrap img {
  width: 100%;
  height: 100%;
}

.giftvouchermodal_body .code {
  font-size: 15px;
  line-height: normal;
  text-align: center;
  font-weight: 600;
  color: #a3a2a2;
  padding: 0;
  margin-bottom: 10px;
}

.giftvouchermodal_body .text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  color: #a3a2a2;
  padding: 0;
  margin: 0;
  max-width: 250px;
  margin: 0 auto;
  width: 100%;
}

/*****giveratting_modal****/
.giveratting_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000007c;
  z-index: 999;
}

.giverattingmodal_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  padding: 30px 20px 20px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 0px #ccc;
  width: 100%;
  max-width: 400px;
  height: auto;
  z-index: 1;
}

.giverattingmodal_body .close_btn .btn {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  background-color: transparent;
  font-size: 22px;
  color: #000000;
}

.giverattingmodal_body .heding {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #662d91;
  text-decoration: underline;
  padding: 0;
  margin-bottom: 15px;
}

.giverattingmodal_body .submit_btn {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.giverattingmodal_body .submit_btn .btn {
  width: auto;
  height: auto;
  padding: 5px 15px;
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background-color: rgb(85, 141, 245);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border: none;
}

/****ExchangedYourContactModal****/

.ExchangedYourContactModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000007c;
  z-index: 999;
}

.ExchangedYourContact_body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  padding: 30px 20px 20px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 0px #ccc;
  width: 100%;
  max-width: 400px;
  height: auto;
  z-index: 1;
}

.ExchangedYourContact_body .icondiv {
  width: 100%;
  display: grid;
  justify-content: center;
  margin-bottom: 15px;
}

.ExchangedYourContact_body .icondiv i {
  font-size: 48px;
  color: #099c17;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ExchangedYourContact_body .icondiv span {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  color: #474646;
}

.ExchangedYourContact_body .heading {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  color: #0a0a0a;
  padding: 0;
  margin-bottom: 15px;
}

.ExchangedYourContact_body .text {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  color: #0a0a0a;
  padding: 0;
  margin-bottom: 30px;
  line-height: normal;
}

.ExchangedYourContact_body .done_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExchangedYourContact_body .done_btn .btn {
  width: auto;
  height: auto;
  padding: 5px 30px;
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background-color: rgb(91, 91, 92);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border: none;
}

/* Other Product Modal */
.otherprofudvtmodalbackbox {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #00000038;
  position: fixed;
  z-index: 999;
}

.otherprofileproductbgmain {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  border-radius: 10px;
}

.threedorttsdcoroodivflx {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  column-gap: 15px;
  padding: 10px 20px 2px 20px;
}

.imnmnproduucttdthghecrs {
  width: 100%;
  max-width: 17px;
  min-width: 19px;
  height: 8px;
}

.imnmnproduucttdthghecrs img {
  width: 100%;
  height: 100%;
}

.beaiuttisprofuductimh {
  width: 100%;
  max-width: 100%;
  height: 350px;
  padding: 20px 0px;
  text-align: center;
}

.beaiuttisprofuductimh img {
  /* width: 100%; */
  height: 100%;
  border-radius: 10px;
}

.producttdescttxpppyip {
  font: 400 14px/18px Roboto;
  color: #000;
  margin-bottom: 10px;
}

.timttksopooiisuttxpp {
  font: 400 12px/15px Roboto;
  color: #000;
  margin-bottom: 10px;
  text-align: right;
}

.imnmnproduuccrtisocross {
  width: 100%;
  max-width: 16px;
  min-width: 16px;
  height: 16px;
}

.breyuuayproosttxppp {
  font: 400 28px/32px Roboto;
  color: #000;
}

.produtitleimggsdescrdiv {
  padding: 4px 40px 10px 40px;
  border-bottom: 1px solid #aba1a1;
  height: 431px;
  overflow-y: auto;
}

.produtitleimggsdescrdiv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.produtitleimggsdescrdiv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.imnmnproduuccrtisocross img {
  width: 100%;
  height: 100%;
}

.producudtlikecomrnttsennsuiquerydiv {
  padding: 4px 40px 10px 40px;

  border-bottom: 1px solid #aba1a1;
}

.producytppostcommnentdiv {
  padding: 10px 40px 10px 40px;
}

.heijggsctrdjpprodfuiiciv {
  height: 167px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.heijggsctrdjpprodfuiiciv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.heijggsctrdjpprodfuiiciv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.heijggsctrdjpprodfuiiciv.actv {
  height: 180px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.heijggsctrdjpprodfuiiciv.actv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.heijggsctrdjpprodfuiiciv.actv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.textardibbrdrdivmain {
  white-space: pre-wrap;
  word-break: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
  font-family: notosans;
  font-size: 12px;
  font-weight: 400;
  height: 67px;
  outline: none;
  overflow-y: auto;
  padding: 10px 44px 10px 10px;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.textardibbrdrdivmain::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.textardibbrdrdivmain.actv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.prodctlikecmntenquerydiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.produucvtsdlikeinmncomnf {
  width: 100%;
  max-width: 18px;
  min-width: 18px;
  height: 20px;
}

.produucvtsdlikeinmncomnf img {
  width: 100%;
  height: 100%;
}

.likkskjsbtcomnbvtyoipuxtt {
  font: 400 16px/18px Roboto;
  color: #000000;
}

.likkskjsbtcomnbvtyoipuxtt span {
  margin-left: 10px;
  font: 300 15px/18px Roboto;
}

.produucvtsdlikeinmncomnfdivvhjjd {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 10px;
  cursor: pointer;
}

.commuisonnporttsidivrrls {
  position: relative;
}

.posyynbmrmodiuvvmain {
  position: absolute;
  top: 7px;
  right: 13px;
}

.posyynbmrmodiuvsujusvbntn {
  background-color: transparent;
  border: none;
  font: 600 16px/18px Roboto;
  color: #000;
}

.commenytstthowitxtpehht {
  padding: 0px 60px 10px 60px;
}

.commentshpwfrsttcmontdiv {
  padding: 10px 0px 5px 0px;
  border-bottom: 1px solid #a3a0a0;
}

.profikellcmntimgdivflxttst {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.commentproofilleimg {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
}

.commentproofilleimg img {
  width: 100%;
  height: 100%;
}

.commporosttxttnamepp {
  font: 400 15px/18px Roboto;
  color: #000;
}

.profikellcmnjhhrrddivflxttst {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commsoprpodttctxppp {
  font: 400 14px/18px Roboto;
  color: #000;
  margin: 10px 0px 5px 0px;
}

.lodmrebtnmainndiv {
  width: 100%;
  background-color: #e0e0e1;
  color: #000;
  font: 400 16px/20px Roboto;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 0px 20px 0px;
}

.commentshpwfrsttcmontdiv:last-child {
  border-bottom: none;
}

.editabannermodalkbg {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  border-radius: 10px;
}

.diesdrddeytecroosdivffzl {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 15px;
  padding: 10px 0px 4px 10px;
}

.editabannerideletcrrsdivhjdsj {
  padding: 10px 40px 10px 40px;
  border-bottom: 1px solid #b9b3b3;
}

.dettetstyyiocnnhui {
  width: 100%;
  max-width: 17px;
  min-width: 17px;
  height: 19px;
  cursor: pointer;
}

.browoefilesabbddivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.brwdffioevbtgbjhbtn {
  width: 100%;
  max-width: 30%;
  min-width: 30%;
  background-color: #d9d9d9;
  padding: 8px 15px;
  color: #000;
  font: 500 16px/20px Roboto;
  border: none;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.dettetstyyiocnnhui img {
  width: 100%;
  height: 100%;
}

.inkspottsrrdiv {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.inkspottsrrdivdiv {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.edittsbannerttxtppp {
  font: 500 20px/25px Roboto;
  color: #000;
}

.bannerimgeditgetmain {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 300px;
}

.bannerimgeditgetmain img {
  width: 100%;
  height: 100%;
}

/*  */
.editlofgogaemodalbg {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  min-width: 400px;
  border-radius: 10px;
}

.browoefilesabbddivforprofikeflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 35px;
}

.brwdffioevbtgbjhbtnmewlogpro {
  width: 100%;
  max-width: 40%;
  min-width: 40%;
  background-color: #d9d9d9;
  padding: 8px 15px;
  color: #000;
  font: 500 16px / 20px Roboto;
  border: none;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.editsonbscimedialsodlonkbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  border-radius: 10px;
}

.sosvciidlidnbedittdiv {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
}

.sosvciidlidnbedittdiv img {
  width: 100%;
  height: 100%;
}

.sosvciidlidnbedittdivmainflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  padding-bottom: 20px;
}

.upldbantrtupldbannerdivmaindiv {
  border-bottom: 1px solid #b9b3b3;
  padding: 10px 40px;
}

.inppoiustsllonsocikk {
  width: 100%;
}

.sosvciidlidnbedittdivmainflxmaindiv {
  padding: 20px 40px;
}

.inppoiustsllonsocikkinpy {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  color: #000;
  font: 400 16px/20px Roboto;
  border: 1px solid #000;
}

.inppoiustsllonsocikkinpy:focus {
  border: 1px solid #000;
  outline: 0;
}

/*  */
.editramoffahsgaellrttdivshhafb {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  border-radius: 10px;
}

.hahgetytsiimghbmaindibv {
  width: 100%;
  max-width: 100%;
  height: 300px;
  position: relative;
  text-align: center;
}

.hahgetytsiimghbmaindibv img {
  /* width: 100%; */
  height: 100%;
}

.bggsttgaggsllerybanutn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  background-color: #d9d9d9;
  border: none;
  padding: 8px 20px;
  color: #000;
  font: 400 16px/20px Roboto;
  border-radius: 5px;
}

.ijnbnsdkpwggartyiomghghs {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 8;
  opacity: 0;
}

.hhsjjhahhahuyguuy {
  width: 100%;
  padding-bottom: 15px;
}

.hhsjjhahhahuyguuydibbanmnib {
  padding: 20px 40px;
}

.galleytyusysdheheiighgsccgyyukj {
  height: 500px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.galleytyusysdheheiighgsccgyyukj::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.galleytyusysdheheiighgsccgyyukj {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/*  */

.enquerysmokdnndbndbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 670px;
  min-width: 670px;
  border-radius: 10px;
}

.psendwendijndwjjwndiv {
  height: 600px;
  overflow-y: auto;
  padding: 20px 40px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.psendwendijndwjjwndiv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.psendwendijndwjjwndiv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.imnmnprhhhsbdijnvvgbsj {
  width: 100%;
  max-width: 16px;
  min-width: 16px;
  height: 16px;
}

.compamydinfodivmdiobbdgppshg {
  width: 100%;
  max-width: 48%;
  min-width: 48%;
  padding-bottom: 15px;
}

.compamydinfodivtesxtrareobbdgppshg {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding-bottom: 15px;
}

.svavtatvvbtnnmsendferdgf {
  background-color: #089f29;
  color: #fff;
  padding: 6px 20px;
  font: 400 15px / 20px Roboto;
  border: none;
  border-radius: 5px;
}

.ennsjjuwtysttsxttaytredg {
  width: 100%;
  height: 90px;
  resize: none;
  border: 1px solid #7c7c7c;
  border-radius: 5px;
}

.chossefilkklkebdtrfdiv {
  width: 100%;

  resize: none;
  border: 1px solid #7c7c7c;
  border-radius: 5px;
  padding: 20px 35px;
}

.enuqteyschosefllfbrdtt {
  position: relative;
  border: 1px solid #7c7c7c;
  padding: 8px 15px;
  color: #000;
  font: 400 16px/20px Roboto;
  background-color: transparent;
  border-radius: 2px;
}

.alkoowjdiisdttxpp {
  font: 400 14px/18px Roboto;
  color: #7c7c7c;
  margin-top: 5px;
}

.enuqteyschosefllfbrdttdivdkdlnb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.imnmnprhhhsbdijnvvgbsj img {
  width: 100%;
  height: 100%;
}

.compamydinfodivmainbtmhuhauhdsicflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}

/* Update Post Modal */
.updatepostmodalbg {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  min-width: 600px;
  border-radius: 10px;
  padding-bottom: 10px;
}

.poysttdiobnnnmmmnjm {
  width: 100%;
  padding-top: 20px;
}

.compamyupdatepoksttmodaldiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.poysttdiobnnnmmmnjmdivflxs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.compamydinfodivmdiobbdgppshgsadd {
  width: 100%;
  max-width: 48%;
  min-width: 48%;
}

.compamydinfodivmdiobbdgppshgsadd:last-child {
  padding-top: 15px;
}

/* Forward Message Modal */
.productmodalbgmain {
  position: absolute;
  background-color: #fff;
  /* height: 30px; */
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.productmodalbgmaintopo {
  width: 100%;
}

.serachjforwatdinputddiv {
  width: 100%;
  position: relative;
}

.serachjforwatdinput {
  position: relative;
  width: 100%;
  padding: 6px 20px 6px 50px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #ccc;
  border: none;
  background-color: #e0e0e094;
}

.serachjforwatdinputddivmain {
  padding: 10px 15px;
}

.serchjhdfijjfimngiocon i {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 9;
  font-size: 18px;
}

.recetbebtchattsttxtpp {
  width: 100%;
  font: 400 14px/18px Roboto;
  color: #000;
}

.productmodalbgmainbttom {
  width: 100%;
  padding: 10px 18px;
  height: 300px;
  overflow-y: auto;
}

.productmodalbgmainfooter {
  width: 100%;
  border-top: 1px solid #fff;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 10px 15px;
}

.inpouytcgeddfijuv {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}

.forwarerdmodalmessgediv {
  background-color: #fff;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
  height: 79px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.forwarerdmodalmessgediv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.forwarerdmodalmessgediv {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.messageforrsrwrdtxtp {
  font: 400 14px/18px Roboto;
  color: #000;
}

.dowcimmedfrfooediuvvvfoerdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}

.Documenticonpjhimg {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 40px;
}

.foriwdtrpersinedidivijcionflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  padding: 10px 0px;
}

.foriwdtrpersinedimain {
  width: 100%;
  max-width: 433px;
  min-width: 424px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fooeowrrdiodnnfiv {
  width: 100%;
  max-width: 20%;
  min-width: 20%;
}

.foriwdtrpersinedimaindklckflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.foriwdtrpersinedimaintspp {
  width: 100%;
  font: 400 15px/18px Roboto;
  color: #000;
}

.fooeowrrdiodnnfiv i {
  font-size: 30px;
  color: #502570;
}

.imaggeiikdnbdooidicttxpp {
  font: 400 14px/18px Roboto;
  color: #000;
}

.Documenticonpjhimg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inpouytcgeddfijuv input {
  width: 100%;
  height: 100%;
}

.forwtardprofolipic {
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: 50px;
}

.forwtardprofolipic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.productmodalbgmainbttommaoinflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  padding-top: 10px;
}

.productmodalbgmainbttommaoin {
  width: 100%;
  padding-top: 20px;
}

.memmdmttxonnedttxtpp {
  font: 400 14px / 18px Roboto;
  color: #000;
  width: 100%;
  width: 100%;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 20px;
}

.creossiodofdiv i {
  color: #fff;
  font-size: 15px;
  margin-left: 10px;
}

.forrwtrsymmetsttcopp {
  font: 400 18px/18px Roboto;
  color: #ffffff;
  margin-bottom: 0px;
}

.creossiodfottcoppdivflx {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  column-gap: 15px;
  width: 100%;
  background-color: #502570;
  padding: 20px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* ownpagesearchkeywordmodal */
.owenoagedjeywordbgmain {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  border-radius: 10px;
}

.crossdivmainmnm i {
  font-size: 20px;
  color: #000;
}

.crossdivmainmnm {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
}
.keywirdrserchttxtdibgflx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 15px;
}
.keywirdrserchdibvnain {
  width: 100%;
  max-width: 20%;
  min-width: 20%;
}
.keywirdrserchdibinpiuytxtdiv {
  width: 100%;
  max-width: 75%;
  min-width: 75%;
}
.keywprodsecrtinputmain {
  width: 100%;
  max-width: 100%;
  padding: 10px 10px 10px 20px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  color: #646464;
  font: 400 14px/18px Roboto;
  background-color: #fff;
}
.keywirdrserchdibvnainttxpp {
  font: 500 16px/20px Roboto;
  color: #0858d1;
}
.keywprodsecrtinputmain:focus {
  outline: 0;
  border: 1px solid #9f9f9f;
}
.keywirdrserchttxtdibgflxmain {
  padding: 5px 20px;
}
.keywirdrserchdibderselect {
  padding: 10px 10px 10px 15px;
  height: 44px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  position: relative;
}
.keywprodsecrtinputmain::placeholder {
  font: 400 14px/18px Roboto;
  color: #000000;
}
.selrcttcatreffgtxtpp {
  font: 400 14px/24px Roboto;
  color: #555252;
}
.vectorimgamindiv {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 23px;
  width: 100%;
  max-width: 17px;
  min-width: 17px;
  height: 17px;
}
.vectorimgamindiv img {
  width: 100%;
  height: 100%;
}
.chekowcutomedesign {
  width: 100%;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
}
.labbnemttxptt {
  font: 400 15px / 20px Roboto;
  color: #625e5e;
  margin-left: 10px;
  margin-bottom: 0px;
}
.chekowcutbnemttxndivflx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.cancelbscearchbtntndiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  margin: 20px 0px;
}
.cancelbtn {
  padding: 10px 15px;
  background-color: transparent;
  color: #000;
  font: 400 15px/20px Roboto;
  border: none;
}
.scearchbtn {
  padding: 10px 15px;
  background-color: #d9d9d9;
  color: #000;
  font: 400 15px/20px Roboto;
  border: none;
  border-radius: 10px;
}

.produtitleimggsdescrdivnewallada {
  padding: 30px 40px 10px 40px;
  border-bottom: 1px solid #aba1a1;
  height: 475px;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.produtitleimggsdescrdivnewallada::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.produtitleimggsdescrdivnewallada {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.textareadibmainmainbrdrnewalada {
  width: 100%;
  height: 79px;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 10px;
}
.textareadibmainrerarenewalada {
  width: 100%;
  height: 49px;
  overflow-y: auto;
  word-break: break-word;
  white-space: pre-wrap;
  font: 400 14px / 18px Roboto;
  color: #000;
  padding: 10px;
  resize: none;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  border: none;
}
.textareadibmainrerarenewalada:focus {
  outline: 0px;
  border: none;
}
.textareadibmainrerarenewalada::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.textareadibmainrerarenewalada {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* single chat big modal */
.sihnglechatbigdbgmain {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  border-radius: 10px;
}
.chatconytenybigmodalbtmm {
  width: 100%;
  padding: 0px;
  padding: 0px 10px 10px 10px;
}
.chatconytentbigmdlimg {
  width: 100%;
  height: 500px;
}
.chatconytentbigmdlimg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chatconytentbigmdlimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* Responsive */

@media (max-width: 1499px) {
  .producttdescttxpppyip {
    font: 400 12px / 15px Roboto;
    color: #000;
    margin-bottom: 10px;
  }
  .produtitleimggsdescrdivnewallada {
    height: 404px;
    overflow-y: auto;
  }

  .beaiuttisprofuductimh {
    width: 100%;
    max-width: 100%;
    height: 293px;
    padding: 20px 0px;
  }

  .uploadaboxafgaeddpadikjnffdiv {
    padding: 15px 60px;
    height: 450px;
    overflow-y: auto;
  }

  .psendwendijndwjjwndiv {
    height: 542px;
    overflow-y: auto;
    padding: 20px 40px;
  }
}

@media (max-width: 991px) {
  .sihnglechatbigdbgmain {
    max-width: 700px;
    min-width: 700px;
  }
}

@media (max-width: 767px) {
  .productmodalcontent {
    width: 90%;
  }

  .prcdgimgj {
    padding: 15px 10px;
  }
  .sihnglechatbigdbgmain {
    max-width: 500px;
    min-width: 500px;
  }
  .chatconytentbigmdlimg {
    width: 100%;
    height: 420px;
  }

  .prdcttctx {
    font-size: 20px;
  }

  .enquerysmokdnndbndbg {
    width: 100%;
    max-width: 500px;
    min-width: 500px;
    border-radius: 10px;
  }

  .unjktxtt {
    font-size: 13px;
  }

  .prflshrModInn {
    width: 85%;
  }

  .compamyinfoeditbgmain {
    width: 100%;
    max-width: 550px;
    min-width: 550px;
  }

  .prflshrFig {
    width: 35px;
    height: 35px;
  }

  .prflshrnm {
    font-size: 14px;
  }

  .copyUrlDiv {
    height: 50px;
    margin-bottom: 0px;
  }

  .edittamodalboixsbg {
    width: 100%;
    max-width: 450px;
    min-width: 450px;
    border-radius: 10px;
  }

  .copyUrlBtn {
    height: 30px;
    padding: 5px 15px;
    font-size: 13px;
  }

  .otherprofileproductbgmain {
    width: 100%;
    max-width: 570px;
    min-width: 570px;
  }

  .editsonbscimedialsodlonkbg {
    width: 100%;
    max-width: 500px;
    min-width: 500px;
    border-radius: 10px;
  }

  .copyUrlLink {
    font-size: 13px;
    padding-left: 5px;
  }

  .prflshrModInn {
    padding: 40px 15px;
  }

  .prflShrIcon button img {
    width: 50px;
    height: auto;
  }

  .upgalerymodalbg {
    width: 100%;
    max-width: 550px;
    min-width: 550px;
  }
}

@media (max-width: 575px) {
  .prcdgimgj {
    height: 250px;
  }

  .modQRimg {
    width: 150px;
    height: 150px;
  }

  .qrshareInn {
    padding: 30px 20px;
    width: 90%;
  }

  .enquerysmokdnndbndbg {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
  }
  .owenoagedjeywordbgmain {
    width: 100%;
    max-width: 420px;
    min-width: 420px;
    border-radius: 10px;
  }
  .sihnglechatbigdbgmain {
    max-width: 400px;
    min-width: 400px;
  }
  .chatconytentbigmdlimg {
    width: 100%;
    height: 400px;
  }

  .psendwendijndwjjwndiv {
    height: 542px;
    overflow-y: auto;
    padding: 20px 8px;
  }

  .qrDwnld,
  .modshareBtn {
    height: 35px;
    padding: 10px 22px;
    font-size: 13px;
  }

  .prflshrModInn {
    width: 90%;
  }

  .prflshrnm {
    font-size: 12px;
  }

  .prflshrFig {
    width: 25px;
    height: 25px;
  }

  .editsonbscimedialsodlonkbg {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 10px;
  }

  .sosvciidlidnbedittdivmainflxmaindiv {
    padding: 20px 12px;
  }

  .otherprofileproductbgmain {
    width: 100%;
    max-width: 480px;
    min-width: 480px;
  }

  .editramoffahsgaellrttdivshhafb {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 10px;
  }

  .upgalerymodalbg {
    width: 100%;
    max-width: 450px;
    min-width: 450px;
  }

  .compamyinfoeditbgmain {
    width: 100%;
    max-width: 450px;
    min-width: 450px;
  }

  .editabannermodalkbg {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 10px;
  }

  .eidutcommhifnnfodiv {
    padding: 20px 20px 0px 20px;
  }

  .brwdffioevbtgbjhbtn {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
  }

  .produtitleimggsdescrdiv {
    padding: 4px 40px 10px 40px;
    border-bottom: 1px solid #aba1a1;
    height: 382px;
    overflow-y: auto;
  }

  .uploadaboxafgaeddpadikjnffdiv {
    padding: 15px 20px;
    height: 450px;
    overflow-y: auto;
  }

  .copyUrlDiv {
    padding: 10px 5px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .productmodalbgmain {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
  }

  .JoinNowModal_body {
    max-width: 95%;
  }

  .JoinNowSuccessModal_body {
    max-width: 95%;
  }

  .breyuuayproosttxppp {
    font: 400 20px / 26px Roboto;
    color: #000;
  }

  .foriwdtrpersinedimain {
    width: 100%;
    max-width: 316px;
    min-width: 316px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .compamydinfodivmdiobbdgppshg {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .prflShrIcon button {
    margin: 0 12px 12px !important;
  }

  .prflShrIcon button img {
    width: 40px;
    height: auto;
  }

  .prcdgimgj {
    height: auto;
  }

  .giverattingmodal_body {
    max-width: 92%;
  }

  .upgalerymodalbg {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
  }

  .uploadaboxdeshhbdivbrdrmain {
    padding: 10px 15px;
  }

  .otherprofileproductbgmain {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
  }

  .compamydinfodivmdiobbdgppshgsadd {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 10px;
  }

  .compamydinfodivmdiobbdgppshgsadd:last-child {
    padding-top: 0px;
  }

  .compamyinfoeditbgmain {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
  }

  .produtitleimggsdescrdiv {
    padding: 4px 20px 10px 20px;
    border-bottom: 1px solid #aba1a1;
    height: 382px;
    overflow-y: auto;
  }

  .producudtlikecomrnttsennsuiquerydiv {
    padding: 4px 20px 10px 20px;
    border-bottom: 1px solid #aba1a1;
  }

  .commenytstthowitxtpehht {
    padding: 0px 20px 10px 20px;
  }

  .upldbannertrtxtpp {
    font: 400 20px / 25px Roboto;
  }

  .selletxtcbabbtxttpp {
    color: #a9acb4;
    font: 400 15px / 20px Roboto;
  }

  .choosttflttxtpp {
    color: #292d32;
    font: 500 20px / 25px Roboto;
  }

  .jjshpsttsrrtxtpp {
    color: #a9acb4;
    font: 500 15px / 20px Roboto;
    text-align: center;
  }

  .uploalogomodalbg {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
  }

  .uploadlogobroderrardtdivbtt {
    padding: 30px 25px;
  }

  .upldbantrtupldbannerdiv {
    border-bottom: 2px solid #b9b3b3;
    padding: 12px 20px;
  }

  .uploadlogobroderrardt {
    width: 100%;
    max-width: 140px;
    min-width: 140px;
    height: 140px;
  }

  .broowstsffibthnsttdiv {
    background-color: #d9d9d9;
    color: #000;
    font: 500 11px / 17px Roboto;
    width: 100%;
    max-width: 74%;
  }

  .uploabannermodalbg {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
  }

  .uploadaboxdeshhbdiv {
    width: 100%;
    max-width: 100%;
    padding: 24px 15px;
    border: 2px dashed #cbd0dc;
    border-radius: 10px;
    position: relative;
    height: 230px;
  }

  .edittamodalboixsbg {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 10px;
  }
}

@media (max-width: 420px) {
  .otherprofileproductbgmain {
    width: 100%;
    max-width: 330px;
    min-width: 330px;
  }

  .produucvtsdlikeinmncomnf {
    width: 100%;
    max-width: 15px;
    min-width: 15px;
    height: 15px;
  }

  .likkskjsbtcomnbvtyoipuxtt {
    font: 400 14px / 16px Roboto;
    color: #000000;
  }

  .likkskjsbtcomnbvtyoipuxtt span {
    margin-left: 4px;
    font: 300 15px / 18px Roboto;
  }

  .produtitleimggsdescrdiv {
    padding: 4px 20px 10px 20px;
    border-bottom: 1px solid #aba1a1;
    height: 340px;
    overflow-y: auto;
  }

  .productmodalbgmain {
    width: 100%;
    max-width: 330px;
    min-width: 330px;
  }

  .editlofgogaemodalbg {
    position: absolute;
    background-color: #fff;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 320px;
    min-width: 320px;
    border-radius: 10px;
  }
  .owenoagedjeywordbgmain {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
    border-radius: 10px;
  }
  .labbnemttxptt {
    font: 400 16px / 19px Roboto;
    color: #000;
    margin-left: 10px;
    margin-bottom: 0px;
  }
  .chekowcutomedesign {
    width: 100%;
    max-width: 15px;
    min-width: 15px;
    height: 15px;
  }
  .brwdffioevbtgbjhbtnmewlogpro {
    width: 100%;
    max-width: 45%;
    min-width: 45%;
  }

  .browoefilesabbddivforprofikeflx {
    padding: 50px 20px;
  }

  .produtitleimggsdescrdiv {
    padding: 4px 10px 10px 10px;
  }

  .producudtlikecomrnttsennsuiquerydiv {
    padding: 4px 10px 10px 10px;
    border-bottom: 1px solid #aba1a1;
  }

  .commenytstthowitxtpehht {
    padding: 0px 10px 10px 10px;
  }

  .uploalogomodalbg {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
  }

  .uploabannermodalbg {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
  }

  .upgalerymodalbg {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
  }

  .edittamodalboixsbg {
    width: 100%;
    max-width: 325px;
    min-width: 325px;
    border-radius: 10px;
  }

  .compamyinfoeditbgmain {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
  }

  .foriwdtrpersinedimain {
    width: 100%;
    max-width: 241px;
    min-width: 249px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .brwdffioevbtgbjhbtn {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
  }

  .editabannermodalkbg {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    border-radius: 10px;
  }

  .editabannerideletcrrsdivhjdsj {
    padding: 10px 20px 10px 20px;
  }

  .bannerimgeditgetmain {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 225px;
  }

  .brwdffioevbtgbjhbtn {
    width: 100%;
    max-width: 52%;
    min-width: 52%;
  }

  .browoefilesabbddivflx {
    padding: 20px 15px;
  }

  .editsonbscimedialsodlonkbg {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
    border-radius: 10px;
  }

  .editramoffahsgaellrttdivshhafb {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
    border-radius: 10px;
  }

  .hahgetytsiimghbmaindibv {
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: relative;
  }

  .hhsjjhahhahuyguuydibbanmnib {
    padding: 20px 12px;
  }

  .enquerysmokdnndbndbg {
    width: 100%;
    max-width: 320px;
    min-width: 320px;
  }
  .sihnglechatbigdbgmain {
    max-width: 320px;
    min-width: 320px;
  }
  .chatconytentbigmdlimg {
    width: 100%;
    height: 300px;
  }
}

textarea {
  padding: 8px 15px;
}
/* Hide the default input */
/* input[type="file"] {
  display: none;
} */
#file-upload{
  display: none;
}

/* Style the custom label */
.file-label {
  display: inline-block;
  padding: 8px 15px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
  border:solid 1px #7c7c7c;
  font: 400 16px/20px Roboto;
  background-color: transparent;
}

/* Add some spacing */
.custom-file-upload {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
}

#file-chosen {
  font-size: 14px;
  color: #666;
}

