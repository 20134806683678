.ReferAndEarnBanner_sec {
    width: 100%;
    height: auto;
}

.ReferAndEarnBanner_sec .content_wrap {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

.ReferAndEarnBanner_sec .heading {
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    color: #000000;
    padding: 0;
    margin-bottom: 30px;
}

.ReferAndEarnBanner_sec .heading span {
    color: #6607DF;
}

.ReferAndEarnBanner_sec .text {
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    color: #000000;
    line-height: normal;
    padding: 0;
    margin-bottom: 30px;
}

.Getyourlink_btn {
    width: 100%;
    max-width: 150px;
    height: 40px;
    text-align: center;
    border: 1px solid #1A9A06;
    background-color: #1A9A06;
    color: #fff;
    border-radius: 8px;
    text-decoration: none;
    outline: 0;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.6s;
    margin-top: 20px;
}

.Getyourlink_btn:hover {
    background-color: transparent;
    color: #1A9A06;
}

.ReferAndEarnBanner_sec .bannerimg_wrap {
    width: 100%;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.ReferAndEarnBanner_sec .bannerimg_wrap img {
    width: 100%;
    height: 100%;
}

.MaximiseYourEarnings_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.MaximiseYourEarnings_sec .heading {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    color: #000000;
    padding: 0;
    max-width: 700px;
    margin: 0 auto 20px;
}

.MaximiseYourEarnings_sec .heading span {
    color: #662D91;
}

.MaximiseYourEarnings_sec .text {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: normal;
    color: #000000;
    max-width: 700px;
    margin: 0 auto 40px;
}

.Gainfreedom_wrap {
    width: 100%;
    height: auto;
    /* background-color: aquamarine; */
}

.Gainfreedom_wrap .Gainheading {
    font-size: 44px;
    font-weight: 700;
    line-height: normal;
    color: #0B0A0A;
    text-align: left;
    padding: 0;
    margin-bottom: 30px;
}

.Gainfreedom_wrap .Gainsubhead {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: #0B0A0A;
    text-align: left;
    padding: 0;
    margin-bottom: 80px;
}

.Gainfreedom_wrap .Gainpara {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: #0B0A0A;
    text-align: left;
    padding: 0;
    margin: 0;
    max-width: 300px;
}

.GainApplication_Form {
    width: 100%;
    height: auto;
}

.GainApplication_Form .head {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #662D91;
    line-height: normal;
    padding: 0;
    margin-bottom: 20px;
}

.GainApplication_Form .desc_box {
    width: 100%;
    height: auto;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #662D91;
    background-color: #E9E6FF;
    margin-bottom: 20px;
}

.GainApplication_Form .desc_box .desc {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    line-height: normal;
}

.GainApplication_Form .form-group {
    width: 100%;
    height: auto;
}

.GainApplication_Form .form-group label {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #000000;
}

.GainApplication_Form .form-group .form-control {
    width: 100%;
    height: auto;
    background-color: transparent;
    border: 1px solid #6D40CD;
    box-shadow: none;
    outline: 0;
    padding: 5px 10px;
}

.GainApplication_Form .form-group .form-control:focus {
    box-shadow: none;
    outline: 0;
}

.GainApplication_Form .form-group .form-control.paddingleft {
    padding: 5px 10px 5px 84px;
}

.GainApplication_Form .form-group .numbercode {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border: none;
    border-right: 1px solid #6D40CD;
    background-color: transparent;
    height: 100%;
    width: 75px;
    padding: 5px 5px;
    box-shadow: none;
    outline: 0;
}

.GainApplication_Form .form-check {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.GainApplication_Form .form-check .form-check-label {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    line-height: normal;
}

.applynow_btn {
    width: 100%;
    max-width: 120px;
    height: 40px;
    text-align: center;
    border: 1px solid #1A9A06;
    background-color: #1A9A06;
    color: #fff;
    border-radius: 8px;
    text-decoration: none;
    outline: 0;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.6s;
}

.applynow_btn:hover {
    background-color: transparent;
    color: #1A9A06;
}

.HereHowReferralWorks_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.HereHowReferralWorks_sec .heading {
    font-size: 34px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    line-height: normal;
    padding: 0;
    margin-bottom: 30px;
}

.allreferral_works {
    width: 100%;
    height: auto;
    padding: 30px 20px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #FAECFF;
    border: 1px solid #000000;
}

.HowReferralWorks_card {
    width: 100%;
    height: auto;
    padding: 10px;
}

.HowReferralWorks_card .icon_wrap {
    width: 100%;
    max-width: 150px;
    height: 100px;
    margin: 0 auto 15px;
    overflow: hidden;
}

.HowReferralWorks_card .icon_wrap img {
    width: 100%;
    height: 100%;
}

.HowReferralWorks_card .name {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60px;
}

.HowReferralWorks_card .descrip {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #000000;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 90px;
}

.ReferEarn_btn {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: end;
}

.ReferEarn_btn .btn {
    width: auto;
    height: 40px;
    text-align: center;
    border: 1px solid #1A9A06;
    background-color: #1A9A06;
    color: #fff;
    border-radius: 8px;
    text-decoration: none;
    outline: 0;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.6s;
}

.ReferEarn_btn .btn:hover {
    background-color: transparent;
    color: #1A9A06;
}

.FrequentlyAskedQuestions_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.FrequentlyAskedQuestions_sec .heading {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    line-height: normal;
    padding: 0;
    margin-bottom: 15px;
}

.FrequentlyAskedQuestions_sec .text {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #000000;
    line-height: normal;
    padding: 0;
    margin-bottom: 25px;
}


.FrequentlyAskedQuestions_sec .accordion__button {
    position: relative;
    border-radius: 12px;
    margin-bottom: 15px;
}

.FrequentlyAskedQuestions_sec .accordion__button p {
    font-weight: 700;
}

.FrequentlyAskedQuestions_sec .accordion__button:before {
    position: absolute;
    top: 27px;
    right: 20px;
    transform: rotate(45deg);
    transition: 0.6s;
}

.FrequentlyAskedQuestions_sec .accordion__button[aria-expanded='true']::before,
.FrequentlyAskedQuestions_sec .accordion__button[aria-selected='true']::before {
    transform: rotate(-135deg);
}

.FrequentlyAskedQuestions_sec .accordion {
    border: none;
}

.FrequentlyAskedQuestions_sec .accordion__item + .accordion__item {
    border-top: 0;
}

.FrequentlyAskedQuestions_sec .accordion__panel {
    padding: 0 20px 20px;
}

.ReferAndEarnTermsConditions_sec {
    width: 100%;
    height: auto;
    padding: 40px 0;
    background-color: #fff;
}

.ReferAndEarnTermsConditions_sec .listhead {
   font-size: 24px;
   font-weight: 600;
   text-align: left;
   line-height: normal;
   color: #000000;
   padding: 0;
   margin-bottom: 15px;
}

.ReferAndEarnTermsConditions_sec .list {
    width: 100%;
    height: auto;
    padding-left: 20px;
    margin-left: 0px;
    margin-bottom: 30px;
}

.ReferAndEarnTermsConditions_sec .list li {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: normal;
    color: #000000;
    padding: 0;
    margin-bottom: 10px;
}

.readmore_btn {
    text-align: center;
    border: none;
    background-color: #D8D8D8;
    color: #020202;
    border-radius: 8px;
    text-decoration: none;
    outline: 0;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.6s;
}


@media (max-width: 991px) {
    .ReferAndEarnBanner_sec .bannerimg_wrap {
        height: 100%;
    }
}

@media (max-width: 768px) {
    .ReferAndEarnBanner_sec .bannerimg_wrap {
        height: auto;
    }

    .Gainfreedom_wrap {
        margin-bottom: 20px;
    }

    .MaximiseYourEarnings_sec .heading {
        font-size: 24px;
    }

    .MaximiseYourEarnings_sec .text {
        font-size: 14px;
        margin: 0 auto 15px;
    }

    .Gainfreedom_wrap .Gainheading {
        font-size: 24px;
        text-align: center;
        margin-bottom: 5px;
    }

    .Gainfreedom_wrap .Gainsubhead {
        font-size: 15px;
        text-align: center;
        margin-bottom: 15px;
    }

    .Gainfreedom_wrap .Gainpara {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        max-width: 100%;
        margin-bottom: 30px;
    }

    .GainApplication_Form .head {
        font-size: 20px;
        text-align: center;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .ReferAndEarnBanner_sec .content_wrap {
        padding: 30px 0 0;
    }

    .HereHowReferralWorks_sec .heading {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .HowReferralWorks_card .icon_wrap {
        width: 100%;
        max-width: 100px;
        height: 70px;
    }

    .HowReferralWorks_card .name {
        font-size: 14px;
        height: 40px;
    }

    .HowReferralWorks_card .descrip {
        font-size: 12px;
        height: 60px;
    }

    .HowReferralWorks_card {
        margin-bottom: 25px;
        padding: 0;
    }
    .FrequentlyAskedQuestions_sec {
        padding: 20px 0;
    }
    .FrequentlyAskedQuestions_sec .heading {
        font-size: 24px;
    }
    .FrequentlyAskedQuestions_sec .text {
        font-size: 16px;
    }
    .ReferAndEarnTermsConditions_sec {
        padding: 20px 0;
    }
    .ReferAndEarnTermsConditions_sec .listhead {
        font-size: 18px;
        font-weight: 700;
    }
    .ReferAndEarnTermsConditions_sec .list li {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .ReferAndEarnBanner_sec .heading {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .ReferAndEarnBanner_sec .text {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .Getyourlink_btn {
        width: 100%;
        max-width: 115px;
        height: 35px;
        font-size: 14px;
        margin-top: 15px;
    }

    .Gainfreedom_wrap .Gainheading {
        font-size: 20px;
    }

    .Gainfreedom_wrap .Gainsubhead {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .Gainfreedom_wrap .Gainpara {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .HowReferralWorks_card .name {
        font-size: 12px;
        height: 38px;
    }

    .HowReferralWorks_card .descrip {
        font-size: 10px;
        height: 60px;
    }
}