* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: rgba(237, 238, 255, 1);
}

html {
  max-width: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.cust_container {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  padding: 0 15px;
}

.mainwrap {
  width: 100%;
  height: auto;
  padding-top: 70px;
}

.brdrsrvc {
  border: 2px solid #DBDEFE;
  margin: 15px 20px 0px;
  border-radius: 10px;
  padding: 10px 15px;
}

.mainheader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #ccc;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mainheader.fixed {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  right: 0;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.TopHeader {
  /* background-color: #FAFAFA; */
  background-color: #67308F;
  padding: 15px 0px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.headerlogocnctmain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0px 20px; */
}

.homeneoo {
  animation: diagonalAnimation 1.5s ease-out;
  transform-origin: bottom right;
}

@keyframes diagonalAnimation {
  0% {
    transform: translate(90%, 100%);
  }

  100% {
    transform: translateY(0, 0);
  }
}

.logoneeo {
  /* width: 150px; */
  /* height: 40px; */
  width: 196px;
  height: auto;
  margin: 0;
}

.logoneeo img {
  width: 100%;
  height: 100%;
  max-width: 196px;
}

.iconmainheader {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.headermenuname {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #d7caca;
}

.iconhdmin:hover .headermenuname {
  color: #fff;
}

.imgprfls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
  position: relative;
  margin-left: 15px;
  cursor: pointer;
}

.userDrop {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  background-color: #fff;
  width: 200px;
  padding: 5px 10px;
  box-shadow: 0px 0px 5px 0px #9d9a9a;
  z-index: 25;
  border-radius: 4px;
}

.userMenu {
  list-style-type: none;
}

.userMenu li {
  border-bottom: 1px solid rgb(241, 239, 239);
  padding: 5px 0;
}

.userMenu li:last-child {
  border: none;
}

.userMenu li a {
  display: block;
  width: 100%;
  padding: 5px 8px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.userMenu li a:hover {
  background-color: #6A08B7;
  color: #fff;
}

.iconarrw i {
  font-size: 15px;
  color: #d7caca;
  margin-left: 10px;
}

.prflimg {
  width: 41px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.prflimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: centers;
}

#QRIcon {
  display: none;
}

.iconhdmin {
  width: auto;
  margin: 0;
  cursor: pointer;
  text-align: center;
}

.iconhdmin svg {
  width: 25px;
  height: 23px;
  fill: #d7caca;
  /* fill: #fff; */
}

.iconhdmin svg:hover {
  fill: #fff !important;
}

.iconhdmin i {
  font-size: 24px;
  color: #d7caca;
}

.iconhdmin i:hover {
  color: #fff !important;
}

.iconhdmin img {
  width: 100%;
  height: 100%;
}

.dirctrIcon {
  width: 12px;
  height: 12px;
}

.dirctrIcon img {
  width: 100%;
  height: 100%;
}

.fottersection {
  width: 100%;
  height: auto;
  /* padding: 30px 0px; */
}

.csttxt {
  color: #585454;
  font-Size: 16px;
  font-family: 'Inter', sans-serif;
  font-Weight: 600;
  line-height: 20px;
  padding-bottom: 5px;
}

/* fixed footer */

.FixedFootSec {
  width: 100%;
  height: auto;
  padding: 15px 0px;
  /* background: linear-gradient(to right, rgb(139 158 209), rgba(103, 48, 143, 1)); */
  background-color: #fff;
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: none;
  border: 1px solid #ECE7E7;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.FixFootMenu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
}

.footMenuIcon {
  width: auto;
  height: auto;
  text-align: center;
}

.footMenuIcon svg {
  width: 22px;
  height: 22px;
  fill: #504f4f;
  margin-bottom: 2px;
}

.footMenuIcon i {
  font-size: 22px;
  color: #504f4f;
  margin-bottom: 2px;
}

.footMenuIcon:hover svg,
.footMenuIcon:focus svg {
  fill: #6A08B7 !important;
}

.footMenuIcon:hover i,
.footMenuIcon:focus i {
  color: #6A08B7 !important;
}

.footmenuicon_name {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #504f4f;
}

.footMenuIcon:hover .footmenuicon_name,
.footMenuIcon:focus .footmenuicon_name {
  color: #6A08B7 !important;
}


.fottersection .socialicon_wrap {
  width: 100%;
  height: auto;
  padding-top: 40px;
}

.fottersection .socialicon_wrap ul {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  list-style: none;
}

.fottersection .socialicon_wrap ul li {
  cursor: pointer;
}

.fottersection .socialicon_wrap ul li a {
  font-size: 28px;
  color: #999999;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border: none;
  background-color: transparent;
  transition: 0.6s;
}

.fottersection .socialicon_wrap ul li:hover a {
  color: #67308F;
  text-decoration: none;
}

.fopterbg {
  width: 100%;
  /* background-color: rgba(250, 250, 250, 1); */
  height: 100%;
  padding: 0px 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imgfooterprl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
}

.imgfooterprl .howcan {
  white-space: nowrap;
  white-space: nowrap;
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: #000000;
  font-weight: 600;
  padding: 0;
  margin: 0 15px 0 0;
}

.contuctus_btn {
  width: auto;
  height: auto;
  padding: 6px 20px;
  border-radius: 20px;
  color: #000000;
  background-color: #D9D9D9;
  white-space: nowrap;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grpimg {
  width: 50px;
  height: 52px;
}

.grpimg img {
  width: 100%;
  height: 100%;
}

.rightfootercontentwrap {
  width: 100%;
  max-width: 50%;
}

.fotercfyvflx {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px 25px;
}

.txttftrtkjh {
  color: #000000;
  font-Size: 14px;
  font-family: 'Inter', sans-serif;
  font-Weight: 500;
  letter-Spacing: 0.24px;
  line-height: 18px;
  padding: 10px 0px;
  white-space: nowrap;
}

.txtppcpy {
  color: #585454;
  font-Size: 12px;
  font-family: 'Inter', sans-serif;
  font-Weight: 400;
  letter-Spacing: 0.24px;
  text-align: right;
}

.footerafbhsvbhy {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
}

.footerafbhsvbhy img {
  width: 25px;
  height: auto;
  margin-right: 10px;
}

.bgghjh {
  background-color: #F0EAF4;
  width: 100%;
  height: auto;
  /* position: absolute;
  z-index: 20;
  top: 80px; */
  box-shadow: 0px 0px 4px 0px #ccc;
  padding: 30px 0px;
}

.inpyuj {
  width: 100%;
  /* padding: 10px 0px; */
  position: relative;
}

.inpyuj .input {
  width: 100%;
  padding: 10px 50px 10px 20px;
  background-color: #fff;
  outline: 0;
  border: none;
  border-radius: 50px;
  color: #010203;
  font-size: 15px;
  border: 1px solid #E1D6E9;
}

.inpyuj .input::placeholder {
  color: #010203;
}

.inpyuj .input:focus {
  outline: 0;
}

.inpyuj ul {
  list-style: none;
  padding: 10px 15px 10px;
  margin: 0;
}

.inpyuj ul li {
  width: 100%;
  height: auto;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 4px;
}

.inpyuj ul li:hover {
  background-color: #d7c6e391;
}



.ghjhhsrch {
  width: 35px;
  height: 43px;
  position: absolute;
  top: 2px;
  right: 15px;
  /* transform: translateY(-50%); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ghjhhsrch i {
  font-size: 20px;
  color: grey;
}

.fhjsdcncth {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding-bottom: 16px;
  padding-top: 5px;
}

.mnakmsjjmn {
  width: 100%;
  max-width: 100%;
  /* margin: 10px 0px; */
}

.ttxytnk {
  color: #524a4a;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 0px 3px 0px #adabab;
  transition: 0.6s;
  margin-right: 15px;
}

.ttxytnk:hover {
  color: #fff;
  background-color: #67308F;
  box-shadow: 0px 0px 0px 0px #757575;
}

.ttxytnk.active {
  color: #fff;
  background-color: #67308F;
  box-shadow: 0px 0px 0px 0px #757575;
}

.ttxytnk i {
  margin-left: 5px;
  color: #524a4a;
  font-size: 15px;
  display: none;
}

.ttxytnk:hover i {
  color: #fff;
}

.ttxytnk.active i {
  color: #fff;
}

.ttxytnkactc {
  padding: 10px;
  border: 1px solid #010203;
  border-radius: 18px;
  text-align: center;
  cursor: pointer;
}

.ttxytnkactc i {
  margin-left: 5px;
  color: #010203;
  font-size: 15px;
}

.authhbg {
  background-color: #67308fa1;
  width: 100%;
  height: 100vh;
  position: relative;
}

.backhjhjlgn {
  width: 100%;
  /* height: 100%; */
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 50%;
  border-radius: 10px;
}

.lgntxtpp {
  font-size: 40px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-Weight: 600;
  letter-Spacing: 0.24px;
}

/* login page start */

.LoginPage {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #EDEEFF;
}

.LoginBgOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(195deg, rgba(66, 66, 74, 0.6), rgba(25, 25, 25, 0.6));
  z-index: 1;
}

.LoginForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  max-width: 400px;
  width: 100%;
  z-index: 2;
}

.LoginTop {
  background: #67308F;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgb(187 89 235 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
  border-radius: 0.5rem;
  margin: -50px 0px 30px;
  padding: 25px 20px;
  text-align: center;
  color: #fff;
}

.LoginHead {
  font-size: 25px;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  margin-bottom: 15px;
}

.LoginTopIcon {
  margin-bottom: 10px;
}

.LoginTopIcon i {
  margin: 0px 12px;
  font-size: 15px;
}

.LoginBtm form {
  padding: 15px 0px;
}

.LoginBtm .form-control:focus {
  border-color: #c9c9c9;
  box-shadow: none;
}

.LoginBtm input {
  width: 100%;
  display: block;
  height: 40px;
  border: none;
  outline: 0;
  border: 1px solid #c9c9c9;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
}

.LoginBtm input::placeholder {
  color: #5c5959;
}

.LoginBtm input[type='checkbox'] {
  width: 13px;
  height: 13px;
  display: inline-block;
}

.LoginRem {
  font-size: 15px;
  margin-left: 5px;
  color: #5c5959;
}

.LoginBtn {
  width: 100%;
  height: 36px;
  /* background-color: rgb(26, 115, 232); */
  background-color: #67308F;
  color: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: rgba(26, 115, 232, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(26, 115, 232, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(26, 115, 232, 0.15) 0rem 0.0625rem 0.3125rem 0rem;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.LoginLink {
  text-align: center;
  color: rgb(123, 128, 154);
  margin-bottom: 15px;
  font-size: 15px;
}

.LoginLink a {
  color: rgb(26, 115, 232);
  font-weight: 500;
  font-size: 15px;
}

.LoginLink a:hover {
  color: rgb(26, 115, 232);
  text-decoration: none;
}

svg:hover {
  fill: #000 !important;
}

/* login page end */

/* Responsive search bar */

.ResSerBar {
  width: 100%;
  height: auto;
  position: fixed;
  top: 60px;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #E1DFDF;
  padding: 10px 0px;
}

.resSerInp {
  width: 100%;
  padding: 10px 0px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.resSerInp input {
  width: 100%;
  padding: 10px;
  background-color: #D9D9D9;
  outline: 0;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  color: #010203;
  font-size: 15px;
  border: 1px solid #E1D6E9;
}

.resSerInp input::placeholder {
  color: #010203;
}

.ressrch i {
  width: 10%;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #000;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.SerOPtn {
  display: flex;
  align-items: center;
  padding-top: 5px;
  gap: 10px;
}

.SerOptnDiv {
  border: 1px solid #D3B0FF;
  border-radius: 10px;
  Width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #000;
}

/* loader design */

.loaderSec {
  width: 100%;
  height: 100vh;
  position: relative;
}

.loadermid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderFig {
  width: 250px;
  height: auto;
}

.loaderFig img {
  width: 100%;
  height: 100%;
}

.loaderbtm {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.loadersmlltxt {
  color: #000;
  font-size: 14px;
}

.loaderBigtxt {
  font-size: 22px;
  color: #000;
  font-weight: 500;
}

.loaderSec .progress {
  background-color: #c9c9c9;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  /* padding: 0 5px; */
  display: flex;
  height: 4px;
  width: 100%;
  margin-top: 20px;
}

.loaderSec .progress-value {
  animation: load 1s normal forwards;
  box-shadow: 0 10px 40px -10px #634fe0;
  border-radius: 100px;
  background: #634fe0;
  height: 30px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}


.homesearchheadermain{
  width: 100%;
  max-width: 100%;
  position: absolute;
  background-color: transparent;
  padding: 15px 0px;
  z-index: 9;
}
.hdbkpt {
  background-color: #662D91;
  width: 100%;
  position: fixed;
  padding: 15px 0px;
  z-index: 9;
}

.homesearchheadermain.fixed {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  right: 0;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #662D91;
}
























/* Responsive */

@media (max-width: 1200px) {
  .rightfootercontentwrap {
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 1024px) {
  .mnakmsjjmn {
    max-width: 100%;
  }
}

@media (max-width: 991px) {

  /* loader */

  .loaderFig {
    width: 150px;
  }

  .loaderBigtxt {
    font-size: 18px;
  }


  .cust_container {
    max-width: 90%;
  }

  .fopterbg {
    padding: 15px 0px;
    padding-bottom: 30px;
    flex-wrap: wrap;
  }

  .imgfooterprl {
    width: 100%;
    margin-bottom: 20px;
  }

  .txtppcpy {
    text-align: left;
  }

  .bgghjh {
    padding: 15px 0px;
  }

  .rightfootercontentwrap {
    width: 100%;
    max-width: 100%;
  }

  .fotercfyvflx {
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .iconhdmin svg {
    width: 25px;
    height: 20px;
    margin-bottom: 2px;
  }

  .iconmainheader {
    gap: 15px;
  }

  .imgprfls {
    margin: 0;
  }

  .prflimg {
    width: 35px;
    height: 35px;
  }

  .cust_container {
    max-width: 100%;
  }

  .footerafbhsvbhy {
    justify-content: start;
    margin-bottom: 15px;
  }
  .FixedFootSec {
    display: block;
  }


}

@media (max-width: 575px) {

  /* body{
    background-color: #fff;
  } */

  .iconmainheader {
    align-items: baseline;
  }

  
  .fottersection {
    margin-bottom: 50px;
  }

  .txtppcpy {
    text-align: left;
  }

  #headSerIcon,
  #headDirctryIcon {
    display: none;
  }

  #headRewardIcon {
    display: none;
  }

  #QRIcon {
    display: block;
  }

  .iconarrw i {
    display: none;
  }

  /* login page start */

  .logoneeo {
    width: 120px;
    /* height: 25px; */
  }

  .LoginForm {
    max-width: 75%;
  }

  .LoginBtm form {
    padding: 10px 0px;
  }

  .LoginTop {
    padding: 20px 15px;
    margin-bottom: 15px;
  }

  .LoginHead {
    font-size: 22px;
  }

  .LoginBtm input::placeholder {
    font-size: 14px;
  }

  .LoginBtm input {
    font-size: 14px;
  }

  .LoginRem {
    font-size: 14px;
  }

  .LoginBtm input[type='checkbox'] {
    width: 12px;
    height: 12px;
  }

  .LoginLink {
    font-size: 14px;
  }

  .LoginLink a {
    font-size: 14px;
  }

  .LoginBtn {
    font-size: 13px;
  }

  .fotercfyvflx {
    flex-direction: column;
    align-items: flex-start;
  }

  .txttftrtkjh {
    margin-bottom: 15px;
    padding: 0;
  }

  .imgfooterprl {
    margin-bottom: 15px;
  }

  /* login page end */

  .ttxytnk i,
  .ttxytnkactc i {
    font-size: 12px;
  }

  .ttxytnk,
  .ttxytnkactc {
    font-size: 14px;
    padding: 5px 7px;
    border-radius: 6px;
  }

  .headermenuname {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .fhjsdcncth {
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 5px;
  }

  .ttxytnk,
  .ttxytnkactc {
    width: auto;
    font-size: 13px;
    margin-right: 0;
  }

  .inpyuj input {
    padding: 6px 10px;
    font-size: 13px;
  }

  .ghjhhsrch i {
    right: 22px;
    font-size: 13px;
  }

}

@media (max-width: 420px) {

  .SerOPtn {
    gap: 7px;
  }

  .SerOptnDiv {
    Width: auto;
    font-size: 13px;
    padding: 0px 12px;
  }

  .logoneeo {
    width: 100px;
  }

  .iconhdmin i {
    font-size: 15px;
  }

  .iconmainheader {
    gap: 12px;
  }

  /* Login page start */

  .LoginForm {
    padding: 20px 15px;
  }

  .LoginHead {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .LoginTop {
    padding: 10px 15px;
  }

  .LoginBtm input {
    height: 35px;
  }

  .LoginLink {
    margin-bottom: 5px;
  }

  .LoginTopIcon i {
    margin: 0px 8px;
    font-size: 13px;
  }

  .iconhdmin svg {
    width: 20px;
    height: 18px;
  }

  /* Login page end */
  .footmenuicon_name {
    font-size: 10px;
  }

}

/* Responsive */

.activeHeader {
  border: 2px solid rgb(8, 8, 17);
  /* You can adjust the border properties as needed */
}



/*****Savecontact_sec****/

.Savecontact_sec {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 30px 50px;
  box-shadow: 0px 0px 3px 0px #5c5959;
  margin-bottom: 5px;
}

.Savecontact_sec .content {
  width: 100%;
  height: auto;
  display: flex;
}

.Savecontact_sec .hand_icon {
  width: 100%;
  max-width: 50px;
  height: auto;
  overflow: hidden;
  margin-right: 15px;
}

.Savecontact_sec .hand_icon img {
  width: 100%;
  /* height: 100%; */
}

.Savecontact_sec .head {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: #000;
  margin-bottom: 5px;
  padding: 0;
}

.Savecontact_sec .subhead {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #000;
  margin-bottom: 5px;
  padding: 0;
}

.Savecontact_sec .list {
  width: 100%;
  height: auto;
  list-style: none;
  margin: 0 0 30px 0;
}

.Savecontact_sec .list li {
  font-size: 14px;
  line-height: normal;
  text-align: left;
  color: #585454;
}

.Savecontact_sec .btn_area {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

.exchangecont_btn {
  width: auto;
  height: auto;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #000;
  padding: 5px 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.savecontact_btn {
  width: auto;
  height: auto;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  border: none;
  background-color: #02661b;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 5px 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.savecontact_btn i {
  font-size: 15px;
  margin-right: 10px;
}

@media (max-width: 480px) {
  .Savecontact_sec {
    padding: 20px 15px;
  }

  .Savecontact_sec .btn_area {
    justify-content: center;
  }

  .exchangecont_btn {
    font-size: 12px;
    white-space: nowrap;
  }

  .savecontact_btn {
    font-size: 12px;
    white-space: nowrap;
  }

  .savecontact_btn i {
    margin-right: 5px;
  }

  .Savecontact_sec .hand_icon {
    max-width: 40px;
    margin-right: 5px;
  }
}


/*****SearchBarmodal****/
.searchBar_modal {
  position: fixed;
  top: 9%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 11;
}

.searchBar_content {
  background: white;
  /* padding: 20px; */
  border-radius: 30px;
  position: absolute;
  top: 3px;
  left: 37.5%;
  transform: translateX(-50%);
  max-width: 45%;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 991px) {

  .searchBar_content {
    max-width: 90%;
  }

}

@media (max-width: 576px) {
  .searchBar_content {
    top: 20px;
    left: 50%;
  }
}

.whatsappMemberOtherProfile {
  width: 100%;
  max-width: 38px;
  min-width: 38px;
  height: 39px;
  border-radius: 50%;
}





/*****pwa popup css start******/

.pwaPopup_wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000070;
  z-index: 999;
}

.pwaPopup_wrap .pwaPopup_body {
  width: 95%;
  max-width: 350px;
  height: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.pwaPopup_wrap .heading h4 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #242323;
  margin-bottom: 20px;
}

.pwaPopup_wrap .logo_wrap {
  width: 100%;
  max-width: 60px;
  height: auto;
  overflow: hidden;
  margin: 0 auto 30px;
  border-radius: 4px;
}

.pwaPopup_wrap .logo_wrap img {
  width: 100%;
  height: 100%;
}

.pwaPopup_wrap .content {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.pwaPopup_wrap .install_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border-radius: 30px;
  border: 1px solid #02661b;
  color: #fff;
  background-color: #02661b;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  width: 100%;
  max-width: 110px;
  height: 40px;
  transition: 0.6s;
  opacity: 0.80;
}

.pwaPopup_wrap .install_btn:hover {
  opacity: 1;
}

.pwaPopup_wrap .close_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border-radius: 30px;
  border: 1px solid #424242;
  color: #fff;
  background-color: #424242;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  width: 100%;
  max-width: 110px;
  height: 40px;
  transition: 0.6s;
  opacity: 0.75;
}

.pwaPopup_wrap .close_btn:hover {
  opacity: 1;
}

/*****pwa popup css end******/

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

/* like button shaking */
.tilt-shaking {
  animation: tilt-shaking 2s infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(5px, 5px) rotate(80deg);
  }

  50% {
    transform: translate(0, 0) rotate(0eg);
  }

  75% {
    transform: translate(-5px, 5px) rotate(-80deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.tilt-shake {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/*****QRCodeScanner_modal***/

.QRCodeScanner_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000071;
  z-index: 999;
}

.QRCodeScannermodal_body {
  position: absolute;
  width: 100%;
  max-width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  z-index: 1;
}

.QRCodeScannermodal_body .content {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.QRCodeScannermodal_body .close_btn {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 2;
  border: none;
  outline: 0;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  background-color: transparent;
  font-size: 24px;
  color: #000;
}

.QRCodeScannermodal_body .heading {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #67308F;
  padding: 0;
  margin-bottom: 20px;
}

.QRCodeScannermodal_body .Scannerbox_wrap {
  width: 100%;
  height: auto;
}

.QRCodeScannermodal_body #reader {
  width: 100%;
  height: auto;
}

.QRCodeScannermodal_body .html5-qrcode-element {
  border: 1px solid #67308F;
  outline: 0;
  text-decoration: none !important;
  outline: 0;
  box-shadow: none;
  background-color: transparent;
  color: #67308F;
  font-weight: 500;
  padding: 5px 15px;
  margin-bottom: 15px;
  border-radius: 6px;
}


.drctrCntctIconOpenDel {
  width: 100%;
  max-width: 38px;
  min-width: 38px;
  height: 38px;
  margin: 0;

}

.drctrCntctIconOpenDel img {
  width: 100%;
  height: 100%;

}

/* skeleteon */


/* Sidebar Start */
.sidebardiv {
  width: 100%;
  max-width: 300px;
  background: #fff;
  position: fixed;
  top: 0;
  right: -350px;
  z-index: 9;
  height: 100vh;
  display: block;
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 0px 5px 0px #838181;
}

.sidetop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px 8px 15px;
  border-bottom: 1px solid #ccc;
}

.frtsalonlogo {
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  height: auto;
}

.frtsalonlogo video {
  width: 100%;
  height: 100%;
}

.frtsalonlogo img {
  width: 100%;
  height: 100%;
}

.crosssideiocn i {
  color: #67308F;
  font-size: 20px;
}

.crosssideiocn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  /* border: 2px solid #000000; */
}

.headermenulistul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ftrmenulistli {
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
}

.listlnktxt {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  font-weight: 500;
  color: #000000;
}

.listlnktxt:hover {
  color: #67308F;
}

.listlnktxtftr {
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  color: #ffffff;
}

.submenulistdivliftr:first-child {
  padding: 10px 15px 0px 15px;
  list-style-type: none;
}

/* Sidebar End */



/*****NotificationBar_wrap******/
.NotificationBar_wrap {
  position: fixed;
  top: 0;
  right: -450px;
  width: 450px;
  height: auto;
  background-color: #fff;
  color: #000000;
  box-shadow: 0px 0px 5px 0px #ccc;
  overflow-x: hidden;
  transition: 0.6s;
  z-index: 999;
}

.NotificationBar_wrap.show {
  right: 0;
}

.NotificationBar_wrap .closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #67308F;
}

.NotificationBar_wrap .top_area {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ccc;
  padding: 15px;
}

.NotificationBar_wrap .heading {
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  color: #000000;
  padding: 0;
  margin: 0;
}

.NotificationBar_wrap .content_wrap {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.NotificationBar_wrap .content_wrap::-webkit-scrollbar {
  display: none;
}

.smsbox_wrap {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  border-bottom: 1px solid #ccc;
  gap: 10px;
}

.smsbox_wrap.unread {
  background-color: #F7EDFF;
}

.smsbox_wrap .left {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.smsbox_wrap .profile {
  width: 100%;
  max-width: 55px;
  min-width: 55px;
  height: 55px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.smsbox_wrap .profile img {
  width: 100%;
  height: 100%;
}

.smsbox_wrap .text {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  line-height: normal;
  padding: 0;
  margin: 0;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.smsbox_wrap .text span {
  color: #2774FF;
}


.smsbox_wrap .right {
  width: 60px;
  height: auto;
}

.smsbox_wrap .right .more_btn {
  width: 100%;
  text-decoration: none;
  border: none;
  outline: 0;
  box-shadow: none;
  background-color: transparent;
  color: #000000;
  text-align: center;
  font-size: 20px;
  margin-left: auto;
  position: relative;
}

.morebtn_dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #D0D0D0;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.morebtn_dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.morebtn_dropdown ul .item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #242323;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
}

.smsbox_wrap .right .datetime {
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: #242323;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.smsbox_wrap .right .status {
  width: 100%;
  font-size: 10px;
  text-align: center;
  color: #cac8c8;
  margin-bottom: 0;
  margin-top: 10px;
}

.smsbox_wrap.unread .right .status {
  color: #67308F;
}


.no_Notification {
  width: 100%;
  height: auto;
  padding: 100px 50px;
}

.no_Notification .icon {
  width: 100%;
  max-width: 60px;
  min-width: 60px;
  height: auto;
  margin: 0 auto 30px;
}

.no_Notification .icon img {
  width: 100%;
  height: 100%;
}

.no_Notification .name {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  color: #000000;
  text-align: center;
  padding: 0;
  margin-bottom: 10px;
}

.no_Notification .desc {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: #000000;
  text-align: center;
  padding: 0;
  margin: 0;
}




@media (max-width: 768px) {
  .NotificationBar_wrap {
    width: 350px;
  }

  .no_Notification {
    padding: 100px 20px;
  }

}


@media (max-width: 576px) {
  .NotificationBar_wrap {
    width: 310px;
  }

  .no_Notification {
    padding: 50px 0px;
  }
}

@media (max-width: 480px) {

  .no_Notification .icon {
    max-width: 40px;
    min-width: 40px;
    margin: 0 auto 20px;
  }

  .no_Notification .name {
    font-size: 16px;
  }

  .no_Notification .desc {
    font-size: 12px;
  }

  .NotificationBar_wrap .closebtn {
    font-size: 20px;
  }

  .NotificationBar_wrap .top_area {
    padding: 12px;
  }

  .smsbox_wrap .text {
    font-size: 11px;
  }

  .smsbox_wrap .profile {
    max-width: 45px;
    min-width: 45px;
    height: 45px;
  }
}



/* styles.css */
.inptfgfrmdiv {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.required::before {
  content: "*";
  color: red;
  margin-right: 0.5rem;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-wrapper select,
.input-wrapper input {
  flex-grow: 1;
}

/* >>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<< */
.file-upload-modal {
  position: relative;
  top: -18em;
  width: 195px;
  left: 27.5%;
  transform: translateX(-100%);
  background-color: white;
  padding: 10px 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}

.file-upload-modal input {
  display: block;
  margin-bottom: 10px;
}

.file-preview img {
  max-width: 100%;
  max-height: 200px;
  display: block;
  margin-bottom: 10px;
}

.file-upload-modal button {
  margin-right: 10px;
}

@media(max-width:768px){
  .file-upload-modal {
    position: relative;
    top: -13em;
    width: 150px;
    left: 176px;
    transform: translateX(-100%);
    background-color: white;
    /* padding: 15px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 8;
}

}

.profileLink {
  display: flex !important;
  align-items: center; /* Aligns image and text vertically */
  text-decoration: none; /* Removes underline from the link */
  color: inherit; /* Inherits color from parent, can be customized */
}

.linkText {
  margin-left: 10px;
}
.btn-rth {
  float: right;
  text-transform: uppercase;
}