.loginHeader {
    width: 100%;
    height: auto;
    padding: 20px 0px;
    background-color: #67308F;
}

.authenSec {
    width: 100%;
    height: 85vh;
    position: relative;
    padding: 20px 0px;
}

.authenSecInn {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.authenSecInnDiv{
    justify-content: space-between;
    display: flex;
    border-radius: 9px;
    border: 1px solid #CCC8C8;
    background: #FBFBFB;
}

.authenSecForm {
    width: 45%;
    height: auto;
    padding: 30px 15px 30px 20px;
}

.authenSecImg {
    width: 50%;
    height: 450px;
}

.authenSecImg img {
    width: 100%;
    height: 100%;
    border-radius: 0px 9px 9px 0px;
}

.authenSecTop h4 {
    color: #8C369B;
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 1.2px;
}

.authenSecTop p {
    color: #605E5E;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.57px;
    margin-top: 15px;
}

.authenSecBtm {
    padding-top: 30px;
    width: 93%;
}

.authenSecBtm input {
    border-radius: 12px;
    background: #D9D9D9;
    height: 40px;
    color: #4B4949;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.45px;
    border: none;
    width: 100%;
    display: block;
    outline: 0;
    padding: 3px 15px;
}

.authenSecBtm input:-webkit-autofill,
.authenSecBtm input:-webkit-autofill:hover,
.authenSecBtm input:-webkit-autofill:focus,
.authenSecBtm input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
}

.helpbtn {
    color: #A79D9D;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 15px;
}

.helpbtn:hover{
    color: #A79D9D;
}

.forgetFig{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #D9D9D9;
    margin-bottom: 15px;
}

.forgetFig img{
    width: 100%;
    height: 50%;
    border-radius: 50%;
}

.resetmsg{
    background-color: #fbf5c6;
    color: #000;
    font-size: 12px;
    border-radius: 8px;
    padding: 10px 5px;
    margin-bottom: 20px;
}

.resetmsg i{
    color: green;
    margin-right: 6px;
}

/* Responsive */

@media (max-width: 1199px){
    .authenSecTop h4{
        font-size: 30px;
    }

    .homeloginBtn {
        height: 35px;
        font-size: 14px;
    }
}

@media (max-width: 991px){
    .authenSecImg {
        height: 450px;
    }
}

@media (max-width: 767px){

    .authenSecBtm{
        width: 100%;
    }
    
    .authenSecImg{
        display: none;
    }

    .authenSecForm{
        width: 100%;
        padding: 20px 15px;
    }

    .authenSecTop h4 {
        font-size: 25px;
    }

    .authenSecTop p {
        font-size: 14px;
    }
    
}

@media (max-width: 575px){
    .authenSecInnDiv{
        background: none;
        border: none;
    }

    .authenSecInn{
        position: static;
        transform: translate(0,0);
    }
}