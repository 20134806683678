.ReferEarn_dashboard {
    width: 100%;
    height: auto;
    margin: 10px 0 40px;
}

.ReferEarn_dashboard .cust_container {
    background-color: #fff;
    border-radius: 6px;
}

.ReferEarnHeader_sec {
    width: 100%;
    height: auto;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DBD8D8;
}

.ReferEarnHeader_sec .leftpart {
    display: flex;
    align-items: center;
}

.ReferEarnHeader_sec .rightpart {
    display: flex;
    align-items: center;
}

.ReferEarnHeader_sec .back_btn {
    text-decoration: none;
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #000000;
    margin-right: 30px;
}

.ReferEarnHeader_sec .leftpart img {
    width: 35px;
    height: auto;
    margin-right: 10px;
}

.ReferEarnHeader_sec .leftpart .head {
    font-size: 22px;
    font-weight: 500;
    color: #0B0B0B;
    line-height: normal;
    text-align: left;
    padding: 0;
    margin: 0;
}

.ReferEarndrowpdown_wrap {
   margin: 0 15px;
}

.ReferEarndrowpdown_wrap .form-group {
   margin: 0;
}

.ReferEarndrowpdown_wrap .form-control {
   font-size: 14px;
   font-weight: 500;
   box-shadow: none;
   outline: 0;
   min-width: 90px;
}


.ReferEarnHeader_sec .rightpart .amout {
    font-size: 22px;
    font-weight: 500;
    color: #0B0B0B;
    line-height: normal;
    white-space: nowrap;
    text-align: left;
    padding: 0;
    margin: 0 15px 0 0;
}

.withdraw_btn {
    width: auto;
    height: auto;
    padding: 7px 15px;
    background-color: #38D79F;
    color: #fff;
    border: none;
    outline: 0;
    text-decoration: none;
    box-shadow: none;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
}

.ReferFriendsEarn_credits {
    width: 100%;
    height: auto;
    padding: 15px 50px;
    display: flex;
    align-items: flex-end;
}

.ReferFriends_card {
    width: 100%;
    max-width: 75%;
    height: auto;
    padding: 30px;
    border-radius: 12px;
    border: 1px solid #000000;
    margin-right: 15px;
}

.ReferFriends_card .iconwrap {
    width: 100%;
    max-width: 80px;
    height: auto;
}

.ReferFriends_card .iconwrap img {
    width: 100%;
    height: 100%;
}

.ReferFriends_card .head {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
    color: #0B0B0B;
    padding: 0;
    margin-bottom: 8px;
}

.ReferFriends_card .desc {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    line-height: normal;
    color: #090808;
    padding: 0;
    margin: 0;
}

.GetReferralLink_btn {
    width: auto;
    height: auto;
    padding: 8px 20px;
    background-color: #069D43;
    color: #fff;
    border: none;
    outline: 0;
    text-decoration: none;
    box-shadow: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
}

.GetReferralLink_btn:hover {
    color: #fff;
}

.AllEarningsBox_sec {
    width: 100%;
    max-width: 75%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    padding: 40px 50px;
}

.EarningsBox_wrap {
    width: 100%;
    max-width: 46%;
    height: auto;
    padding: 30px;
    border-radius: 12px;
    overflow: hidden;
}

.EarningsBox_wrap .name {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: normal;
    padding: 0;
    margin-bottom: 10px;
}

.EarningsBox_wrap .amount {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.SmartBusinesspack_btn {
    width: auto;
    height: auto;
    padding: 10px 20px 30px;
    background-color: transparent;
    color: #000000;
    border: none;
    outline: 0;
    text-decoration: none;
    box-shadow: none;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 40px;
    white-space: nowrap;
    transition: 0.6s;
}

.SmartBusinesspack_btn i {
    margin-left: 12px;
    color: #827676;
    transition: 0.6s;
}

.SmartBusinesspack_btn:hover i {
    margin-left: 18px;
}

.GenerateLink_Page {
    width: 100%;
    height: auto;
    margin: 10px 0 40px;
}

.GenerateLink_Page .cust_container {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
}

.RFECreditsInner_sec {
    width: 100%;
    height: auto;
    padding: 20px 40px 30px;
}

.RFECreditsInner_sec .text {
    width: 100%;
    max-width: 75%;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin: 20px 0 30px;
}

.RFECreditsInner_sec .form-check {
    margin-bottom: 20px;
}

.RFECreditsInner_sec .form-check label {
    font-weight: 500;
}


.HowToGET_sec {
    width: 100%;
    height: auto;
    padding: 40px 30px;
}

.HowToGET_sec .head {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    color: #0A0909;
    padding: 0;
    margin-bottom: 15px;
}

.HowToGET_allcard {
    width: 100%;
    height: auto;
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    gap: 15px;
}

.HowToGET_card {
    width: 100%;
    max-width: 18%;
    height: auto;
    overflow: hidden;
}

.HowToGET_card .icon {
    width: 40px;
    height: 33px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.HowToGET_card .icon img {
    width: 100%;
    height: 100%;
}

.HowToGET_card .progressbar {
    width: 100%;
    height: 1px;
    background-color: #827676;
    opacity: 0.6;
}

.HowToGET_card .name {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    color: #0D0C0C;
    padding: 0;
    margin-bottom: 3px;
}

.HowToGET_card .desc {
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #0D0C0C;
    padding: 0;
    margin: 0;
}

.HowToGET_sec .list {
   width: 100%;
   height: auto;
   list-style: none;
   padding: 0;
   margin: 0;
}

.HowToGET_sec .list li {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding: 0;
    margin-bottom: 15px;
}

/***shareyourlink_sec***/

.shareyourlink_sec {
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 20px 40px 30px;
}

.shareyourlink_sec .sharepic {
   width: 100%;
   max-width: 200px;
   height: auto;
   margin: 0 auto 20px;
}

.shareyourlink_sec .sharepic img {
    width: 100%;
    height: 100%;
}

.shareyourlink_sec .socialmedia_all {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.socialmedia_all .icon {
  width: 45px;
  min-width: 45px;
  height: 45px;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BCB9B9;
  text-decoration: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  margin: 0;
  transition: 0.6s;
}

.socialmedia_all .icon i {
  color: #fff;
  font-size: 20px;
}

.socialmedia_all .icon:hover {
    background-color: #67308F;
}

.refcode_wrap {
    width: 100%;
    height: auto;
    padding: 15px 20px;
    border-radius: 12px;
    background-color: #E3E3E3;
    margin-bottom: 10px;
}

.refcode_box {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.refcode_box .code {
   font-size: 18px;
   font-weight: 600;
   text-align: center;
   line-height: normal;
   color: #000000;
   padding: 0;
   margin: 0;
}

.referallink_wrap {
    width: 100%;
    height: auto;
    padding: 15px 20px;
    border-radius: 12px;
    background-color: #E3E3E3;
}

.referallink_wrap .referal_box {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.referallink_wrap .referal_box .link {
   font-size: 16px;
   line-height: normal;
   text-align: center;
   font-weight: 400;
   color: #000000;
   padding: 0;
   margin: 0;
}

.buttonall_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.Copybutton_btn {
    text-decoration: none;
    border: none;
    background-color: transparent;
    color: #000000;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.Sharebutton_btn {
    text-decoration: none;
    border: none;
    background-color: #1B9807;
    color: #fff;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 6px;
    margin: 0;
}

/**todayearnings_page**/
.todayearnings_page {
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.todayearnings_page .cust_container {
   background-color: #fff;
   border-radius: 6px;
}

.todayearnings_page .listwrap_all {
   list-style: none;
   margin: 0;
   padding: 30px 20px;
}

.profiledetails_box {
   width: 100%;
   height: auto;
   display: flex;
   justify-content: space-between;
   gap: 0 15px;
   padding: 20px;
   border-bottom: 1px solid #b4b2b2;
}

.profiledetails_box:last-child {
    border: none;
}

.profiledetails_box .left_area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    max-width: 70%;
}

.profiledetails_box .profdetails {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 0 15px;
}

.profiledetails_box .pro_pic {
   width: 100%;
   max-width: 60px;
   min-width: 60px;
   height: 60px;
   overflow: hidden;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.profiledetails_box .pro_pic img {
    width: 100%;
    height: 100%;
}

.profiledetails_box .name {
   font-size: 18px;
   font-weight: 500;
   line-height: normal;
   text-align: left;
   color: #000000;
   padding: 0;
   margin-bottom: 1px;
}

.profiledetails_box .desc {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    color: #5a5959;
    padding: 0;
    margin-bottom: 1px;
}

.profiledetails_box .text {
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #3e3e3f;
    padding: 0;
    margin: 0;
}

.profiledetails_box .date .time {
   font-size: 16px;
   font-weight: 400;
   line-height: normal;
   color: #000000;
}

.profiledetails_box .amount {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-align: right;
    color: #000000;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}


/***Responsive*****/

@media (max-width: 1024px) {
    .HowToGET_card {
        max-width: 22%;
        margin-bottom: 25px;
    }
}

@media (max-width: 991px) {
    .HowToGET_card {
        max-width: 30%;
    }
    .profiledetails_box .left_area {
        max-width: 80%;
    }
    .ReferEarnHeader_sec .back_btn {
        font-size: 20px;
        margin-right: 15px;
    }
    .ReferEarnHeader_sec .leftpart .head {
        font-size: 16px;
        font-weight: 600;
    }
    .ReferEarndrowpdown_wrap {
        margin: 0 10px;
    }
    .ReferEarndrowpdown_wrap .form-control {
        font-size: 12px;
    }
    .ReferEarnHeader_sec .rightpart .amout {
        font-size: 16px;
        font-weight: 600;
        margin: 0 10px 0 0;
    }
    .withdraw_btn {
        padding: 5px 15px;
    }
    .ReferEarnHeader_sec {
        padding: 15px 0px;
    }
    .profiledetails_box {
        padding: 12px 0;
    }
    .todayearnings_page .listwrap_all {
        padding: 20px 0px;
    }
    .profiledetails_box .pro_pic {
        width: 100%;
        max-width: 45px;
        min-width: 45px;
        height: 45px;
    }
    .profiledetails_box .desc {
        font-size: 12px;
    }
    .profiledetails_box .name {
        font-size: 16px;
    }
    .profiledetails_box .date .time {
        font-size: 12px;
    }
    .profiledetails_box .amount {
        font-size: 14px;
    }    
}

@media (max-width: 768px) {
    .ReferEarnHeader_sec {
        padding: 15px 0px;
    }

    .ReferFriendsEarn_credits {
        padding: 15px 0px;
    }

    .GetReferralLink_btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .ReferFriends_card .head {
        font-size: 18px;
    }

    .ReferFriends_card .desc {
        font-size: 12px;
    }

    .ReferFriends_card {
        padding: 15px;
    }

    .ReferFriends_card .iconwrap {
        max-width: 50px;
    }

    .AllEarningsBox_sec {
        max-width: 100%;
        padding: 40px 0px;
    }

    .SmartBusinesspack_btn {
        margin: 0;
    }
    .HowToGET_card {
        max-width: 45%;
    }
}

@media (max-width: 576px) {
    .ReferEarnHeader_sec {
        padding: 10px 0px;
    }

    .ReferEarnHeader_sec .leftpart img {
        width: 25px;
        margin-right: 5px;
    }

    .ReferEarnHeader_sec .leftpart .head {
        font-size: 16px;
    }

    .ReferEarnHeader_sec .rightpart .amout {
        font-size: 16px;
        margin: 0 10px 0 0;
    }

    .withdraw_btn {
        padding: 5px 10px;
        font-size: 11px;
    }

    .ReferFriends_card {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .ReferFriendsEarn_credits {
        flex-wrap: wrap;
        justify-content: center;
    }

    .EarningsBox_wrap .name {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .EarningsBox_wrap .amount {
        font-size: 14px;
    }

    .EarningsBox_wrap {
        padding: 20px;
        height: 90px;
    }
    .profiledetails_box .left_area {
        gap: 15px;
        max-width: 75%;
    }
    .profiledetails_box .right_area {
        display: flex;
        align-items: flex-end;
    }
    .profiledetails_box { 
        padding: 20px 0;
    }
    .ReferEarnHeader_sec .back_btn {
        font-size: 16px;
        margin-right: 15px;
    }
    .ReferEarnHeader_sec .leftpart {
        flex-wrap: wrap;
        max-width: 60%;
        min-width: 60%;
    }
    .ReferEarndrowpdown_wrap {
        margin: 5px 0 0 32px;
    }
}

@media (max-width: 480px) {
    .ReferEarnHeader_sec {
        padding: 7px 0px;
    }

    .ReferEarnHeader_sec .leftpart img {
        width: 20px;
    }

    .ReferEarnHeader_sec .leftpart .head {
        font-size: 14px;
        font-weight: 600;
    }

    .ReferEarnHeader_sec .rightpart .amout {
        font-size: 14px;
        margin: 0 10px 10px 0;
    }

    .withdraw_btn {
        padding: 5px 10px;
        font-size: 12px;
    }

    .ReferFriends_card .head {
        font-size: 16px;
    }

    .ReferFriends_card .desc {
        font-size: 10px;
    }

    .ReferFriends_card {
        padding: 10px;
    }

    .EarningsBox_wrap {
        padding: 10px;
        height: 63px;
        max-width: 48%;
    }

    .EarningsBox_wrap .name {
        font-size: 12px;
    }

    .EarningsBox_wrap .amount {
        font-size: 10px;
        font-weight: 700;
    }

    .AllEarningsBox_sec {
        padding: 20px 0px;
        gap: 10px;
    }
    .RFECreditsInner_sec {
        padding: 20px 0px;
    }
    .HowToGET_sec {
        padding: 30px 0px;
    }
    .todayearnings_page .listwrap_all {
        padding: 20px 0px;
    }

    .profiledetails_box .pro_pic {
        width: 100%;
        max-width: 40px;
        min-width: 40px;
        height: 40px;
    }
    .profiledetails_box .profdetails {
        gap: 0 10px;
    }
    .profiledetails_box .name {
        font-size: 14px;
        font-weight: 600;
    }
    .profiledetails_box .desc {
        font-size: 12px;
        font-weight: 500;
    }
    .profiledetails_box .text {
        font-size: 10px;
        font-weight: 400;
        color: #918d8d;
    }
    .profiledetails_box .left_area {
        max-width: 80%;
    }
    .profiledetails_box .amount {
        font-size: 12px;
    }
    .profiledetails_box .date .time {
        font-size: 10px;
        width: 150px;
        margin-left: 50px;
    }
    .ReferEarnHeader_sec .rightpart {
       flex-wrap: wrap;
       justify-content: end;
    }
}